@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.user-activity-data {
  font-size: 17px;

  &--general {
    @include flexible-row;
    width: 90%;
    margin-bottom: 24px;
    flex-wrap: wrap;

    &-detail {
      margin-bottom: 10px;
      margin-right: 5px;
    }

    &--product {
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  &--upgrade {
    @include flexible-row;
    width: 90%;

    &--product {
      @include flexible-row;

      &--text {
        font-weight: bold;
        margin: 10px;
      }
    }

    &--button {
      &#{&} {
        text-transform: unset;
        padding: 10px 20px;
        font-size: 17px;
      }

      &.upgrade {
        margin-right: 10px;
      }
    }
  }
}
