@import '../../../../../style/globalStyles.scss';

.customize-cost-params-frame {
	padding: 0 20px 20px;
	text-align: left;
	.cost-analysis-parameters__section-image {
		hr {
			margin: 20px 0 !important;
		}
	}
	.form-header-wrapper {
		h3,
		p {
			margin-left: 0 !important;
			margin-top: 0 !important;
		}
	}
	.include-all-costs-checkbox {
		&--wrapper {
			@include flex(center, flex-start, row);
			@include font-builder(16px, 500);
			&.disabled {
				opacity: 0.8;
			}
		}
	}
}
