@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../style/globalStyles.scss';

.solution-fea {
  &-alert {
    max-height: none;
    text-align: left;

    &--content {
      &--open-sentence {
        font-weight: bold;
        margin-bottom: 20px;
      }

      &--strength {
        margin-bottom: 20px;

        &--title {
          margin-bottom: 10px;
        }

        &--values {
          @include flex(center, null);

          &--number {
            background-color: $regular-opac;
            padding: 10px;
            border-radius: 3px;
            font-size: 18px;
          }
        }
      }

      &--user-input {
        margin-bottom: 50px;

        &--title {
          margin-bottom: 15px;
        }

        &--slider {
          &#{&} {
            width: 70%;
          }
        }
      }
    }
  }

  &--results {
    &--compare {
      @include flex(flex-start, space-between);
      width: 275px;
      margin-bottom: 20px;

      &--detail {
        max-width: 115px;

        &--title {
          color: inherit;
        }

        &--body {
          color: $text-regular-opac;
          white-space: normal;
          font-size: 13px;
        }

        &--icon {
          &#{&} {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &--result {
      @include flex(center, space-between);

      &--icon {
        width: 70px;
        height: 70px;
      }

      &--explanation {
        width: 78%;
      }
    }
  }
}
