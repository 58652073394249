@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.castor-devider {
	border: 1px solid $devider-color;
	opacity: 0.3;
	&--with-text {
		@include flex(center, center, row);
		gap: 10px;
		.castor-devider {
			width: 100%;
		}
	}
}
