.project-extras {
  &--devider {
    margin: 20px 0;
  }

  &--content {
    &--root {
      margin: 0 15px;
      overflow: hidden;
    }
  }
}
