@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.material-selctors-div {
  padding: 0 0.3em;

  &,
  .flex {
    display: flex;

    > div {
      &:first-of-type {
        margin-right: 24px;
      }

      &:last-of-type {
        margin-left: 0;
      }
    }
  }

  &__buttons {
    margin-top: 16px;
    padding: 0;
    margin-left: -5px;
    text-transform: capitalize;

    .label {
      margin-left: 5px;
    }

    .outlined {
      width: 225px;

      & {
        height: 35px !important;
      }

      .upload-project-material-selector-field {
        padding: 8px 15% 8px 0;
        margin: 2px 0 0 10px;
        align-items: center;
        width: calc(85% - 10px);
        max-width: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        @include typography();

        @include breakpoint(1920px) {
          font-size: 14px;
        }
      }
    }
  }
}

.material-selector {
  flex: 1 1 0%;
  text-transform: capitalize;
  margin: 0 0.2em 0 0.5em;
  text-align: left;
  /* margin-left: 10px; */
}

.material-selector-configure {
  flex: 1 1 0%;
  text-transform: capitalize;
  padding-right: 0.5em;
  text-align: left;

  .hide-icon {
    display: none;
  }
}

.material-middle-selector {
  max-width: 120px;
  margin: 0 0.3em;
}

.material-last-selector {
  flex: 3;
  max-width: 20em;
}

.material-selector-configure-wrapper {
  display: flex;
  flex-direction: initial !important;
  align-items: center !important;
  width: unset !important;
}

.material-selector-configure-batch-size {
  &-text {
    font-size: $configuration-property-text;
    color: black;
    padding-top: 9px;
    padding-right: 8px;
    width: calc(100% - 94px); //text - input width
  }

  &-label-wrapper {
    width: 100% !important;
  }

  &-input {
    input {
      padding: 0 10px;
      width: 94px;
      height: 35px;
      border-radius: 2px;
      border: solid 1px #dedede;
      font-weight: 400;
      font-size: 14px;
    }

    div {
      &:before,
      &:after {
        display: none; //remove material-ui line
      }
    }
  }
}

.material-selector-configure-materials-button {
  font-size: $configuration-property-text;
  cursor: pointer;
  color: #222222;
  text-align: left !important;
  margin-top: 10px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.arrow-down {
  transition: all 0.3s ease-in-out !important;
}

.arrow-down-rotate {
  transform: rotate(90deg);
}

.material-selector--no-select-field {
  align-self: center;
  max-width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
  color: #848484;
}

.material-selector--box {
  &.select-box {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 24px;
    height: 35px;

    @include breakpoint(1860px) {
      font-size: 14px;
      padding: 12px 24px;
    }
  }
}
