.in-house-printers {
	&-selector {
		justify-content: flex-start;
		gap: 24px;
		text-align: left;
	}
  &-frame {
    margin-top: 90px;
    position: relative;
  }
  &-checkbox {
    margin-top: -115px;
    position: fixed;
  }
}
