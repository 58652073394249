@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../style/globalStyles.scss';

.linear-progress-bar {
  &.small-view {
    .linear-progress-bar__box {
      margin-top: 25px;
      padding: 0;
      box-shadow: none;

      + .linear-progress-bar__box {
        .flex-column {
          margin-left: 10px;
        }
      }
    }

    .linear-progress-bar__info {
      width: calc(100% - 60px);

      &.flex-column {
        p {
          align-items: flex-start;
          width: 450px;
        }
      }
    }

    .linear-progress-bar--progress-indicator {
      font-size: 16px;
      width: 45px;
    }

    .progress {
      width: 295px;
      min-width: inherit;
    }

    .linear-progress-bar__info {
      p, span {
        font-size: 14px;
      }
    }

    .linear-progress-bar__loader {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    h2 {
      font-size: 16px;

      span {
        display: none;
      }
    }
  }

  &__box {
    margin: 44px auto 32px;
    width: 100%;
    max-width: 1406px;
    padding: 40px;


    h2 {
      margin: 0 0 24px;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: $text-regular;
      @include flex(center, flex-start);

      span {
        margin-left: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: $text-regular;

        svg {
          margin-right: 5px;
        }
      }
    }

    &-progress {
      @include flex(center, flex-start);
      max-width: 1155px;

    }
  }

  &__info {
    @include flex(center, flex-start);
    width: calc(100% - 140px);

    &.flex-column {
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      .loading-text {
        &:after {
          position: absolute;
          content: '\00a0\00a0\00a0';
          animation: progress-ellipsis 2s infinite;
        }

        @keyframes progress-ellipsis {
          0% {
            content: '\00a0\00a0\00a0';
          }
          30% {
            content: '.\00a0\00a0';
          }
          60% {
            content: '..\00a0';
          }
          90% {
            content: '...';
          }
        }
      }

      p {
        top: 30px;
        left: 10px;
        position: absolute;
        @include flex(center, flex-start, column);

        span {
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: $text-regular;
          text-align: left;

          &.loading-text {
            font-size: 14px;
            line-height: 16px;
            color: $darkGrey;
          }
        }
      }
    }

    .progress {
      width: 100%;
      max-width: 854px;
      min-width: 500px;
      height: 23px;
      background: $background;
      border-radius: 50px;
      margin: 0 8px;

      .progress-bar {
        background: $progress-bar-gradient;
        border-radius: 50px;

        &.empty {
          background: transparent;
        }
      }
    }
  }

  &--progress-indicator {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $text-regular;
    width: 60px;
  }

  &--files-indicator {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: $text-regular;
    min-width: 160px;
  }

  &__loader {
    border-radius: 100%;
    margin-right: 40px;
    @include flex(center, center);
    width: 100px;
    height: 100px;
    background: #FFFFFF;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);

    svg {
      width: 50px;
    }
  }
}