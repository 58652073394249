.radio-from-control {
  display: flex;
  flex-direction: column;
}
.weight-progress-alert {
  &#{&} {
    width: 900px !important;
    left: 42% !important;
  }
  &--body {
    max-height: 493px;
    height: 500px;
  }
}

.icons {
  width: 40px;
  height: 40px;
}
.picker {
  &--headers {
    font-size: 14px;
    color: black !important;
    padding-top: 9px;
    padding-right: 8px;
    width: 100% !important;
    font-weight: normal;
  }
  &--inputs {
    width: 75%;
    height: 31px;
    font-size: 13px;
    font-weight: bold;
    &#{&} {
      width: 238px;
      margin-right: 10px;
    }
  }
}
.step2-wrapper {
  &#{&} {
    flex-wrap: nowrap;
    margin-top: 23px;
  }
  &--header {
    margin-top: 30px;
    font-weight: bold;
  }
}
.material-recommendation {
  &--textFields-wrapper {
    display: flex;
  }
  &--header {
    margin-top: 20px;
    font-weight: bold;
  }
}
.step1 {
  &--headers {
    font-weight: bold;
    &-subHeader {
      margin-top: 19px;
      margin-bottom: 7px;
      font-weight: bold;
    }
  }
  &--form-label {
    &--root {
      &#{&} {
        align-items: flex-start;
      }
    }
  }

  &--radio-button {
    &#{&} {
      margin-top: 6px;
    }
  }
  &--selector-input {
    &#{&} {
      height: 31px;
      line-height: 2.4em;
    }
  }
  &--image {
    width: 57%;
  }
  &--recommendation-wrapper {
    display: flex;
    flex-direction: row;
    &--text-wrapper {
      margin-top: 28px;
      &--title {
        margin-top: 18px;
      }
      &--body {
        font-weight: bold;
      }
    }
    &--image-wrapper {
      padding: unset;
      margin-top: 42px;
      margin-left: 154px;
      &-title {
        margin-bottom: 7px;
      }
    }
  }
}
.step2-radio-button {
  &--headers {
    font-weight: bold;
    &-subHeader {
      margin-top: 19px;
      margin-bottom: 7px;
      font-weight: bold;
    }
  }
  &--radio {
    &#{&} {
      width: 40px;
      height: 40px;
    }

    &--label {
      &#{&} {
        align-items: unset;
      }
      &--header {
        font-weight: bold;
        margin-top: 7px;
      }
      &--firstSection {
        margin-top: 26px;
      }
      &--secondSection {
        margin-top: 23px;
        &-explanation {
          font-weight: bold;
        }
        &-subExplanation {
          margin-top: 11px;
        }
      }
    }
  }
}
