@import 'src/style/globalStyles.scss';
@import 'src/assets/style/base/colorsTheme.scss';

$border: 1px solid lighten($text-regular, 80%);
$filterWidth: 190px;

.filters-for-part {
  @include flex(center, flex-start, row);
  font-weight: normal;

  &__menu {
    width: max-content;
    position: absolute;
    top: 55px;
    left: 0;
    border-bottom: $border;
    border-left: $border;
    border-right: $border;
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 14px 16px;

    h4 {
      color: $darkTextColor;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 12px;
    }

    &-box {
      position: relative;
    }

    &-body {
      @include flex(flex-start, flex-start);

      > div {
        width: 170px;

        > div {
          height: 180px;
          overflow: auto;
          @include styled-scroll(darkgrey, 3px);
        }
      }
    }
  }

  .border-button {
    font-size: 14px;
    max-height: 45px;
    padding: 8px 20px;
    border-radius: 8px;
    margin-right: 20px;
    text-transform: uppercase;

    svg {
      margin-right: 10px;
    }

    &:hover {
      background-color: darken($button-primary, 20);
    }
  }

  &__select {
    width: $filterWidth;
    background: #FFFFFF;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 20px !important; //override
    padding: 10px 17px !important;
    margin-right: 7px !important;

    &.open-filter {
      box-shadow: none;
      border-top: $border;
      border-left: $border;
      border-right: $border;
      border-radius: 2px !important;

      svg {
        transform: rotate(180deg);
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $text-regular;
      font-weight: 400;
      font-size: 16px;
      text-transform: capitalize;
    }

    &:before,
    &:after {
      display: none;
    }

    & > svg {
      margin-right: 10px;
      margin-top: 2px;
    }
  }

  .default-value {
    color: $text-regular;
    font-weight: 600;
    font-size: 16px;
  }

  &__list {
    @include flex(center, flex-start, row);
    flex-wrap: wrap;
    margin-top: 10px;

    svg {
      width: 15px;
    }

    &-selected {
      @include flex();
      background: #F1F1F1;
      border-radius: 100px;
      color: $text-regular;
      height: 25px;
      cursor: pointer;
      margin: 4px;
      padding: 4px 16px;
    }

    &-text {
      color: $text-regular;
      font-size: 14px;
      min-width: fit-content;

      + svg {
        margin-left: 5px;

        path {
          fill: $text-regular;
        }
      }
    }
  }

  &__option {
    @include flex(center, flex-start, row);
    cursor: pointer;
    justify-content: flex-start;
    //padding: 5px 15px 10px 5px;
    height: 30px;
    width: 100%;
    margin-bottom: 5px;

    &:hover {
      background-color: lighten($text-regular, 80%);
    }

    * {
      color: $text-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    &-title {
      white-space: nowrap;
      @include text-ellipsis();
    }

    &.clear-all {
      @include flex(center, center, row);
      margin: 0;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      align-self: center;

      * {
        color: $button-primary;
      }
    }
  }
}
