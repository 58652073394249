@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.rating-block {
	margin: 10px 0;

	&--title {
		font-size: 18px;
		font-weight: 400;
	}

	&--stars {
		@include flex(center, flex-start);
		margin-top: 5px;

		svg {
			width: 30px;
			height: 30px;

			&:hover {
				cursor: pointer;
			}

			&.filled {
				fill: $button-primary;
			}

			&:not(.filled) {
				path {
					fill: $button-primary;
				}
			}
		}
	}
}
.thank-you-modal {
	margin-top: -150px !important;
	margin-left: -220px !important;
}
