@import '../../../../../style/globalStyles.scss';
@import '../../../../../assets/style/base/colorsTheme.scss';

.switch-view {
	&--wrapper {
		@include flex(center, flex-start, row);
		height: 32px;
		width: 86px;
		border-radius: 8px !important;
		border: 1px solid $lightGrey;
		margin-left: 15px;
		padding: 4px;
	}
	&--button {
    @include flex;
    width: 50%;
    height: 100%;
    border-radius: 4px;
    cursor: pointer;
    &.selected {
      background-color: $select-box-selected-background;
    }
	}
}
