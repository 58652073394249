@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../style/globalStyles.scss';

.part-analysis-button-text-transform {
  @include flex();
  text-transform: initial !important;
  z-index: 9;

  &.disabled {
    pointer-events: none;
  }
}

.part-analysis-button-root {
  width: 194px;
  height: 43px;
  border-radius: 2px !important;
}

.part-analysis-button-label {
  font-size: 17.5px;
}

.part-analysis-header-button {
  margin: unset !important;
  width: auto;
  align-self: flex-end;
  z-index: 1;
}

.part-analysis-header-title {
  color: $text-regular;
  font-size: 36px;
  align-self: flex-end;

  &-number {
    font-size: 24px;
  }
}

.single-configuration {
  width: 100%;
  margin-top: 29px;
  z-index: $app_z_index;
  display: flex;
  flex-direction: column;
}
