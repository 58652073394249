@import '../../../assets/style/base/colorsTheme.scss';

.standard-cost {
	display: flex;
	align-items: center;

	&--error-message {
		margin-top: 10px;
	}

	&--alert {
		width: 600px !important;

		&--body {
			&#{&} {
				max-height: 500px;
			}
		}
	}

	&--container {
		max-height: 210px;
		display: inline-block;
		overflow-y: auto;
		margin-top: 10px;

		&::-webkit-scrollbar {
			width: 7px;
			border-radius: 10px;
			background-color: hsla(218, 76, 15, 0.08);
		}

		&::-webkit-scrollbar-thumb {
			background-color: hsla(0, 0, 66, 0.7);
			border-radius: 10px;
		}
	}

	&--approve-icon {
		&#{&} {
			fill: $success;
		}
		&.inside-transparent {
			margin: 0 -16px 0 16px;
		}
	}
}
