@import '../../../assets/style/base/designTheme.scss';
@import '../../../assets/style/base/colorsTheme.scss';

.customize-form {
  width: 97%;
  height: auto;
  margin: 5%;
  padding: 16px 0;

  &--divider {
    margin-top: 1rm;
  }
}

.material-picker {
  width: 90%;

  &--currency-sign {
    font-size: 1rem;
  }

  &--text {
    margin-right: 1rem;
    margin-left: 1rem;
    width: 8.5rem;
  }

  &--select {
    margin-right: 0.5rem;
    margin-left: -3.5rem;
    width: 18rem;
  }
}

.custom-placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  color: $text-regular-opac;
}

.price-flex {
  align-items: center;
  justify-content: center;
  display: flex;

  .material-picker {
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }
  .small {
    width: 70px;
    margin-left: -5px;
  }
}
