.castor-icon {
  width: 171px;
}

.benefit-icon-wrapper {
  margin: 0.1em;
  z-index: 3 !important;
  display: flex;
}

.details-popup-data-benefits-message {
  top: -5px !important;
}
