@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.grid-element {
  border-radius: 5px;
  background-color: $text-white;
  overflow: hidden;
  height: 100%;

  &--content {
    padding: 20px 0 0 20px;
  }

  &--top-line {
    width: 100%;
    height: 5px;

    &--primary {
      background-color: $button-primary;
    }

    &--benefit {
      background-color: $statsCardTopLine;
    }
  }

  &--title {
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    margin-right: 15px;

    &--wrapper {
      display: flex;
      align-items: flex-end;
      margin-bottom: 35px;
    }
  }

  &--subtitle {
    font-size: 18px;
  }
}
