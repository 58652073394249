$background: #f1f1f1;
$success: #008c13;
$warning: #ff9200;
$riskWarning: #2568dd;
$object-header-text: #000000;
$object-header-text-focused: #ffffff;
$object-header-background: #ffffff00;
$object-content-background: #ffffff;
$button-primary: #3e3e42;
$button-primary-light: #556679;
$transparent-button-primary: #556679;
$button-primary-on-background: #556679;
$button-secondary: transparent;
$button-info: #dbdbe3;
$button-info-font-color: #3e3e42;
$button-hover-color: #E60610;
$button-hover-icon: #E60610;
$text-regular: #4B4B4B;
$text-light-opac: #a1a1a1;
$text-regular-opac: #848484;
$text-white: #ffffff;
$devider-color: #abb3bb;
$regular-opac: #ebecee;
$long-text-read-more-background: #f5f5f5;
$textOnSuccessBackground: #ffffff;
$benefitsBackground: #d1d8e0;
$benefitsText: #556679;
$advancedText: #6e49ee;
$regularBenefitsText: #25313c;
$benefitsBackgroundLight: #556679;
$benefitsBackgroundOpac: #d1d8e0;
$pieBenefitsShipments: #7fc5c9;
$roiTable: #7fc5c9;
$excelButtonColor: #008c13;
$listItemBackground: #efd2ea;
$statsCardTopLine: #41bdd5;
$cardBackground: hsla(0, 0, 13%, 0.1);
$cardShadow: rgba(0, 0, 0, 0.13);
$cardShadowHover: rgba(0, 0, 0, 0.28);
$partBackground: #dfe5f3;
$newFeatureTagGradient: linear-gradient(to left, #5daf88, #3abde5);
$takeATourGradient: linear-gradient(to right, #b9e5c7, #8de2f3 100%);
$takeATourBgColor: #b9e5c7;
$takeATourButtonColor: $button-hover-color;
$successText: #186a1e;
$successBackground: #caf7ba;
$primaryTableText: #757579;
$primaryTableHeader: #525256;
$primaryBorder: #dbdbe3;
$solutionTabButton: #ff4900;
$combineIconFill: #f2f2f9;
$warningIcon: #ffa500;
$progress-bar-gradient: linear-gradient(90deg, #3F607A 0%, #E60610 153.96%);
$box-header-gradient: linear-gradient(180deg, #556679, #3D4A57);
$button-border-color: #556679;
$quick-upload-feedback-background: linear-gradient(90.95deg, rgba(100, 200, 130, 0.4) 0%, rgba(32, 192, 230, 0.4) 100%);
$count-note-color: #CF1322;
$disabled-background-color: #dedcdc;
$sidebarIcon: #000000;

//Table colors
$tableBackground: transparent;
$tableBorderColor: $primaryBorder;
$tableHeaderBackground: #e6e6ee;
$tableButtonsBorder: #fafaff;
$tableButtonsActiveBackground: #404e5a;
$tableButtonsBackground: #dbdbe3;
$tableButtonsHover: #9f9fa7;
$tableButtonsActiveHover: #2c353e;

//Tooltip colors
$tooltip-background-color: #d1d8e0;
$tooltip-title-font-color: #848484;
$tooltip-body-font-color: #222222;

//Tabs colors
$tab-button-active-text-color: #E60610;
$tab-button-text-color: #4B4B4B;
$tab-button-border-color: #E60610;

  //Chart color
$chartMethodColor: #ec7d33;
$chartCrossColor: #977CF3;
$chartAMColor: #0ab0ef;

//toolbar
$toolbarBackground: #323e49;
$orientation-icon-color: #222222;

$unlock-background: #ffc700;

//filter block
$grey-search-bar: #6D6D6D;
$background-search-bar: #F7F9FF;

//Select box block
$select-box-padding: 12px 24px;
$select-box-border: #4B4B4B;
$select-box-text-color: #4B4B4B;
$select-box-selected-color: #556679;
$select-box-selected-background: rgba(85, 102, 121, 0.1);

// analysis page
$darkTextColor: #323232;
$darkGrey: #848484;
$lightGrey: #DEDEDE;
$lightBlue: #556679;
$whiteColor: #ffffff;
$blackColor: #000000;
$benefitIconColor: #556679;
$benefitIconBackgroundColor: rgba(85, 102, 121, 0.2);
$whiteBackground: #F1F1F1;
$warnColor: #E8000E;
$hoverColor: #efefef;
$hoverPartColor: #20c0e60d;

//onboarding page
$disabledStepColor: #bdbdbd;
$currentStepColor: #20c0e6;
$completedStepColor: #64c882;
$stepTextColor: #fff;
$stepCardHeaderBackground: linear-gradient(
	135deg,
	rgba(32, 192, 230, 0.16) 0%,
	rgba(100, 200, 130, 0.16) 100%
);