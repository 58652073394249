@import '../../../assets/style/base/colorsTheme.scss';

.castor-slider {
  &#{&} {
    align-self: center;
    width: 96%;
  }

  .rc-slider-handle {
    background-color: $button-primary;
    margin-left: unset;
    // margin-top: unset;
    // width: unset;
    // height: unset;

    &:focus {
      margin-left: -7px;
      margin-top: -5px;
      width: 14px;
      height: 14px;
    }
  }

  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;
    top: 8px;
  }

  &--tooltip {
  }
}
