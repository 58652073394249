.success_notification {
  background: #4caf50;
  border-radius: 3px;
  white-space: pre-wrap;
  text-align: left;
}

.error_notification {
  background: #f44336;
  border-radius: 3px;
}

.info_notification {
  background: #64c882;
  border-radius: 3px;
}

.warn_notification {
  background: #ff9800;
  border-radius: 3px;
}

.notification {
  z-index: 9999999999;
}

.Toastify__toast-body {
  white-space: pre-line;
  /*text-align: start;*/
}
