@import '../../../../../../../../../../../style/globalStyles.scss';
@import '../../../../../../../../../../../assets/style/base/colorsTheme.scss';

.cost-analysis-table-buttons {
  width: 100%;
  text-align: left;
  margin-top: -5px;

  &--group {
    height: 36px;
    border-radius: 6px;
  }

  &--button {
    padding: 0 !important; // override Mui styles
    min-width: 0 !important; // override Mui styles

    &__content {
      display: flex;
      align-items: flex-end;

      svg {
        width: 22px;

        path {
          fill: $tab-button-text-color;
        }

        &.graphic-icon {
          width: 19px;
        }
      }

      span {
        margin-left: 5px;
        text-transform: capitalize;
        color: $tab-button-text-color;
      }
    }

    &:hover {
      background: transparent !important; // override Mui styles

      .cost-analysis-table-buttons--button__content {
        svg {
          path {
            fill: $tableButtonsActiveBackground;
          }
        }

        span {
          color: $tableButtonsActiveBackground;
        }
      }
    }

    &.active {
      .cost-analysis-table-buttons--button__content {
        svg {
          path {
            fill: $tableButtonsActiveBackground;
          }
        }

        span {
          color: $tableButtonsActiveBackground;
        }
      }
    }
  }

  button + button {
    margin-left: 15px;
  }
}
