@import '../../../../../style/globalStyles.scss';

.start-screen {
	&--wrapper {
		@include flex(center, center, column);
		height: 100%;
		gap: 40px;
	}
	&--greeting {
		@include flex(center, flex-start, column);
		@include font-builder(20px, 500);
		gap: 20px;
		&--logo {
			width: 300px;
		}
	}
	&--description {
		@include font-builder(20px, 400);
		max-width: 50%;
		p {
			margin-bottom: 28px;
			&:last-child {
				margin: 40px 0 0;
			}
		}
	}
	&--button {
		text-transform: uppercase !important;
	}
}
