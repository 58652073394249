@import '../../../../../style/globalStyles.scss';
@import '../../../../../assets/style/base/colorsTheme.scss';
.admin-user-form {
	&--field {
		width: 200px;
		margin: 10px 0;
		text-transform: capitalize;
		input {
			width: 100%;
		}
	}
	&--select {
		width: 200px;
		position: relative;
		&--selected-values {
			text-transform: capitalize;
		}
		&--error {
			color: $solutionTabButton;
			position: absolute;
			left: 0;
			bottom: 5px;
			font-weight: 400;
			text-transform: capitalize;
		}
	}
}
.reset-button {
	width: 23%;
}
.submit-button {
	background-color: $solutionTabButton !important;
	border-color: $solutionTabButton !important;
	&--icon {
		width: 20px;
		height: 20px;
		margin-left: 10px;
		color: $text-white;
	}
}
.date-picker-wrapper {
	margin: 10px 0 !important;
	width: 200px;
	margin: 8px 8px;
	div,
	input {
		width: 100%;
	}
}
.select-role-wrapper {
	@include flex(flex-start, center, column);
	margin: 10px 0;
}
.menu-item {
	text-transform: capitalize;
}
