@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.data-table {
  &#{&} {
    display: grid;
    justify-items: start;
    grid-row-gap: 23px;
    column-gap: 16px;
    align-items: center;
  }

  &--inside-row {
    font-size: 0.8rem;
  }

  &--radio {
    &.disabled {
      opacity: 0;
    }
    &#{&} {
      display: flex;
      align-items: center;
      padding: 0 !important;
    }

    &--color {
      height: 100%;
      width: 6px;
      border-radius: 0 4px 4px 0;
      min-height: 63px;
    }
    &--input {
      width: 48px !important;
    }
  }

  &--first-column {
    padding-left: 4px !important;

    &--inside-row {
      &#{&} {
        padding-left: 25px;
      }
    }

    &--parent {
      @include flex();
      cursor: pointer;

      &--chevron {
        transition: transform 0.3s ease-in-out !important;

        &.rotate {
          transform: rotate(90deg);
        }
      }
    }

    &.cursor-pointer {
      .data-table-row-text {
        cursor: pointer;
      }
    }
  }

  &--break-row-line {
    grid-column: 1/-1;
    border: 0.5px solid #dededeb8;
    width: 100%;
  }

  &-results {
    &--review {
      &--open-button {
        &#{&} {
          position: fixed;
          bottom: 0;
          align-self: flex-end;
        }
      }
    }
    &--grid {
      &#{&} {
        grid-row-gap: 8px;
        overflow: auto;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 10px;
      }
    }
  }

  &-header {
    font-weight: bold;
    background: #dedede66;
    width: 100%;
    text-align: left;
    padding: 10px 0;

    &--multiple-lines {
      width: 100%;
      background: #dedede66;
      padding: 9.5px 0;
    }
  }

  &-text-field {
    color: $text-regular;
    text-align: left;
    white-space: nowrap;

    &:not(.cost-summary--table--text--title) {
      height: 20px;
      @include text-ellipsis();

      &.full-width {
        width: 100%;
      }

      &.without-border-italic{
        font-style: italic;
        width: 100%;
      }

      &.full-columns {
        grid-column-start: 3;
        grid-column-end: 12;
        text-align: center;
        font-weight: bold;
        width: 100%;
      }
    }
  }

  &-opacity-field {
    color: $text-regular-opac;
  }

  &-long-text {
    &-field {
      color: $text-regular-opac;
      text-align: left;
      margin: auto 0;

      &-without-read-more {
        white-space: pre-line !important;
      }
    }

    &-popper {
      &-message {
        background-color: $long-text-read-more-background;
        white-space: pre-wrap;
      }

      &-target {
        display: inline;
        cursor: pointer;
        color: $text-regular;
      }

      &-data {
        color: $text-regular-opac;
        font-size: 15px;
        width: 300px;
        white-space: initial;
      }
    }
  }

  &-button {
    @include tranparent-button();

    &-wrapper {
      justify-self: end;
    }
  }

  &-rows {
    @include flexible-column();
  }

  &-row {
    @include flexible-row();
    justify-content: unset;
    flex-direction: row-reverse;
    gap: $table-row-column-gap;

    &.icon-right {
      flex-direction: row;
    }

    &.with-i-icon {
      width: 65%;
      gap: 5px;

      .details-popup-target {
        cursor: pointer;
        margin-bottom: 5px;
        margin-left: 0;
      }

      .info-box-wrapper {
        width: auto;
      }
    }

    &-icon {
      width: $table-row-icon;
      height: $table-row-icon;
      min-height: $table-row-icon;
      min-width: $table-row-icon;

      &--info-box-wrapper {
        &#{&} {
          &#{&} {
            width: auto;
          }
        }
      }
    }

    &-icon-part {
      fill: #a5a4a4;
      height: 18px;
      width: 18px;
      margin-left: 3px;
    }

    &-text {
      text-align: left;
    }

    &-description {
      color: $tooltip-title-font-color;
      text-align: left;
      width: 400px;
    }
  }

  &-input {
    &-text {
      width: 100%;
      height: 32px;

      &.without-border {
        border: none;
        border-bottom: 0.5px solid;
      }
    }
    &-number {
      width: 100%;
      height: 32px;

      &.without-border {
        border: none;
        border-bottom: 0.5px solid;
      }
    }
  }

  &-dropdown {
    background-color: white;
    border: 1px solid black;
    width: 100%;

    &.without-border {
      border: none;
      border-bottom: 0.5px solid;
    }
  }

  .deviation-block {
    div {
      text-align: left;
    }
  }

  .deviation-block {
    div {
      text-align: left;
    }
  }

  &-button-with-loader {
    display: flex;
    align-items: center;
    min-width: 70px;
    min-height: 70px;
  }
}
