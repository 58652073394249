.printers-materials-costs {
	&--wrapper {
		.customize-printer-props--content--inputs--wrapper,
		.customize-material {
			justify-content: flex-start !important;
			gap: 25px;
		}
		.printer-costs-table,
		.materials-costs-table {
			margin: 24px 12px;
			max-width: 100% !important;
			width: calc(100% - 24px);
		}
		.material-costs-input-hint {
			text-align: left;
		}
		h3 {
			margin-top: 10px !important;
		}
		.weight-selector-info{
			max-width: 148px !important;
		}
	}
}
