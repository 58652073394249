@import 'src/style/globalStyles.scss';
@import 'src/assets/style/base/colorsTheme.scss';

$box-btn-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.2),
	0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);

.parts-header {
	&--not-assembly,
	&--assembly {
		@include flex();
	}

	&--assembly {
		.standard-cost,
		.parts-properties-button {
			order: 2;

			button {
				font-size: 16px;
				border-radius: 4px;
				max-height: 45px;
				padding: 10px 20px;
				margin: 0 !important;

				@include breakpoint(xl) {
					padding: 15px 35px;
				}
			}
		}

		.export-button {
			order: 3;
			background: $text-white;
			box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07) !important; //override important
			border-radius: 4px;
			border: 0 !important; //override important
			margin: 0 20px !important; //override important

			.export-text {
				font-size: 14px;
			}
		}

		.parts-properties-button {
			order: 1;

			&.middle {
				margin: 0 !important; //override important
				button.transparent {
					color: $transparent-button-primary;
					font-size: 14px;
					transition: all 0.2s ease-in-out;
					text-transform: capitalize;
					border-radius: 0 !important;
					border-right: 1px solid $lightGrey !important;
					background-color: transparent;
					padding: 0 16px;
					margin: 0;
					cursor: pointer;
					height: 20px;
					box-shadow: none !important;

					&:hover {
						color: $button-primary-light !important;
						box-shadow: 0 0 transparent !important;
					}
				}
			}
		}

		.parts-header--remove-button {
			order: 4;
			border-radius: 4px;
			padding: 0 0 2px 0;
			margin-right: 20px !important;
			height: 20px;

			&:hover {
				svg {
					fill: #000000;
				}
			}

			svg {
				fill: $text-regular-opac;
			}

			&--text {
				display: none;
			}
		}

		.parts-properties-button,
		.standard-cost {
			button {
				&.transparent {
					color: $transparent-button-primary;
					font-size: 14px;
					transition: all 0.2s ease-in-out;
					text-transform: uppercase !important;
					border-radius: 0 !important;
					border-right: 1px solid $lightGrey !important;
					background-color: transparent;
					padding: 0 16px;
					margin: 0;
					cursor: pointer;
					height: 20px;
					box-shadow: none !important;

					&:hover {
						color: $button-primary-light !important;
						box-shadow: 0 0 transparent !important;
					}
				}
			}
		}

		.export-button {
			&.transparent {
				color: $transparent-button-primary !important;
				font-size: 14px;
				transition: all 0.2s ease-in-out;
				text-transform: uppercase !important;
				border-radius: 0 !important;
				border-right: 1px solid $lightGrey !important;
				background-color: transparent;
				padding: 0 16px;
				margin: 0 !important;
				cursor: pointer;
				max-height: 20px !important;
				box-shadow: none !important;
				&.in-quick-cad {
					border: none !important;
					padding-right: 0 !important;
				}

				&:hover {
					color: $button-primary-light !important;
					box-shadow: 0 0 transparent !important;
					svg {
						path {
							fill: $button-primary-light !important;
						}
					}
				}
			}
		}
	}

	&--not-assembly {
		flex-direction: column;

		> div {
			width: 100%;
		}

		.parts-properties-button + .standard-cost {
			button {
				margin: 0;
			}
		}

		.parts-properties-button,
		.standard-cost,
		.export-button,
		.parts-header--remove-button {
			width: 100%;
			margin: auto;
		}

		.standard-cost {
			button {
				width: 100%;
			}
		}
	}
}
