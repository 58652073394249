@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/colorsTheme.scss';

$label: #a2a2a2;

.light-user-upload-project {
	&-alert {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 20px;

		&-logo {
			padding-bottom: 10px;
			width: 250px;
		}

		&-name,
		&-email,
		&-company,
		&-address {
			width: 80%;
		}

		&-address {
			div {
				padding: 0;
				width: 100%;
			}

			label {
				color: $label;
				font: inherit;
				font-size: 1rem;
				font-weight: 400;
			}

			input {
				padding-right: 30px;
				@include text-ellipsis();
			}

			.location-search-input {
				&:before {
					background-color: rgba(0, 0, 0, 0.42);
				}
			}

			.light-user-upload-project-alert-error {
				position: absolute;
			}
		}

		&-error {
			color: red;
		}
	}

	&-bold-text {
		font-weight: 500;
	}
}

//fix the zIndex of shadow
div {
	&:has(+ .not-available-modal) {
		z-index: 5500 !important;
	}
}

.castor-alert {
	&--body {
		max-height: 500px;
	}

	&.not-available-modal {
		top: 58% !important;
		left: 48% !important;
	}

	div {
		&:has(+ .zip-code) {
			margin-bottom: 15px !important;
		}
	}
}

.address-input-root {
	width: 100%;
	padding-top: 5px !important;
	margin-bottom: 10px !important;

	&:has(+ .state) {
		margin-bottom: 5px;
	}

	&:not(.state) {
		margin-top: -15px !important;
	}

	label[data-shrink='false'] {
		top: -5px;
	}
}

.country-select-root {
	padding-bottom: 5px !important;

	&.state {
		padding-top: 5px !important;
	}
}

div[role='presentation'] {
	&.dropdown {
		z-index: 6500 !important;
	}
}

.light-alert {
	.castor-alert--body {
		padding-right: 25px;
		padding-left: 25px;
		padding-top: 25px;
		padding-bottom: 15px;	
	}
}
