$windowWidth: 720px;

.upload-script {
  align-self: center;
  text-align: left;

  .main-content-scroller {
    min-width: $windowWidth;
    max-width: $windowWidth;
    margin: auto;
  }

  &-body {
    margin-top: 10px;
  }

  &-error {
    color: #e74c3c;
  }

  &-button {
    width: 100%;
    margin: 2px 0 0;
    height: unset;
  }

  &-text {
    width: $windowWidth;
    align-self: center;
    align-items: flex-start;
  }

  &-dropzoneDrag,
  &-dropzone {
    max-width: $windowWidth;
    cursor: pointer;
    height: 160px;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/img/dropzoneBG.png');
  }

  &-dropzoneDrag {
    border: 5px solid green;
  }

  &-dropzone {
    &__flex {
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &__icon {
      margin: 8px;
      transform: scale(1.5);
    }
  }
}
