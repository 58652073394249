.admin {
  &--users {
    &--user-info {
      width: 50%;
      min-width: 600px;
      grid-column-gap: 0 !important;
      column-gap: 0 !important;

      &--field {
        max-width: unset !important;

        &--title {
          font-weight: bold;
          text-transform: capitalize;
        }
      }
    }
    &--icon-loader {
      display: inline-block;
      margin-top: 4px;
    }
  }
}
