//castor-detail
$castor-detail-title-font-weight: 200;
$castor-detail-title-font-size: 12.7px;

//Castor Alert.
$object-content-border-width: 2px;
$object-content-border-radius: 0;
$object-content-review-btn-flex-direction: flex-end;
$object-content-padding: 41.5px 58px 30px 58px;
$object-header-icon-display: inherit;
$object-header-padding: 0 24px;
$object-header-margin: 0;
$object-header-font-size: 19px;
$object-header-font-weight: 200;
$object-footer-divider-display: inherit;
$object-header-show-icon: none;
$object-header-name-width: 275px;
$show-popup-header-text-in-box: true;
$popup-full-width-top: 35px;

//textField
$text-field-label-font-size: 16px;
$text-field-tab-font-size: 14px;
$text-field-header-font-size: 16px;

//customize
$customize-material-printer-tech-width: 6rem;
$customize-material-material-name-width: 6rem;
$customize-material-picker-price-width: 6rem;
$customize-material-picker-unit-width: 6rem;
$customize-printer-company-width: 10rem;
$customize-printer-name-width: 8rem;
$customize-printer-cost-company-width: 165px;
$customize-printer-cost-name-width: 165px;
$customize-printer-price-width: 6.6rem;
$customize-printer-layer-thickness-width: 8.2rem;
$in-house-printers-selector-width: 10em;

//geometry analysis alert
$part-display-width: 33%;
$alert-divider-display: block;

//table
$table-column-with-text-width: 45%;
$cost-analysis-chart-height: 200px;

//configuration
$expand-config-height: 631px;
$big-expand-config-height: 730px;
$part-image-size: 395px;
$solution-analysis-shadow: none;
$solution-analysis-object-content-margin: 37px 35px 15px 0;
$data-table-results-grid-height: 270px;
$configuration-block-max-width: 98%;
$configuration-block-margin: 0 auto 75px;

$app_z_index: unset;
$header_z_index: 1090;
$main-panel-z-index: 1;
$main-panel-z-index-md-bp: 1140;

$configuration-property-text: 14px;

//base
$space-for-scroller: 70px;
$space-for-banner: 64px;
$menu-font-weight: bold;
$button-hover-style: underline;
$table-row-icon: 15px;
$table-row-column-gap: 6px;

//Upload page
$upload-icon-size: 75px;
$sidebar-text-weight: bold;
$select-box-icon-display: none;
$box-config-height: fill-available;
$upload-page-height: auto;
$upload-max-page-height: 1280px;
$quick-upload-icon-size: 35px;

//button
$button-border-radius: 8px;
$cancel-button-margin-right: 20px;

//toolbar
$header-toolbar-logo-height: 18px;

//usageAgreement
$usage-agreement-header-align: initial;

//onboarding
$onboardingBackground: bottom right no-repeat
	url('../../img/svg/QuickCADUpload.svg');
