@import 'src/assets/style/base/colorsTheme.scss';
@import 'src/style/globalStyles.scss';

.info-box {
  &-wrapper {
    &#{&} {
      width: auto;
    }

    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $tooltip-background-color;

    &.big {
      min-width: 310px;
    }

    &__content {
      p {
        @include flex(center, space-between, row-reverse);
        border-bottom: 1px dashed $primaryBorder;

        span {
          margin: 0 10px;
        }

        .small {
          text-transform: lowercase;
        }
      }
    }
  }

  &-data {
    &#{&} {
      width: auto;
    }

    height: 100%;
    margin: auto;
    font-size: 16px;
    letter-spacing: normal;
    color: $tooltip-body-font-color;
    text-align: left;

    p {
      color: $text-regular-opac;

      span {
        color: $tooltip-body-font-color;
      }
    }
  }

  &-icon {
    fill: $text-regular-opac;
  }
}
