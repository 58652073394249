.poweredBy {
  &--text {
    font-size: 12px;
    color: gray;
  }
  &--logo {
    width: 85px;
    vertical-align: top;
  }
}
