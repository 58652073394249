@import '../../../assets/style/base/colorsTheme.scss';

.items-list {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 5px 10px;

  &--item {
    border-radius: 1px;
    background-color: $listItemBackground;
    font-size: 12px;
    color: $text-regular-opac;
    padding: 0 15px 0 5px;
    position: relative;
    cursor: default;

    &:hover {
      color: $text-regular;
    }

    &--remove {
      cursor: pointer;
      margin-left: 5px;
      font-size: 10px;
      position: absolute;
      right: 5px;
      top: -1px;
    }
  }
}
