@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../style/globalStyles.scss';

.progress-bar {
	&--wrapper {
		@include flex(center, center, row);
		gap: 8px;
		padding: 40px 0;
		width: 100%;
		@media screen and (max-width: 1710px) {
			width: 85%;
		}
		@media screen and (max-width: 1470px) {
			width: 90%;
		}
		@media screen and (max-width: 1370px) {
			width: 100%;
		}
	}
	&--step {
		@include flex(center, flex-start, row);
		gap: 8px;
		&--number {
			@include flex();
			width: 24px;
			min-width: 24px;
			height: 24px;
			border-radius: 50%;
			background-color: $disabledStepColor;
			color: $stepTextColor;
			&.completed {
				background-color: $completedStepColor;
			}
			&.current {
				background-color: $currentStepColor;
			}
		}
		&--title {
			@include font-builder(16px, 500);
			color: $text-regular;
			&.disabled {
				color: $darkGrey;
			}
		}
	}
	&--connector {
		width: 27px;
		height: 1px;
		background-color: $currentStepColor;
		&.disabled {
			background-color: $disabledStepColor;
		}
	}
}
