@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.drop-down-value {
  display: flex;
  width: 100%;
  height: 100%;

  &--material-name {
    font-size: 14px;
    margin-top: -5px;
    @include text-ellipsis();
  }

  &-printer-name {
    font-weight: bold;
    margin-top: 5px;
  }

  &--cost {
    margin: auto;
    font-weight: bold;
  }
}
.select-drop-down {
  &#{&} {
    margin-right: 0.5rem;
    width: 13rem;
    font-size: 23px;
    color: $text-regular;
    white-space: nowrap;
    text-align: left;
    pointer-events: none;
  }

  &--menu {
    &#{&} {
      padding: 0 0 0 3px;
      height: 52px;
    }

    &--last {
      &#{&} {
        margin-bottom: -8px;
      }
    }
  }

  &--select {
    &#{&} {
      padding: 0 32px 0 0;
      min-height: unset;
      line-height: unset;
    }
  }
  &--icon {
    &#{&} {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      width: 40px;
      height: 40px;
      top: -10px;
    }
  }
}
