@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.search-bar {
	border-radius: 8px;
	border: 1px solid $lightGrey;
	height: 45px;
	width: 320px;
	@include flex();

	& > div {
		width: 100%;
		padding: 0 18px;
		color: $grey-search-bar;

		svg {
			fill: $grey-search-bar;
			font-size: 20px;
		}

		& > div {
			@include flex();

			&:before,
			&:after {
				display: none;
			}

			input {
				margin-left: 5px;
				color: $text-regular-opac;
				font-weight: 400;
				font-size: 16px;
				&::-webkit-search-cancel-button {
					display: none;
				}
			}
		}
	}
	.clear-input {
		order: 1;
		cursor: pointer;
		&.hidden {
			display: none;
		}
	}
	.search-icon {
		order: 2;
		&.clickable {
			cursor: pointer;
		}
	}
}
