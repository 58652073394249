@import '../../../../../../style/globalStyles.scss';
@import '../../../../../../assets/style/base/designTheme.scss';
@import '../../../../../../assets/style/base/colorsTheme.scss';

.part-tabpanel {
  &--details {
    &_accordion {
      background: $whiteColor;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.1) !important;
      border-radius: 8px !important;
      margin-bottom: 20px !important;

      &:before {
        display: none;
      }
    }

    &_content {
      flex-direction: row-reverse;

      > div {
        padding: 12px 12px 12px 0;
        align-items: center;
      }

      .expand {
        position: relative;
        transform: rotate(270deg);

        &.open {
          transform: rotate(360deg);
        }
      }

      .new-feature-tag {
        margin-left: 16px;
      }
    }

    &_body {
      padding: 0 51px 12px !important;

      .details {
        &__block {
          width: 100%;
          @include flex(center, space-between);

          &.map {
            align-items: flex-start;
            min-height: 550px;

            .add-new-configuration--form {
              padding: 0 0 0 24px;
            }

            .grey-block {
              padding: 0;
              background-color: white;

              .react-reveal > div {
                margin-bottom: 40px;
              }

              .rc-slider-step {
                background-color: $whiteBackground;
              }

              .rc-slider-track {
                background-color: $button-primary;
                z-index: 1;
              }

              &.property {
                height: 230px;
              }
            }

            .solution-analysis-object-content-configure-widget {
              margin: 0;
            }

            .loader {
              width: 100% !important;
              // 68px is block header height
              height: calc(100% - 68px) !important;
              bottom: 0;
              left: 0;
            }

            .solution-analysis-object-content-configure {
              &-widget {
                margin-top: 25px;
                width: 100%;

                p {
                  margin: 0;
                }
              }

              &-top-header {
                width: 90%;
                margin-bottom: 12px;
              }

              &-widget-feature {
                @include flex();
              }

              &-widget--inputs {
                height: 200px;

                > div {
                  width: 90%;
                }
              }
            }

            .solution-priorities--buttons {
              @include flex(center, space-between);
              margin-left: 5%;
              margin-top: 5px;
            }
          }
        }

        &__information,
        &__block {
          display: flex;
        }

        &__information {
          &-general,
          &-material,
          &-filter {
            text-align: left;

            b {
              @include font-builder(14px, 500, 16px);
            }

            p {
              @include flex(center, flex-start);
              @include font-builder(14px, 400, 24px);
              margin: 5px 0;
            }

            .title {
              color: #848484;
              margin-right: 5px;

              svg {
                margin-right: 4px;
              }
            }
          }

          &-general,
          &-material {
            margin-right: 8vw;
          }
        }

        &__map-analysis {
          .chart {
            width: 481px;
            height: 500px;
          }

          .yx-axis {
            @include flex(center, space-between);
            width: 100%;
          }


          .x-axis,
          .y-axis {
            @include flex(flex-start, flex-start, column);

            .axis-input {
              width: 100%;
            }
          }

          .y-axis {
            width: 38%;
            margin-right: 2%;

            > div {
              width: 100%;
            }
          }

          .x-axis {
            width: 60%;
          }

          .x-axis {
            > div {
              @include flex(center, space-between);
              width: 100%;
            }
          }

          .low-medium-good {
            @include flex(center, space-between);
            font-weight: 500;
            position: absolute;
            width: 87%;
            bottom: 0;
            right: 10px;

            .low {
              color: $warnColor;
            }

            .medium {
              color: $warningIcon;
            }

            .good {
              color: $successText;
            }
          }

          .axis-input {
            background-color: #ffffff;
            border: 1px solid #323232 !important;
            border-radius: 8px;
            text-align: left;
            font-size: 12px;
            text-transform: capitalize;
            color: #323232;
            padding: 0 12px;

            &.without-arrow {
              svg {
                display: none;
              }
            }

            &.top-input {
              margin: 0;
            }

            div {
              &:before {
                display: none;
              }
            }
          }
        }


        &__table {
          width: calc(100% - 490px);

          .body {
            div {
              text-align: left;
            }

            .name {
              max-width: 90px;
              @include text-ellipsis();
              display: block;
              white-space: nowrap;
            }
          }
        }

        &__action {
          padding: 20px 0;
          min-width: 300px;
          @include flex();

          > div {
            margin-top: -48px;

            svg {
              margin-right: 8px;
            }
          }
        }
      }

      .devider {
        height: 100%;
        width: 1px;
        background-color: $lightGrey;
      }
    }

    .part-analysis-header-button {
      margin-top: 12px !important;
      text-transform: uppercase !important;
      border-radius: 8px !important;
      max-width: 280px;
      box-shadow: none;
      padding: 10px 24px !important;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }

      svg {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #20C0E6;
      }
    }

    &_heading {
      @include font-builder(14px, 500, 16px);
      color: $darkTextColor;
      margin-left: 12px;
    }

    &_sub-heading {
      @include font-builder(14px, 400, 16px);
      color: $darkGrey;
      border-left: 1px solid $lightGrey;
      padding-left: 16px;
      margin-left: 16px;
    }
  }
}


#chartID {
  position: relative;
}

.apexcharts-tooltip-1 {
  position: absolute;
  background: #ffffff;
  border-radius: 5px;
  z-index: 11111;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
}

.apexchart-custom-tooltip {
  margin: 0;
  @include flex(flex-start, center, column);
  padding: 6px 12px;
  border-radius: 5px;
  color: #323232;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  .solution-info {
    @include flex(flex-start, flex-start, column);
    max-width: 200px;

    &,
    div {
      text-align: left;
      white-space: normal;
    }
  }
}

.solution-map-table {
  margin-left: 5%;
  margin-bottom: 40px;

  .header,
  .row {
    display: grid;
    grid-template-columns: 26% 26% 26% 20%;
    grid-column-gap: 5px;
    padding: 10px 60px 10px 20px;

    &.five-grid {
      grid-template-columns: 25% 25% 20% 10% 20%;
    }
  }

  .row {
    margin-bottom: 8px;
  }

  .header {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #323232;
    margin: 0 -20px - 20px;

    > div {
      white-space: nowrap;

      span {
        margin-left: 2px;
      }
    }
  }

  .body {
    @include styled-scroll();
    max-height: 715px;
    overflow: auto;
    padding: 10px 20px 20px;
    margin: 0 -20px - 20px;
  }

  .row {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    border: 1px solid transparent;
    outline: 1px solid transparent;

    &.is-hover,
    &:hover {
      cursor: pointer;
      border: 1px solid #323232;

      .position {
        background-color: #323232;
      }
    }

    &.best-position {
      background: #E0F4E6;
      outline: 1px solid #64C882;

      &.is-hover,
      &:hover {
        border: 1px solid #64C882;

        .position {
          background-color: #64C882;
        }
      }
    }

    div {
      @include flex(center, flex-start);
      cursor: default;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #323232;

      button {
        cursor: pointer;
        text-align: left;
      }
    }
  }

  .position-block {
    @include flex(center, flex-start);

    .position {
      width: 22px;
      height: 22px;
      border-radius: 100%;
      background-color: #848484;
      margin-right: 14px;
      @include flex(center, center);
      color: #ffffff;


      &.best-position {
        background: #64C882;
      }
    }
  }

  .button-block {
    height: 20px;
    min-width: 90px;

    button {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $lightBlue;
      width: 100%;
      min-width: 0;
      padding: 0;
      margin: 0;
      display: inline-block;
      box-sizing: border-box;
      white-space: pre-line;
      word-wrap: break-word;

      .launch {
        position: absolute;
        right: -18px;
        top: -2px;
        width: 16px;
        height: 16px;
      }

      span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &.best-button {
      button {
        color: #64C882;
        margin-right: 4px;
      }
    }

    svg {
      position: absolute;
      right: 24px;
      top: -1px;
    }
  }
}

.apexcharts-xaxis-annotation-label {
  white-space: pre-line;
}