@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

$grabCadFillColor: #232D4F;
$grabCadIconColor: #29A8DF;

.grab-blocks {
  width: auto;
  position: absolute;
  right: 10px;
  top: 10px;

  &.part-analysis-button-text-transform {
    &.grab-configuration {
      width: inherit;
      border: 1px solid transparent !important;
      background-color: white;
      color: $grabCadFillColor;
      position: relative;
      right: 0;
      top: 0;
      text-transform: uppercase;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      &:hover {
        border-color: $lightBlue !important;
      }

      > span {
        text-transform: uppercase;
        white-space: nowrap;
        margin: 0 2px 0 -10px;
      }

      &.selected {
        background-color: $lightBlue;
        color: white;

        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }

        .print-with {
          path {
            fill: white !important;
          }
        }

        svg {
          &:has(+span) {
            filter: drop-shadow(0px 0px 3px rgba(93, 224, 255, 0.8));
            height: 45px !important;

            .letter {
              fill: $grabCadIconColor !important;
            }
          }

          .letter-top {
            fill: white !important;
          }

          .letter {
            fill: $lightBlue !important;
          }

          path:not(.letter) {
            fill: $lightBlue;
          }
        }
      }

      .print-with {
        display: block;
        width: 75px !important;
        height: 11px !important;
        margin-top: 1px;
        margin-right: 10px;

        path {
          fill: $grabCadFillColor !important;
        }
      }

      svg {
        height: 35px !important;
        width: 40px !important;

        rect {
          display: none;
        }

        .letter-top {
          fill: white !important;
        }

        .letter {
          fill: $grabCadFillColor !important;
        }

        path:not(.letter) {
          fill: $grabCadFillColor;
        }
      }
    }
  }

  &.view-only:not(.selected) {
    rect {
      fill: white;
    }

    .letter {
      fill: black;
    }
  }

  &:not(.view-only) {
    &:hover {
      cursor: pointer;

      img {
        scale: 1.1;
      }
    }
  }

  &.selected {
    img {
      filter: grayscale(0);
    }
  }

  img {
    width: 70px;
  }
}
