.threed-iframe-alert {
  width: 1010px !important;
  max-width: 96%;
  height: 99%;
  left: calc((100% - 1010px) / 2) !important;
  top: calc((100% - 99%) / 2) !important;
  z-index: 5000 !important;
  margin: auto !important;

  h2 {
    margin: 0;
  }

  .lead {
    height: 90%;
  }
}

.threed-iframe {
  height: 85%;
}

@media screen and (max-width: 1100px) {
  .threed-iframe-alert {
    left: calc((100% - 96%) / 2) !important;
  }
}
