body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}
