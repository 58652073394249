.project-not-uploaded {
    &-arrow {
        &-icon {
            margin-top: 20px;
            height: 100px;
            width: 100px;
            transform: rotate(270deg);
            margin-bottom: 100px;
        }
    }
    &-text {
        font-size: 26px;
        font-weight: bold;
        padding-right: 100px;
        line-height: 40px;
    }

    &-icon {
        height: 200px;
        width: 100px;
    }

    &-spacer {
        height: 50px;
    }
}