.data-sheet {
  width: 100%;
}

.data-grid-container .data-grid .cell > input {
  height: unset;
  width: 100%;
}

.value-viewer {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
