@import '../../../../../../style/globalStyles.scss';
@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../assets/style/base/designTheme.scss';

.sidebar-poweredby {
  align-self: center;
  padding: 20px 0;
  margin-right: 10px;
  width: 100%;
  z-index: 4;

  &--logo {
    width: 115px !important;
  }
}

.sidebar {
  &--new-tag {
    position: absolute;
    top: 15px;
    right: 17px;
  }

  &--text {
    &#{&} {
      @include text-ellipsis;
      font-weight: $menu-font-weight;
    }
  }

  &-collapse {
    @include styled-scroll();
  }
}

#sidebarWrapper {
  @include styled-scroll();

  @media only screen and (max-width: 900px) {
    top: 10px;
    height: 100vh;
  }

  .link-loader {
    width: 25px;
    padding-left: 5px;
  }

  div + .sidebar--text {
    padding-left: 7px !important;
  }

  .sidebar--text {
    padding-right: 5px;
    max-width: 75%;
  }

  .info-box-wrapper {
    width: auto;
  }

  a {
    width: 90%;
    transition: all ease-in 10ms;

    &.active {
      svg {
        path {
          fill: $object-header-text-focused;
        }
      }
    }

    &.top-nav-link {
      svg {
        path {
          fill: $sidebarIcon;
        }
      }
    }
  }

  svg {
    path {
      fill: $sidebarIcon;
    }
  }
}

.hidden {
  display: none;
}

.sidebar-with-collapse {
  &.parts-sidebar {
    margin: 0;

    > a {
      &:has(+ div) {
        position: relative;
      }
    }

    &:not(.collapse-block) {
      .sidebar--text {
        padding: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
      }
    }

    svg {
      display: none;
    }
  }

  .caret-block {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 5px;

    &:hover {
      > b {
        scale: 1.5;
      }
    }

    > b {
      position: relative;
      margin: 0;
      right: -5px;
      top: -5px;
    }
  }
}

.collapse-block {
  &__list {
    height: auto !important;

    &.parts-list {
      &::before {
        content: "";
        position: absolute;
        left: 40px;
        width: 1px;
        top: -20px;
        height: 100%;
        background: $object-header-text;
      }

      a {
        margin-left: 50px;
        width: 80% !important;

        &::before {
          position: absolute;
          content: "";
          height: 1px;
          width: 10px;
          left: -10px;
          top: 20px;
          background: $object-header-text;
        }
      }
    }
  }
}
