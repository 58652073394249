@import 'src/assets/style/base/colorsTheme';

.custom-theme {
  width: 730px !important; //override inline style
  h2 {
    padding-top: 16px !important;
  }
}

.custom-range-block {
  width: 300px;
  margin-left: 10px;

  .single-range {
    margin: 25px 10px;

    &:first-of-type {
      margin-top: 0;
    }

    p {
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }

  .check-boxs--icon {
    margin-left: -10px;
  }
}
