.wall-thickness {
  &--alert {
    width: 580px !important;
    z-index: 6000 !important;

    &--input {
      margin-top: 20px !important;
      align-items: center;

      > div,
      p {
        width: 100%;
      }

      p {
        font-size: 14px;
      }

      &.grey {
        > div {
          &:before,
          &:after {
            display: none;
          }
        }

        input {
          border: 1px solid #dedede;
          padding: 10px;
          border-radius: 3px;
        }
      }

      &.error {
        input {
          border-color: red;
        }
      }

      &,
      input {
        width: 31em;
      }
    }
    &--body {
      padding: 40px !important;
    }
  }
}
