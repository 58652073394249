@import '../../../../../style/globalStyles.scss';

.cost-function-editor-frame {
	&--body {
		h5 {
			@include font-builder(16px, 400);
      text-align: left;
			padding-left: 10px;
			margin: 0 0 10px;
		}
	}
	.costing-function-editor--body {
		padding: 10px;
		color: $darkTextColor;
	}
}
