@import '../../../../assets/style/base/colorsTheme.scss';

.custom-material-names {
  &--divider {
    opacity: unset;
    margin-top: 5px !important;
    margin-left: 5px !important;
    width: 98%;
  }

  &--add-material-text {
    width: 335px;
    margin-right: 20px;
  }

  &.text {
    min-width: 280px;
    padding-right: 20px;
    margin-left: 15px;
  }
  &--title {
    color: #565656;
    margin-top: 20px;
    font-size: 16px;
    font-weight: normal;
    padding-top: inherit;
    margin-bottom: 7px;
    font-weight: 500;
    padding-left: 13px;
  }
  &--explanation {
    display: flex;
    justify-content: space-between;
    color: #797878;
    margin-bottom: 7px;
    padding-left: 13px;
    margin-right: 20px;
  }

  &--button {
    color: white;
  }

  &--AddButton {
    color: white;
  }
}
