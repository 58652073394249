@import '../../../style/globalStyles.scss';

.tag-text {
  text-transform: unset;
  max-width: 100%;
  @include typography();
}

.tag-buttons {
  @include flex(center, flex-end, row);
}
