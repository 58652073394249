@import '../../../assets/style/base/colorsTheme.scss';

.export-button {
	margin: unset !important;
	position: unset !important;
	background-color: transparent !important;
	color: #78be20 !important;
	padding: 6px 13px !important;
	height: 40px;
	box-shadow: unset !important;
	text-transform: capitalize !important;
	border-radius: 2px !important;
	border: 2px solid #78be20 !important;
}

.export-icon {
	width: 20px;
	height: 20px;
}

.export-csv {
	margin: -3px 8px 0 0;
}

.export-text {
	margin-left: 8px;
	font-size: 18px;
	font-weight: 400;
	height: 20px;
}
