@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.new-feature-tag {
  width: 52px;
  height: 21px;
  color: $text-regular;
  font-weight: bold;
  border-radius: 10px;
  background-image: $newFeatureTagGradient;
  text-transform: uppercase;
  @include flex;

  &.white {
    color: white;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    background: linear-gradient(92.18deg, #64C882 3.7%, #20C0E6 98.17%);
    text-transform: initial;
  }
}
