@import '../../../assets/style/base/colorsTheme.scss';

.three-d {
	&--button {
		width: 77px;
		height: 31px;
		padding: 4px 10px !important;
    &:hover {
      svg {
        &, g, path {
          fill: $button-hover-color;
          stroke: $button-hover-color;
        }
      }
    }
	}
	&--icon {
		margin: 0px 5px;
	}
}
