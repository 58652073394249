@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../style/globalStyles.scss';

$min-ok-button: 85px;

$min-ok-button: 85px;

.new-configuration-form {
  width: 1000px;
  margin: 100px auto;
  padding: 37px 33px;
  background: $tableBackground;
  border-radius: 10px;
  box-shadow: 0 2px 7px 0 hsla(0, 0%, 0%, 0.16);
  z-index: $app_z_index;

  .flex {
    display: flex;
  }

  .center {
    align-items: center;
  }

  .end {
    justify-content: flex-end;
  }

  .start {
    align-items: flex-start;
  }

  &--header {
    justify-content: space-between;
    margin-bottom: 50px;

    &__title {
      color: #000000;
      font-weight: bold;
      font-size: 24px;
    }
  }

  &--body {
    &__top {
      align-content: center;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    &__center {
      > div {
        margin-bottom: 40px;
      }

      p {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  &--footer {
    &-cancel-button,
    &-ok-button {
      min-width: $min-ok-button;

      button {
        // margin-bottom: 0;
      }
    }

    &-cancel-button {
      &#{&} {
        background-color: $button-secondary;
        margin-right: 10px;
        color: $text-regular-opac;
      }
    }

    &-ok-button {
      min-width: $min-ok-button;

      &#{&} {
        display: flex;
        justify-content: center;
      }
    }
  }

  .configure-wrapper {
    &__section {
      flex-direction: column;
      margin-right: 10px;

      .financial-form-quantity {
        height: 47px;
        padding: 15px;

        input {
          color: #000000 !important; //override nesting styles
          padding: 0;
        }

        * {
          &:after,
          &:before {
            display: none;
          }
        }
      }
    }

    &-manufacturing {
      flex-direction: column;
      align-items: flex-start;
      margin-right: 10px;
    }

    &-objectives {
      @include flex(end, flex-start, row);
    }

    &-material {
      align-items: center;

      > div {
        flex-direction: row !important;

        > div {
          position: relative;
          margin-right: 10px;

          &:first-of-type {
            margin-right: 10px;
          }
        }
      }

      .label {
        left: 0;
        font-size: $configuration-property-text;
        position: absolute;
        top: -20px;
        font-weight: bold;
        text-align: start;
        color: #3c4858;
      }

      button {
        border-radius: 2px;
        color: $tableButtonsActiveBackground;
        width: 42px;
        height: 42px;
        top: -1px;

        &:hover {
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .cancel-button {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.04);
    border-color: transparent;
    background-color: $tableButtonsBorder;
    color: $tableButtonsActiveBackground;
    padding: 10px 30px;
    margin-right: 10px;

    &:hover {
      color: $tableButtonsActiveBackground;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    }

    .details-popup-target {
      svg {
        margin-left: 5px;
        fill: $tableButtonsActiveBackground;
      }
    }
  }

  .ok-button {
    min-width: $min-ok-button;
    background-color: $tableButtonsActiveBackground;
    color: $tableHeaderBackground;
  }

  .configuration-input {
    margin-right: 15px;

    &:first-of-type {
      margin-right: 5px;
    }
  }

  .text_field__label {
    margin-top: -20px;
  }

  .material-selector-configure,
  .text_field__label {
    font-size: $configuration-property-text;
    font-weight: bold;
    margin-bottom: 0;
    color: #3c4858;

    svg {
      fill: $text-regular;
    }
  }

  .buttons-section {
    margin-top: -33px;
  }

  .material-selector-configure {
    width: 300px;

    div {
      width: 270px;
    }

    svg {
      right: 15px;
    }
  }

  .material-selector-configure,
  .post-processes-button__white,
  .financial-form-quantity {
    > div {
      font-weight: bold;
      position: relative;
    }
  }

  .material-selector-configure,
  .configure-manufacturing-method,
  .post-processes-button__white,
  .financial-form-quantity {
    border: 1px solid $devider-color !important;
    border-radius: 6px;
    background-color: $tableButtonsBorder !important;
    color: #000000;
    text-align: left;
    justify-content: flex-start;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold;

    div {
      border: 0;
      font-size: 16px;
      top: -2px;
    }
  }

  .post-processes-alert {
    margin-left: 0;
    padding: 27px 33px;
  }

  .post-processes-button {
    &__white {
      width: 300px;
      height: 50px;

      svg {
        width: 18px;
        margin-right: 14px;
      }

      .counter {
        background-color: $tableButtonsActiveBackground;
        color: $tableHeaderBackground;
        font-size: 16px;
        line-height: 24px;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        text-align: center;
        position: absolute;
        right: 35px;
      }

      &:after {
        content: '+';
        position: absolute;
        right: 12px;
        font-size: 25px;
        font-weight: 100;
      }
    }
  }
}
