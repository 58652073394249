@import '../../../../../../../assets/style/base/designTheme.scss';
@import '../../../../../../../assets/style/base/colorsTheme.scss';

.material-quantity-ribbon {
	font-size: $text-field-label-font-size;
	margin-bottom: 25px;
	width: 100%;
	justify-content: space-between;
	align-items: flex-end !important;
	gap: 10px;

	> div {
		width: 100%;
		text-align: center;

		> div {
			width: 95%;
		}

		.material-selector-configure {
			> div {
				> div {
					width: 95%;
					height: 50px;
					line-height: 50px;
					border-radius: 6px;
					margin: 0;
					font-size: $text-field-header-font-size;
					letter-spacing: 0.16px;
					color: #000000;
				}
			}
		}
	}

	.quantity {
		input {
			border: 1px gray solid;
			border-radius: 6px;
			height: 50px;
			padding: 0 25px;
			font-size: $text-field-header-font-size;
			letter-spacing: 0.16px;
			color: #000000;

			&:hover {
				border: 1px gray solid;
			}

			&:disabled {
				border-color: #dedede;
			}
		}

		* {
			&:after,
			&:before {
				display: none;
			}
		}
	}

	.post-process {
		margin: 0 0 2px 0;

		svg {
			height: 20px;
			width: 30px;
		}
	}
	&--material-select {
		height: 50px !important;
		border-radius: 6px !important;
		margin: 0 !important;
    line-height: 3em !important;
    color: $blackColor !important;
    width: 100% !important;
    font-size: 16px !important;
		&--root {
			background: transparent !important;
		}
	}
}

.selector-option {
	text-transform: capitalize;
}
