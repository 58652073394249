@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.lock-part {
	border-radius: 9px !important;
	background-color: $unlock-background;

	&-body {
		padding: 0;
		border-radius: 9px;
		background-color: $unlock-background;
		@include flex(center, center, column);
		pointer-events: unset !important;

		&-contact {
			@include flex(center, center, column);
			padding: 10px;
			height: 250px;

			@include breakpoint(1200px) {
				height: 300px;
			}

			@include breakpoint(1860px) {
				height: calc(100px + 5vh + 15vw);
			}

			svg {
				width: 50px;
				height: 43px;

				@include breakpoint(1680px) {
					width: 78px;
					height: 71px;
				}
			}

			&-text {
				font-weight: 300;
				font-size: 1.5rem;
				color: $text-regular;
				text-align: center;

				@include breakpoint(xl) {
					font-size: 2rem;
				}

				@include breakpoint(1680px) {
					font-size: 39px;

					&-1 {
						margin-top: 34px;
					}

					&-2 {
						margin-top: 22px;
					}

					&-3 {
						margin-top: 29px;
						font-size: 21px;
						line-height: 23px;
					}
				}

        &-1, &-2 {
          margin-top: 15px;
          line-height: 1em;
        }

				&-3 {
					margin-top: 20px;
					color: $text-regular;
					font-size: 14px;
					font-weight: normal;
					line-height: 16px;
				}
			}
		}

		&-button {
			height: 52px;
			border-radius: 3px !important;
			padding: 12px 17px !important;
			background-color: $text-regular !important;
			border-color: $text-regular !important;
			margin-top: 20px !important;
			padding-bottom: 7px !important;
			text-transform: capitalize !important;
			font-size: 22px !important;
			font-weight: 400 !important;
			color: #ffffff !important;

			@include breakpoint(xl) {
				padding: 12px 30px !important;
			}

			@include breakpoint(1680px) {
				margin-top: 44px !important;
			}
		}
	}
	&--list-view {
		position: relative;
		width: 100%;
		display: grid;
		grid-template-columns:
			94px minmax(150px, 1fr) minmax(275px, 1fr) repeat(3, 175px)
			70px;
		align-items: center;
		border-radius: 8px;
		box-shadow: 0 0 10px $cardShadow;
		height: 60px;
		margin: 16px 0;
		&:hover {
			background-color: $hoverPartColor;
		}
		&--cell-img {
			@include flex();
			width: 90px;
			height: 60px;
			background-color: $unlock-background;
			border-radius: 8px 0 0 8px;
			svg {
				width: 50px;
			}
		}
		&--cell-text {
			white-space: nowrap;
			padding-left: 16px;
			font-weight: 400;
		}
		&--upgrade-btn {
			position: absolute !important;
			right: 20px;
			top: 14;
      margin: 0;
      height: 32px;
      border-radius: 8px !important;
      text-transform: uppercase !important;
      padding: 12px 20px !important;
		}
	}
}
