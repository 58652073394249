@import '../../../../assets/style/base/colorsTheme.scss';

.solution-feature-full-tray {
  &--tog {
    align-items: center;
    margin-inline-start: 10px;
  }

  &--info {
    width: 261px;

    &-data {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: $text-regular-opac;
    }

    &-wrapper {
      display: flex;
    }
  }
}
