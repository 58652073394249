@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../style/globalStyles.scss';

.multiple-projects {
  &__form {
    width: 65%;

    .menu-item {
      width: 250px;
      @include flex(space-between, center);

      .menu-text {
        width: 200px;
        @include text-ellipsis();
      }
    }
  }

  &__selected-value {
    @include text-ellipsis();
  }


  .error {
    position: absolute;
    bottom: 0;
    color: $warnColor;
  }
}

.multiple-projects-select-paper {
  width: 250px;
  height: 550px;
  padding-bottom: 0;

  ul {
    padding: 0;
  }

  .multiple-projects-select-paper-button {
    @include flex(center,center);
    position: sticky;
    background-color: white;
    width: 100%;
    height: 60px;
    top: 0;
    padding: 5px;
    z-index: 1;

    button {
      margin: 0;
    }
  }

  .multiple-projects__select-only {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    background-color: #f6fafe;
    padding: 2px 10px;
  }
}