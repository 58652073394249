.qr-img {
  align-self: center;
}
.tow-factor-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 142px;
}
.send-button {
  padding: 5px 20px !important;
}
.login-problems-link {
  margin-top: 10px;
  text-align: left;
  margin-left: 12px;
  cursor: pointer;
}

.two-fa-block {
  .number-text-field {
    width: auto;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #dedede;
    text-overflow: ellipsis;
    margin-right: 10px;

    input {
      margin: 0;
      padding: 6px 15px;
      font-weight: 300;

      + fieldset {
        border: none;
      }
    }

    > div {
      &:before,
      &:after {
        display: none;
      }
    }
  }
}
