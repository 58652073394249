@import '../../../../assets/style/base/designTheme.scss';

.customize-material {
  column-gap: 20px;
  width: 100%;
  min-width: 800px;

  div {
    max-width: 155px;
    margin-right: 10px;
  }

  .customize-material-selector {
    &--printer-tech {
      width: 95%;
    }

    &--material-name {
      width: $customize-printer-cost-company-width;
      margin-right: 25px;
    }
  }
}
