@import '../../../style/globalStyles.scss';
@import '../../../assets/style/base/colorsTheme.scss';

.card-in-row {
  margin: 25px 0 0 !important;

  .box-config {
    min-width: 640px;
  }

  .material-selctors-div {
    .field-with-label {
      width: 200px;
    }
  }

  .files {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;

    .files-upload-content__icon {
      path {
        fill: url(#svg-gray-gradient);
      }
    }

    .new-upload-project__files {
      height: initial;
      max-height: initial;
      justify-content: center;

      .files-upload-content__dropzone {
        .upload-project-dropzone {
          height: 155px;
          margin-bottom: 32px;

          @include breakpoint(1860px) {
            height: 170px;
          }
        }
      }
    }
  }
}