@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';

.text_field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &__remove-icon {
    color: $text-regular-opac;
    width: 20px !important;
    height: 20px !important;
  }

  &__romeve_button {
    margin: 0 !important;
    align-self: flex-start !important;
    padding: 0 !important;
  }

  &__info_icon {
    margin-left: 5px;
  }

  &__label {
    font-size: $text-field-label-font-size;
    color: $text-regular-opac;
    text-align: left;
    margin-bottom: 10px;
  }

  &__error {
    width: 130%;
    text-align: left;
    padding-top: 3px;
  }

  &__input {
    width: auto;
    height: 37px;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid $regular-opac;
    padding: 0 15px;
    text-overflow: ellipsis;

    &_error {
      border-color: red;
    }
  }
}
