@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.weight-icon {
  width: 231px !important;
}

.icons {
  &--benefits {
    &--fill {
      fill: $benefitsBackground;
    }

    &--text {
      fill: $regularBenefitsText;
      &--stroke {
        stroke: $regularBenefitsText;
      }
    }

    &--light {
      &--back {
        fill: $benefitsBackgroundLight;
      }
      &--text {
        fill: $benefitsText;

        &--stroke {
          stroke: $benefitsText;
        }
      }
    }
  }

  &--fill {
    &--success {
      fill: $success;
    }

    &--warning {
      fill: $warning;

      &--risk {
        fill: $riskWarning;
      }
    }

    &--notPrintSuitable {
      fill: $button-primary;
    }

    &--printer {
      fill: $roiTable;
    }
  }

  &--fill-and-stroke {
    &--primary-on-background {
      fill: var(--solution-color);
      stroke: var(--solution-color);
    }
  }
}

.icon-buy-to-fly {
  @include flex();
  border-radius: 20px;
  max-height: 37px;
  background: $benefitsBackground;
  padding: 5px;

  .text {
    @include flex(center, center, row);
    font-weight: bold;
    color: $regularBenefitsText;
    border: 1px solid $regularBenefitsText;
    border-radius: 100px;
    font-size: 14px;
    padding: 0 6px;
    white-space: nowrap;
  }

  .small {
    transform: translate(0,1px);
  }

  svg {
    height: 28px;
    width: 120px;
  }
}
