.user-panel {
  padding: 20px;

  &--grid {
    display: grid;
    grid-template-columns: repeat(10, minmax(80px, 1fr));
    grid-template-rows: repeat(6, minmax(120px, 1fr));
    gap: 15px;
    text-align: left;
  }
}
