@import '../../../../../../assets/style/base/colorsTheme.scss';

.alert {
  &--message {
    margin-bottom: 9px;
  }
}

.tolerances-changer {
  .tolerance-class-menu {
    border: 1px solid $devider-color !important;
    padding: 0 15px;
  }
}
