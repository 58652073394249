@import '../../../style/globalStyles.scss';
@import '../../Home/NewUploadProject/variables.scss';

.castor-info-screen {
	@include flex(center, center);
	width: 100%;
	height: 100%;
	&--block {
		@include flex(center, space-between, column);
		color: $darkTextColor;
		width: 500px;
		height: 380px;
		padding: 40px;
		background-color: $whiteBackground;
		box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		&--heading {
			font-weight: 600;
			margin-bottom: 0;
		}
		&--description {
			@include font-builder($mediumFont, $mediumFontWeight, $mediumFontHeight);
		}
		&--button {
			border-radius: 8px !important;
		}
	}
}
