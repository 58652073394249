@import '../../../assets/style/base/colorsTheme.scss';

.contact-us {
  &--wrapper {
    &#{&} {
      width: auto;
      max-width: 500px;
      border-radius: 8px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: $long-text-read-more-background;
    }

    &--popper {
      width: auto;
    }
  }

  &--button {
    &#{&} {
      padding: 0;
      margin-left: 10px;
      background-color: transparent;
      color: transparent;
      box-shadow: none;
    }
  }
}
