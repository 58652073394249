@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

$alertWidth: 527px;

.castor-alert {
	display: block !important;
	padding: 0 !important;
	width: $alertWidth !important;
	overflow: initial !important; // allow outside the window popup
	background-color: $object-content-background !important;
	box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;

	&.uploading-process {
		width: 630px !important;

		.castor-alert--body {
			padding: 10px 30px 20px;
		}
	}

	&.modal-black {
		width: 384px !important;

		.header-logo {
			filter: invert(1);
			position: absolute;
			top: 20px;
			width: 82px;
		}

		.modal-header {
			background: #323232;
			height: 67px;

			button {
				top: 0;

				svg {
					width: 14px;
				}

				svg,
				path {
					fill: #ffffff;
				}
			}

			&-block {
				background: transparent;
				box-shadow: none;
			}
		}

		.popup-message {
			width: 300px;
			text-align: center;
		}
	}

	&.indent-modal {
		padding: 8px 0 !important; //override important

		.castor-alert--body {
			font-size: $text-field-label-font-size;
			padding: 10px 16px 10px 20px;
		}

		.modal-footer {
			&-buttons {
				button {
					font-family: inherit;
				}
			}

			&-cancel-button {
				box-shadow: 0 2px 2px 0 rgba(219, 219, 226, 0.14),
					0 3px 1px -2px rgba(219, 219, 226, 0.2),
					0 1px 5px 0 rgba(219, 219, 226, 0.2);
				background-color: $tableButtonsActiveBackground;

				span {
					color: #ffffff;
				}
			}
		}
	}

	&.medium-size {
		width: calc(100% - 280px) !important;
		max-width: 880px;
		left: 45% !important;
		@include breakpoint(1301px) {
			max-width: 980px;
		}
		z-index: 5000 !important;

		&.without-sidebar {
			left: 10% !important;
			overflow: auto;
		}

		.castor-alert--body {
			max-height: 683px;
			padding-left: 24px;
			padding-right: 24px;
		}
	}

	&.with-subtitle {
		.modal-header-block {
			visibility: hidden;
			z-index: 1;
		}

		.header-title {
			z-index: 10;
			width: 155px;
			background: $box-header-gradient;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
			border-radius: 8px;
			padding: 12px 20px;
			color: $whiteColor;
			position: absolute;
			top: -20px;
			left: 20px;
			@include flex(flex-start, flex-start, column);

			.title {
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
			}
		}
	}

	&.full-screen {
		width: calc(100% - 280px) !important;
		max-width: 96%;
		max-height: 99%;
		top: $popup-full-width-top !important;
		margin: auto !important;
		left: calc(10% + 140px) !important;

		@include breakpoint(sm) {
			left: calc(10% + 135px) !important;
		}

		@include breakpoint(xl) {
			left: 270px !important;
		}

		&.without-sidebar {
			left: 10% !important;
			width: 80% !important;
			overflow: auto;
		}
	}

	&.responsive-modal {
		min-width: $alertWidth;
		width: fit-content !important;

		.castor-alert--body {
			width: $alertWidth;
			max-width: fit-content;
		}
	}

	h2 {
		margin: 0;
		font-size: 20px;
		font-weight: bold;
		text-align: left;

		.modal-header {
			padding: $object-header-padding;
		}

		.modal-header-title {
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;
		}
	}

	.castor-devider {
		display: $alert-divider-display;
	}

	&--body {
		text-align: left;
		padding: $object-content-padding;
		white-space: pre-wrap;
		max-height: 400px;
		overflow: auto;

		&.full-screen {
			height: 74vh;
			overflow: auto;
			padding-top: 30px;
			max-height: 741px !important;

			@media screen and (min-height: 750px) {
				height: 77vh;
			}

			@media screen and (min-height: 850px) {
				height: 81vh;
			}

			@include breakpoint(xl) {
				height: 83vh;
			}
		}

		&::-webkit-scrollbar {
			width: 7px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: darkgrey;
			border-radius: 10px;
		}

		.ellipsis-text {
			@include text-ellipsis();
			display: inline-block;
			white-space: nowrap;
			max-width: 200px;
			vertical-align: bottom;
		}
	}

	&--text-field {
		padding: 40px;
	}

	&--text-field-input {
		margin: 0 !important;
	}

	&.custom-theme {
		&#{&} {
			width: 650px;
		}

		h2 {
			font-size: 20px;
			font-weight: bold;
			text-align: left;
			padding: $object-header-padding;
		}

		.castor-devider {
			display: none;
		}
	}
}

@media only screen and (max-width: 900px) {
	.castor-alert {
		top: 15% !important;
		margin: 0 !important;
		width: 100vw !important;
		position: fixed !important;
		left: 0 !important;

		h2 {
			font-size: 14px;
		}

		&--body {
			overflow: hidden;
			font-size: 12px;
		}
	}
}
