@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.castor-breadcrumbs {
  &#{&} {
    z-index: 1111;
    margin: 0.4rem 2rem 0;
    font-size: 0.8rem;
    color: $text-light-opac;
  }

  &--last {
    color: $text-regular;

    .name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--empty {
    &:hover {
      cursor: default;
    }
  }

  &--link {
    color: $text-light-opac;

    &:hover {
      color: $button-primary-light;
    }

    &.disable {
      &:hover,
      &:focus {
        color: $text-light-opac;
      }

      &:hover {
        cursor: initial;
      }
    }
  }

  .tooltip-title {
    &,
    .name {
      max-width: 150px;
      @include typography();
    }
  }
}
