@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.page-not-exist {
  @include flex(center, center, column);
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;

  svg {
    display: none;
    width: 50%;
    height: 50%;
  }


  &.show {
    svg {
      display: block;
    }
  }

  #main-color {
    fill: $lightBlue !important;
  }
}

.App:has(#sidebarWrapper) .page-not-exist {
  .page-not-exist__button,
  svg {
    display: none;
  }

  .text {
    display: block;
    margin-left: 100px;
  }
}