@import '../../../assets/style/base/colorsTheme.scss';

.weight-reduction-section {
  &--button {
    &#{&} {
      text-transform: unset;
      margin: 0;
    }

    &--wrapper {
      width: auto;
    }
  }

  &--subheader {
    &--not-requested {
      color: $text-regular;
    }

    &--icon {
      margin-right: 10px;
    }

    &--text {
      white-space: pre-wrap;
    }

    &--update {
      margin: 11px 5px;
      font-size: 14px;
      line-height: 16px;
      text-decoration-line: underline;
      text-transform: uppercase;

      &--loader {
        // we need important because after build order of styles is changed 
        margin: 4.5px 5px !important;
        width: auto !important;
        display: inline-block !important;
      }
    }

    &--input {
      &#{&} {
        text-align: center;
      }

      &.input-error {
        color: #ff0000;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        //hide input number arrows
        display: none;
      }

      &--wrapper {
        width: 30px;
      }
    }
  }
}

.all-weight-reduction {
  width: 100%;

  &--grid {
    &#{&} {
      width: 100%;
      padding: 15px;
    }

    &--tile {
      padding: 1em;
      margin-bottom: -5px;

      .card {
        box-shadow: 0 0 1px 1px $cardShadow;
      }
    }

    &--size-wrapper {
      min-height: unset;
    }

    .card--footer {
      display: none;
    }

    &--item {
      height: auto !important;

      &--subtitle {
        text-align: left;

        &--link {
          color: $button-primary;

          &:hover {
            color: $button-primary-light;
          }
        }
      }
    }
  }

  &--popup {
    display: initial;

    & + .details-popup--contact-us {
      top: 35px !important;
    }
  }
}

.weight-reduction-feature {
  min-height: 160px;
}