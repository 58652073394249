.banner {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0px 50px !important;
	z-index: 9999999;
	position: fixed;
	width: 100%;
	height: 64px;
	top: 0;

	&--upgrade-now {
		background-color: #ffe1e5;
	}

	&--contact-us {
		background-color: #e4e3e3 !important;
		z-index: 4999 !important;
	}
	&--logo {
		width: 146px;
	}
}

.text-container {
	font-size: 18px;
	font-weight: 600;

	&--upgrade-now {
		color: #eb3953;
	}

	&--contact-us {
		color: black;
	}
}
.button {
	width: 140px;
	height: 35px;
	border-radius: 2px !important;
	font-size: 13px !important;
	margin-left: 20px !important;
	border-radius: 5px !important;

	&--upgrade-now {
		background-color: #eb3953 !important;
	}

	&--contact-us {
		background-color: #20c0e6 !important;
	}
}
