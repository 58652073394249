@import '../../../../../../../../assets/style/base/designTheme.scss';

.review-and-fix {
  align-items: $object-content-review-btn-flex-direction;

  &.small-table {
    .data-table-results {
      &--grid {
        height: auto;
        min-height: 80px;
      }
    }
  }

  button {
    margin-bottom: 0;
    margin-top: 10px;
    margin-right: 5px;
  }
}
