@import '../../../style/globalStyles.scss';

.wizard {
	&--wrapper {
		@include flex(center, flex-start, column);
		padding: 0 20px 5px;
		height: 100%;
		max-height: 100%;
    overflow-y: hidden;
	}
}
