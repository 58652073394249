@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.modal-header {
  align-items: center;
  justify-content: space-between;
  background-color: $object-header-background;
  font-size: $object-header-font-size;
  font-weight: $object-header-font-weight;
  height: 74.5px;
  padding: $object-header-padding;
  @include modal-header-style();

  &.loading {
    > div {
      margin: $object-header-margin;
    }
  }

  &-title {
    color: $object-header-text;
    line-height: 1;
    text-align: left;
  }

  &--type-icon {
    display: $object-header-icon-display;
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  &-icon {
    color: $object-header-text;
  }
}

.modal-footer {
  &-buttons {
    @include flex(center, flex-end);
    padding: 0 25px;
    height: 66px;
  }

  &-cancel-button {
    &#{&} {
      background-color: $button-secondary;
      margin-right: $cancel-button-margin-right;
      border: 1px solid $button-border-color;
      border-radius: $button-border-radius;

      span {
        color: $lightBlue;
      }
    }

    &-label {
      color: $text-regular-opac;
    }
  }

  &-reset-button {
    &#{&} {
      background-color: $button-secondary;
      position: absolute;
      left: 0;

      span {
        color: $lightBlue;
        text-decoration: underline;
      }
    }

    &-label {
      color: $text-regular-opac;
    }
  }

  &-submit-button {
    text-transform: initial !important;
    margin: 0 2px !important;
    background: $lightBlue;
    border-radius: 8px;

    &-label {
      font-size: 17.5px;
    }

    &-root {
      width: 120px;
      height: 43px;
      border-radius: 2px !important;
    }
  }

  .castor-devider {
    display: $object-footer-divider-display;
  }
}
