@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

$svgWidth: 16px;

.warn-message {
	margin-top: -10px;
	margin-bottom: 5px;
	@include flex(center, flex-start);

	a {
		font-weight: 500;
		color: inherit;
		&:hover, &:visited, &:active, &:focus {
			color: inherit;
		}
	}

	svg {
		width: $svgWidth;
		height: $svgWidth;
		margin-right: 5px;
	}

	span {
		width: calc(100% - #{$svgWidth});
	}

	&.absolute {
		margin-top: 0;
		margin-bottom: 0;
		position: absolute;

		&.bottom {
			bottom: -50px;
			min-height: 35px;
			display: inline;
			text-align: center;
			width: 500px;

			svg {
				position: relative;
				top: 3px;
			}
		}
	}
}
