// TODO: Move to a global if reuse in feature
$largeFont: 20px;
$mediumFont: 16px;
$basicFont: 14px;
$smallFont: 12px;

$largeFontWeight: 500;
$mediumFontWeight: 400;
$basicFontWeight: 300;

$smallFontHeight: 14px;
$basicFontHeight: 16px;
$mediumFontHeight: 19px;
$largeFontHeight: 23px;
