@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.additive-minds {
  &.type-button {
    @include flex(center, center);
  }

  button.disabled,
  button:not(.disabled) {
    &.additive-minds__button {
      .details-popup-target {
        margin-bottom: 10px;
      }

      &.solution-analysis-header-text {
        &.filled {
          text-transform: uppercase;
          padding: 0 !important;

          span {
            svg#additive-minds__image {
              width: 38px;
              height: 15px;
              margin-right: 5px;
            }
          }
        }
      }
    }

    &.additive-minds__status {
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 18px;
      line-height: 21px;
      text-decoration-line: underline;
      text-transform: capitalize;
    }
  }

  .castor-devider {
    display: none;
  }

  .upload-info,
  .quantity-info,
  .notes-info,
  .additive-minds-popup {
    margin-top: 32px;
  }

  .label {
    color: $text-regular;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .quantity-info {
    position: relative;

    .error {
      position: absolute;
      bottom: -20px;
      margin: 0;
    }
  }

  .field-with-label__block {
    width: 45%;

    input {
      color: $text-regular;
      height: 40px;
      padding: 0 12px;
    }

    .field-with-label {
      height: 40px;
      margin-top: 4px;
      border-radius: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .printer-info {
    margin-top: 20px;

    .field-with-label__block {
      .field-with-label {
        border: none !important;
        background: $whiteBackground;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  .notes-info {
    .filter-fields--widget--text-field-box {
      border-color: $text-regular !important;

      &:focus,
      &:hover {
        box-shadow: 0 3px 3px #efefef;
      }

      > div {
        padding: 12px;

        textarea {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
        }

        &:after,
        &:before {
          display: none;
        }
      }
    }
  }

  .upload-info {
    position: relative;
  }

  &-dropzone {
    padding: 15px;
    border: 1px dashed $text-regular-opac;
    border-radius: 8px;
    width: 100%;
    height: 190px;

    &.drag-on {
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &:hover {
        cursor: pointer;
      }

      .files-upload-content__icon {
        margin-bottom: 12px;
      }

      p {
        text-align: center;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        &:first-of-type {
          margin-bottom: 24px;
        }

        &:last-of-type {
          margin: 8px 0;
        }
      }

      > button.upper {
        padding: 0;
        margin: 0 !important;
        text-transform: uppercase;
      }
    }

    .file-upload {
      @include flex(center, space-between, row);
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      background-color: lighten($text-regular, 60);

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }

      svg {
        &, path {
          fill: $text-regular;
        }
      }
    }

    .files-upload-content__icon {
      width: 40px;
      height: 32px;

      path {
        fill: url(#svg-gray-gradient);
      }
    }
  }

  .error {
    position: absolute;
    bottom: -25px;
    margin-bottom: 0;
    color: $warnColor;
  }
}

.additive-minds-alert.castor-alert {
  height: 90vh;
  max-height: 910px;
  width: 521px !important;
  margin-top: 0 !important;
  top: 5% !important;

  .castor-alert--body {
    height: calc(88vh - 70px);
    max-height: 825px;
    padding: 24px;
  }

  .modal-footer-cancel-button {
    margin: 0 !important;
  }
  .modal-footer-submit-button {
    margin-left: 20px !important;
  }
}

