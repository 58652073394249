@import '../../../../style/globalStyles.scss';

.location-block {
  @include flexible-column();
  position: relative;

  &.register {
    width: 100%;
    margin: 0 12px 0;

    label {
      color: #b1b1b1;
      left: 43px;
      font-weight: 100;
    }

    .address-input {
      text-align: left;
      margin: 0;

      svg {
        color: #555;
      }

      input {
        padding-left: 18px;
      }

      > div {
        > div {
          padding-left: 18px;
        }

        &:before {
          border-color: #D2D2D2;
        }
      }
    }
  }

  .address-input {
    width: 100%;
    margin: 27px 0 0 0;
    position: relative;
    padding-bottom: 10px;
  }

  .error {
    color: red;
  }

  .loader {
    margin: 0;
  }
}
