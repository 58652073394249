@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/colorsTheme.scss';

.fallback-wrapper {
	background-color: $partBackground;
	display: flex;
	width: 100% !important;
	height: 100%;
	align-items: center;
	justify-content: center;
	svg {
		transform: scale(3);
		opacity: 0.6;
		&,
		path {
			fill: $darkGrey;
		}
		rect {
			opacity: 0.6;
		}
	}
}
