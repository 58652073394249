@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../assets/style/base/designTheme.scss';
@import '../../../../../../style/globalStyles.scss';

.nav-scrollable-container {
  .root {
    margin-top: 10px;
    padding-left: 0;
    margin-bottom: 0;
    overflow: visible !important;
  }

  .solution-tab-buttons {
    margin-left: -25px;
  }

  .tab {
    &-overflow-visible {
      overflow: visible !important;
    }

    &-flex-container {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .arrow {
        cursor: pointer;
        opacity: 0.5;
        height: 15px;

        svg {
          font-size: 15px;
        }
      }
    }

    &-flex-container,
    &-scrollable-container {
      button {
        overflow: visible;
        min-width: 105px !important;
        padding: 0 5px;
        text-align: center;
        font-weight: 400;

        &.small {
          width: 100px;

          @include breakpoint(1680px) {
            width: auto;
          }
        }

        &:hover {
          color: $tab-button-active-text-color;
        }

        &:not([aria-selected="true"]) {
          color: $tab-button-text-color;
        }

        &[aria-selected="true"] {
          color: $tab-button-active-text-color;
        }

        .details-popup-target {
          margin-bottom: 0;
          margin-right: 2px;
        }
      }
    }

    &-root-container {
      margin-bottom: 10px;
      border-bottom: 1px solid #e8e8e8;
      overflow: visible !important;
    }

    &-flex-container {
      & + span {
        background-color: $tab-button-active-text-color;
      }
    }
  }
}
