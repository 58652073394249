@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

$borderColor: #667e8c;
$hoverColor: #e60010;
$textColor: #4b5050;

.ad-blocks {
  display: flex;
  justify-content: center;
  max-width: 1406px;
  width: 98%;
  margin: auto;

  &.full {
    max-width: none;
    margin-top: 24px;
  }

  &__wrapper {
    color: $textColor;
    width: 33.33333%;
    background: $whiteColor;
    margin: 0 1rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column;
    min-height: 415px;

    > div {
      width: 100%;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);

      a {
        color: $whiteColor;
        background-color: $hoverColor;
        border-color: transparent;
        box-shadow: 0 13px 27px -6px rgba(230, 0, 16, 0.6);
      }
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__image {
    width: 100%;
    height: 300px;
    position: relative;
    background-position: center;
    background-size: cover;
  }

  &__content {
    padding: 2rem 2rem 1rem;

    &-subheader,
    &-header {
      color: $textColor;
      font-size: 1.75rem;
      line-height: 2.1rem;
      text-align: left;
    }

    &-subheader {
      line-height: 1.3;
      font-weight: 400;
    }

    &-text {
      text-align: left;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: .875rem;
      line-height: 1.7;
    }
  }

  &__button {
    cursor: pointer;
    width: 100%;
    text-align: right;
    padding: 2rem 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      color: $whiteColor !important;
      background-color: $borderColor;
      border-color: $borderColor;
      box-shadow: 0 13px 27px -6px rgba(102, 126, 140, 0.3);
      position: relative;
      border-radius: 30px;
      padding: 12px 25px 12px 75px;
      display: block;
      margin: 0 0 0 23px;
      font-size: 1rem;
      font-family: Open Sans, sans-serif;
      font-weight: 700;
      line-height: 1.1;
      letter-spacing: 0;
      text-align: center;
      width: fit-content;

      &:hover {
        background-color: $hoverColor;
        border-color: transparent;
        box-shadow: 0 13px 27px -6px rgba(230, 0, 16, 0.6);
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        border-color: $whiteColor;
        border-left: 0.0625rem solid $whiteColor;
        left: 3.5rem;
        top: -0.0625rem;
        bottom: -0.0625rem;
      }

      &:after {
        position: absolute;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        line-height: 1;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='20' viewBox='0 0 12 20' fill='none'%3E%3Cpath d='M1.1499 19.1C0.899902 18.85 0.774902 18.554 0.774902 18.212C0.774902 17.8707 0.899902 17.575 1.1499 17.325L8.4749 10L1.1249 2.65C0.891569 2.41667 0.774902 2.125 0.774902 1.775C0.774902 1.425 0.899902 1.125 1.1499 0.875C1.3999 0.625 1.6959 0.5 2.0379 0.5C2.37924 0.5 2.6749 0.625 2.9249 0.875L11.3249 9.3C11.4249 9.4 11.4959 9.50833 11.5379 9.625C11.5792 9.74167 11.5999 9.86667 11.5999 10C11.5999 10.1333 11.5792 10.2583 11.5379 10.375C11.4959 10.4917 11.4249 10.6 11.3249 10.7L2.8999 19.125C2.66657 19.3583 2.37924 19.475 2.0379 19.475C1.6959 19.475 1.3999 19.35 1.1499 19.1Z' fill='white'/%3E%3C/svg%3E");
        font-weight: 300;
        left: 1.5rem;
        top: 40%;
        transform: translateY(-50%);
        font-size: 2.8125rem;
        vertical-align: middle;
      }
    }
  }

  ul {
    padding: 0 10px 0 0;

    li {
      list-style-type: "- ";
    }
  }
}
