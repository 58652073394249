@import '../../../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../../../style/globalStyles.scss';

.castor-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  position: relative;
  height: 114px;

  &.show-retrieve-result {
    border-right: 1px solid rgba(151, 151, 151, 0.3);
    margin-right: 3%;
    padding: 20px 3% 20px 20px;

    & + .castor-detail {
      width: 100%;
    }
  }

  &.with-frame:not(.show-retrieve-result) {
    border-bottom: 1px solid $button-primary;
    background: #FFFFFF;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin: 0 10px 0 0;
    max-width: 25%;
    width: 100%;
    justify-content: flex-start;
    .no-printable-explanation {
      max-width: calc(100% - 25px);
    }

    @include breakpoint(1680px) {
      padding: 20px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.without-configuration {
      width: 100%;
      @include flex(center, center);

      .castor-detail-body--text {
        @include flex(center, center);

        > span {
          height: auto;

          button {
            &, span {
              font-weight: 500;
            }
          }
        }
      }
    }

    &.with-solution {
      @include flex(flex-start, center);
    }
  }

  .part-detail-title {
    font-weight: normal;
  }

  &.part-detail {
    max-height: 60px;

    &#{&} {
      align-self: flex-start;
    }

    &-body {
      &#{&} {
        margin-bottom: 10px;
      }
    }
  }
}
