.wall-thickness-alert-input {
  margin-top: 20px !important;
  width: 17em;
}

.wall-thickness-alert {
  width: 540px !important;
}

.wall-thickness-alert-body {
  padding: 40px !important;
}
