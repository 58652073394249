@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

$darkTextColor: #323232;

.field-with-label {
  transition: all 0.3s ease-out;

  &:hover {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  }

  &.outlined {
    height: 35px;
    background-color: #ffffff;
    border: 1px solid $darkTextColor !important;
    border-radius: 8px;
    text-align: left;
    font-size: 12px;
    text-transform: capitalize;
    color: $darkTextColor;
    padding: 0;
    margin: 0;
    transition: all 0.3s ease-out;

    @include breakpoint(1860px) {
      height: 40px;
      font-size: 14px;
    }

    &,
    div,
    input {
      width: 100%;
    }

    &:before,
    &:after {
      display: none;
    }

    div {
      border: 0;
      font-size: 14px;
      top: -2px;

      @include breakpoint(1860px) {
        font-size: 16px;
      }

      &:before,
      &:after {
        display: none;
      }
    }

    &.short {
      width: 140px;
      &__disabled_box {
        width: 140px;
        background-color: $disabled-background-color;
      }
    }

    &.without-label {
      padding: 4px 10px;
      margin-top: 12px;
      width: 204px;

      div {
        width: calc(100% - 20px);
        top: -2px;

        svg {
          top: 2px;
          right: -20px;
        }
      }
    }
  }

  &__block {
    .currency {
      margin-left: 8px;
      font-size: 14px;
      font-weight: 400;
      top: 9px;
      position: relative;
    }
  }
}

.info-box{
  width: 265px;
  position: absolute;
  background-color: $tooltip-background-color;
  height: 130px;
    &__info_icon {
      margin-left: 7px;
      margin-top: -5px;
      fill: #323232
    } 
}
