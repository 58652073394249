@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../assets/style/base/designTheme.scss';
@import '../../../../../../style/globalStyles.scss';

.calculate-button {
  width: 149px;
  margin: 10px 0 !important;
}

.configure-buttons {
  color: $text-regular-opac !important;

  &:hover {
    color: $button-primary !important;

    .solution-analysis-header-text-icon {
      fill: $button-primary !important;
      stroke: $button-primary !important;
    }
  }
}

.material-configuration {
  overflow-y: auto;

  @include breakpoint(xl) {
    overflow: scroll;
  }
}

.middle-button {
  margin: 0 1em !important;
}

.solution_configure_prioritize {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__field-wrapper {
    width: 100%;
  }

  &__field_title {
    font-size: $configuration-property-text;
    text-transform: capitalize;
    color: $text-regular-opac;
    text-align: left;
  }

  &__romeve_button {
    margin: 0 5px 0 0 !important;
    align-self: flex-start !important;
    padding: 0 !important;
  }

  &__remove-icon {
    color: $text-regular-opac;
    width: 20px !important;
    height: 20px !important;
  }
}

.material-selector-configure-chosen-material-text {
  margin: 0;
  text-align: left;
  width: 100%;
}

.materials-selector-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.materials-title {
  font-size: 18px !important;
  font-weight: bold !important;
  color: $text-regular !important;
  margin-bottom: 5px;
}

.post-processes-button {
  color: $button-primary;
  display: flex;
  align-items: center;
}

.icon-info {
  margin-left: 3px;

  &-long-text {
    margin-left: 130px;
  }

  &-short-text {
    margin-left: 93px;
  }
}

.tray-orientation-button {
  color: '#fffff' !important;
  width: 5px !important;
  background-color: none !important;
  color: $button-primary !important;
  font-size: 14px !important;
  transition: all 0.2s ease-in-out !important;
  text-transform: capitalize !important;
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
}

.simpleConfiguration {
  &--toggle {
    color: $text-regular-opac;
  }

  &-printers-headers {
    font-size: 18px !important;
    color: $text-regular !important;
    margin-bottom: 15px;
  }

  &-content {
    > div:nth-of-type(2) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.single-filter-row {
  svg {
    margin-right: 4px;
  }
}