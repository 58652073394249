@import '../../../assets/style/base/colorsTheme.scss';

.expand-button {
  background-color: $button-primary;
  width: 140px;
  height: 40px;
  border-radius: 27px;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  cursor: pointer;

  &--content {
    justify-content: center;
    width: 100%;

    &--icon {
      height: 16px;
      width: auto;
    }

    &--text {
      color: $text-white;
      font-size: 17px;
      margin-left: 9px;
      text-transform: capitalize;
    }
  }
}
