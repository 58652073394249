@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../style/globalStyles.scss';

.customize-solution-filter {
	padding-right: 0;
	padding-top: 30px;
	max-height: 600px;
	overflow: auto;

	&-fields {
		&-title-devider {
			margin: 15px 0;
		}

		&-content {
			width: 95%;
		}

		&-title {
			font-style: italic;
			color: #222222;
		}
	}

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(168, 168, 168, 0.7);
		border-radius: 10px;
	}

	&-alert {
		&.castor-alert {
			z-index: 5000 !important;
		}
	}

	&-inhouse-alert {
		&.castor-alert {
			z-index: 5500 !important;
			top: 60% !important;
		}
	}

	&-button {
		&.border-button {
			margin: 15px 15px 0 0;
		}
	}

	&-secondary {
		color: $text-regular-opac !important;
	}

	&__content {
		.filters-button {
			@include flex(center, flex-end, row);
		}
	}

	&__threads {
		border-top: 1px solid $tab-button-border-color;
		padding-top: 15px;
		margin-top: 15px;
		border: none;
		border-radius: 10px;
		background-color: rgba(216, 216, 216, 0.37);
		padding: 15px;
		&--wrapper {
			h3,
			p {
				margin: 12px 0 !important;
			}
		}
	}

	&__thread {
		margin-bottom: 16px;

		p {
			font-size: 15px;
			margin: 0;
		}

		&-block {
			@include flex(center, space-between, row);

			& > div {
				@include flex();

				span {
					font-size: 12px;
				}
			}

			.details-popup-target {
				margin: 0 10px;
				cursor: pointer;
			}

			.title {
				margin-right: 10px;
			}
		}
	}

	.solution-feature-modal {
		&-filter {
			width: 85vw;
			max-width: 850px;
			height: 356px;
			padding: 20px 0;

			.filter-field {
				max-width: 330px;
				label {
					max-width: 200px;
				}
			}
		}
	}
}
