@import '../../../../../style/globalStyles.scss';

.material-production {
	&--wrapper {
		@include flex(flex-start, flex-start, column);
		text-align: left !important;
		width: 65%;
		@media screen and (max-width: 1600px) {
			width: 75%;
		}
		@media screen and (max-width: 1400px) {
			width: 85%;
		}
		@media screen and (max-width: 1250px) {
			min-width: 100%;
		}
		&.product-use {
			padding-right: 10px;
		}
		.custom-calculation-co2--explanation {
			padding-left: 10px;
		}
		.custom-calculation-co2--container {
			width: 100%;
			display: grid !important;
			justify-content: start;
			grid-template-columns: auto 85px minmax(70px, 1fr);
			margin-left: 0 !important;
			padding: 0 10px;
			&.printer-material-emission {
				grid-template-columns: repeat(3, auto) minmax(70px, 1fr);
				.custom-calculation-co2--twh-picker {
					width: auto !important;
				}
			}
		}
		.custom-calculation-co2--carbon-wrapper {
			width: 100% !important;
			display: grid !important;
			justify-content: start;
			grid-template-columns: repeat(4, auto) minmax(70px, 1fr);
			margin-left: 0 !important;
			padding: 0 10px;
			.custom-calculation-co2--explanation {
				padding-left: 0px;
			}
			.custom-calculation-co2--carbon-picker {
				width: auto !important;
			}
		}

		.custom-calculation-co2--material-picker,
		.custom-calculation-co2--printer-picker {
			width: auto !important;
		}
		.customize__table {
			width: 100%;
			> div {
				padding-right: 0 !important;
			}
		}
		.custom-calculation-co2--prime-title {
			padding: 0 10px;
		}
		button {
			right: 0 !important;
			left: 0 !important;
			align-self: flex-end;
			justify-self: flex-end;
		}
	}
	&--header {
		@include font-builder(16px, 500);
		padding-left: 10px;
	}
}
.custom-calculation-co2--twh-picker {
	> div {
		width: calc(100% + 5px) !important;
	}
}
