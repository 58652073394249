@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.slider-block {
  margin: 10px 0;

  .axis-button {
    padding: 5px 10px;
    flex: 1;
    background-color: transparent;
    color: $lightBlue;
    border: 1px solid $lightBlue;

    &.active {
      background-color: darken($lightBlue, 5);
      color: white;
      border: 1px solid darken($lightBlue, 5);
    }

    &:hover {
      color: white;
      background-color: darken($lightBlue, 10);
      border: 1px solid darken($lightBlue, 10);
    }
  }

  &__axis-buttons {
    @include flex(center, space-between);

    > div:first-child {
      @include flex(center, space-between);
      width: 100%;
      margin-right: 20px;
    }

    svg {
      fill: darken($lightBlue, 10);

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__slider {
    @include flex(center, center);

    > span {
      margin-right: 10px;
    }

    input {
      margin-left: 10px;
      width: 50px;
    }

    .clip {
      &-rail {
        background-color: $lightBlue;
      }

      &-track {
        background-color: darken($lightBlue, 10);
      }

      &-thumb {
        color: darken($lightBlue, 10);
        width: 12px;
        height: 16px;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        top: 12px;

        &:hover {
          box-shadow: 0 0 0 5px $select-box-selected-background;
        }
      }
    }
  }

  .clipping-button {
    width: 100%;

    &:hover {
      background-color: darken($lightBlue, 5);
    }

    svg {
      width: 20px;
      margin-right: 10px;
      fill: $button-info-font-color;
    }
  }

}