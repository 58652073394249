@import '../../../style/globalStyles.scss';

.user-menu {
	&--popper {
		left: -10px !important;
		z-index: 1;
	}

	&--popper .new-feature-tag {
		margin-left: 0.8em;
	}

	&__name {
		@media only screen and (max-width: 900px) {
			display: none;
		}
	}
}

.header-links {
	@media only screen and (max-width: 900px) {
		position: relative;
		top: 40px;
		left: 5px;
	}

	.version-viewer {
		@media only screen and (max-width: 900px) {
			margin-right: 5px;
		}
	}

	button {
		@media only screen and (max-width: 900px) {
			padding: 0 !important;
			margin: 0 !important;
			justify-content: center;
		}
	}
}

.close-sidebar {
	display: none;

	@media only screen and (max-width: 900px) {
		display: block;
		width: 30px;
		font-size: 30px;
		position: absolute;
		right: 10px;
		top: 25px;
	}
}
.user-menu-item {
	&:hover {
		.custom-icon {
			path {
				fill: #ffffff;
			}
		}
	}
}
.disabled-link {
	pointer-events: none;
}
