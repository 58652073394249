.admin-users-icons {
  display: flex;
}

.admin-users-icon {
  margin: 0 0.5em 0 0;
}

.admin-user-multiple-line-field {
  display: flex;
  flex-direction: column;
}

.admin-user-multiple-line-field-bold {
  font-weight: bold;
}

.admin-user-multiple-line-field .link-button {
  margin: 1px 0;
  width: 14em;
  justify-content: space-evenly;
}

.menu-items-target {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  color: unset;
  /* padding: 1.7em 10px 10px; */
  line-height: 1.5em;
  border-radius: 3px;
  font-size: 1.2em;
}

.menu-button-div {
  display: flex;
  align-items: center;
}

.menu-button-item {
  background-color: transparent !important;
  color: unset !important;
}

.info-button {
  fill: white;
}

.admin-data-table-export-data {
  .export-button {
    min-width: 110px;
  }
}