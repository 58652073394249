@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';
@import './variables';

.new-upload-project {
  .card-header {
    span {
      font-weight: 600 !important; //override nested styles
      font-size: 16px !important; //override nested styles
      line-height: 19px !important; //override nested styles
      margin: 0 !important; //override nested styles
      width: max-content;
    }
  }

  &__card {
    padding: 0 !important; //override nested styles
  }

  .label-field {
    font-weight: 500;
    margin-top: 5px;
  }

  &__content {
    padding: 10px 16px 16px;

    @include breakpoint(custom, 1960px, 1080px) {
      padding: 24px;
    }

    .label {
      @include font-builder($smallFont, $largeFontWeight, $basicFontHeight);
      margin-bottom: 8px;

      @include breakpoint(custom, 1960px, 1080px) {
        @include font-builder($basicFont, $basicFontWeight, $basicFontHeight);
      }

      & + span {
        margin-bottom: 8px;
      }
    }

    input {
      padding: 12px !important;
      border-radius: 8px;
    }

    div {
      @include font-builder($smallFont, $basicFontWeight, $basicFontHeight);

      @include breakpoint(custom, 1960px, 1080px) {
        @include font-builder($basicFont, $basicFontWeight, $basicFontHeight);
      }
    }

    .material-selctors-div {
      &__buttons {
        margin-top: 5px;

        @include breakpoint(custom, 1960px, 1080px) {
          margin-top: 16px;
        }

        .outlined {
          max-width: 300px;
        }
      }
    }

    .early-error {
      position: absolute;
      bottom: 0;
    }
  }

  &__block {
    text-align: left;
    padding: 5px 0 0;

    &.box-files {
      padding-top: 30px;

      &.multiple-params {
        > div {
          margin-top: 0 !important;
        }
      }

      > div {
        width: 600px;
        margin: 0;
        min-height: 100%;
        padding-bottom: 10px;
      }
    }

    &.box-type {
      height: 100%;
      padding-top: 20px;

      > div {
        margin: 0 0 -1.5px;
        height: 100%;

        @include breakpoint(custom, 1680px, 1080px) {
          min-height: 520px;
        }

        @include breakpoint(custom, 1960px, 1080px) {
          min-height: 565px;
        }
      }

      .download-metadata {
        @include flex(center, flex-start);
        text-transform: uppercase;
        margin-top: 20px;
        @include font-builder($basicFont, $mediumFontWeight, $basicFontHeight);

        svg {
          margin-right: 6px;
        }
      }
    }

    &.quick-upload {
      width: 100%;
      height: 100%;
    }
  }

  .parent {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1406px;
    min-height: fit-content;
    max-height: $upload-max-page-height;
    padding-bottom: 5px;

    @include breakpoint(900px) {
      flex-direction: row;
    }

    &.quick-upload-parent {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      padding: 0 10px 5px 16px;
      background: bottom right no-repeat url('../../../assets/img/svg/danfoss/QuickCADUpload.svg');
      overflow-x: hidden;
    }
  }

  .box-config {
    padding: 5px 0 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: auto;
    min-width: 760px;
    align-items: stretch;
    margin: 0 20px 0 10px;

    > div {
      text-align: left;
      padding: 5px 0 0;
      height: $box-config-height;

      &:first-of-type {
        height: fit-content;
      }

      &:last-of-type {
        margin-bottom: 0 !important;
        height: -webkit-fill-available;
      }
    }
  }

  .upload-form {
    &-tolerance-info-icon {
      fill: $darkTextColor;
    }

    &-scenario-info-icon {
      fill: $darkTextColor;
      margin-left: -10px;
    }

    &__types,
    &__scenario {
      padding: 0;
      color: $darkTextColor;

      .tolerance-check-box__text {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        color: $lightBlue;
      }

      &-type {
        &:not(:last-of-type) {
          margin-right: 20px;
        }

        &:first-of-type {
          .select-box {
            img {
              padding: 0;
            }
          }
        }

        &:nth-of-type(3) {
          .select-box {
            img {
              padding: 12px;
            }
          }
        }

        p {
          text-align: center;
          margin-top: 2px;
          margin-bottom: 2px;

          @include breakpoint(custom, 1960px, 1080px) {
            margin-top: 8px;
            margin-bottom: 10px;
          }

          &.selected {
            color: $lightBlue;
          }
        }
      }

      .select-box {
        margin: 0 10px;
        height: 40px;
        font-weight: 400;
        flex: 1 1 auto;
        min-width: 135px;

        @include breakpoint(1820px) {
          width: auto;
          flex: 1 1 auto;
          height: 55px;
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &__text {
          height: 55px;
          width: 130px;
          padding: 0;

          @include breakpoint(custom, 1960px, 1080px) {
            width: 140px;
            height: 70px;
          }

          &.selected {
            img {
              mix-blend-mode: color-burn;
              filter: brightness(0.2);
            }
          }

          img {
            padding: 8px;
            width: auto;
            height: 100%;
          }
        }
      }
    }

    &__scenario {
      margin: 12px 0;

      .tolerance-check-box {
        width: 100%;

        .upload-form__scenario-block {
          > div {
            width: 100%;
          }
        }
      }

      @include breakpoint(custom, 1960px, 1080px) {
        margin: 0 0 24px;
      }

      &-block {
        width: 100%;
        gap: 10px;

        &.am-type {
          display: grid;
          grid-template-rows: 1fr 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 20px;
          grid-row-gap: 5px;

          &-checkbox {
            @include flex();

            span {
              height: 20px;
            }
          }
        }

        .am-type {
          &-checkbox {
            @include flex(flex-start, flex-start);

            span {
              height: 20px;
              width: 20px;
              margin-right: 5px;
            }
          }
        }
      }
    }

    &__types {
      margin-bottom: 0;

      @include breakpoint(custom, 1960px, 1080px) {
        margin-bottom: 10px;
      }
    }
  }

  &__info-screen {
    height: 100vh;

    > div {
      height: 300px;
    }
  }
}

.main-content-scroller {
  &.quick-cad-upload {
    overflow: auto !important;
    height: calc(100vh - #{$space-for-banner});
  }
}

.with-contact-banner {
  margin-top: 64px;
}
