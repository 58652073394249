@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.tour {
  &-tooltip {
    background-image: $takeATourGradient;
    padding: 2em;
    border-radius: 10px;
    width: 400px;
    position: relative;

    &--no-gradient {
      background-image: none;
      background-color: $takeATourBgColor;
    }

    &--close-btn {
      width: 30px;
      height: 30px;
      font-size: 24px;
      position: absolute;
      top: 7px;
      right: 7px;
      cursor: pointer;
      @include flex();
    }

    &--button {
      padding: 0.5em 1em;
      color: $button-primary;
      border-radius: 50px;
      border: none;
      background-color: #fff;
      cursor: pointer;
      box-shadow: 0 2px 3px 0 $cardShadow;
      transition: all 0.5s;

      &:hover {
        box-shadow: 0 2px 4px 1px $cardShadowHover;
      }
    }

    &--title {
      &-welcome {
        font-size: 2em;
      }
    }

    &--footer {
      margin-top: 1em;
      height: 1.5em;
      position: relative;

      &--back {
        position: absolute;
        left: 0;
      }

      &--next {
        position: absolute;
        right: 0;
      }
    }
  }

  &-beacon {
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    color: rgb(85, 85, 85);
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
    padding: 8px;
    appearance: none;
    display: inline-block;
    height: 36px;
    position: relative;
    width: 36px;
    z-index: 1050;
  }
}
