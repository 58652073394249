.image-and-description-images {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #dfe5f3;
}

.star-benefit-block {
  margin: 1em 1em 0;
}
