@import '../../../../../../style/globalStyles.scss';
@import '../../../../../../assets/style/base/colorsTheme.scss';

.part-tabpanel {
	&--block {
		@include flex(center, space-between, null, wrap);
		margin: 0 0 20px;
	}

	&--buttons {
		height: 26px;
		overflow: visible;

		.part-analysis-button-text-transform {
			position: relative;
		}

		.solution-analysis-header-buttons-wrapper {
			align-items: center;
			button,
			.part-analysis-button-text-transform {
				margin-left: 28px !important;
				line-height: 20px !important;

				.solution-analysis-header-text {
					margin-left: 0 !important;
				}
			}

			.solution-analysis-header-text {
				&,
				.solution-analysis-header-text-text,
				> span {
					padding: 0 !important;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
				}
			}

			.drop-down-list-menu {
				&.show {
					div {
						@include flex(center, center, column);
					}
				}
			}

			.part-analysis-button-text-transform,
			.solution-analysis-header-text {
				&.disabled {
					&,
					.solution-analysis-header-text-text,
					> span {
						@include flex();
						color: $darkGrey !important;

						path {
							fill: $darkGrey !important;
							stroke: $darkGrey !important;

							&#combine {
								stroke: $darkGrey;
							}
						}
					}
					button {
						span {
							color: $darkGrey !important;
						}
					}
					&.starred {
						path {
							fill: $button-primary !important;
							stroke: $button-primary !important;
						}
						.solution-analysis-header-text-text {
							color: $button-primary !important;
						}
					}
				}
			}

			.solution-analysis-header-text,
			button {
				svg {
					width: 14px;
					height: 14px;
					top: -1px;
					position: relative;
					margin-right: 4px;
				}

				.pdf-icon {
					svg {
						top: 1px;
					}
				}

				svg {
					&,
					path {
						width: 14px;
						fill: $lightBlue;

						&#combine {
							stroke: $lightBlue;
						}
					}
				}

				&:not(.disabled) {
					.solution-analysis-header-text-text {
						&.send-to-text {
							color: $lightBlue;
						}
					}

					&.starred {
						&.saved {
							color: $button-primary;
							svg {
								&,
								path {
									fill: $button-primary;
									stroke: $button-primary;

									&#combine {
										stroke: $lightBlue;
									}
								}
							}

							&:hover {
								color: $lightBlue;
								svg {
									&,
									path {
										fill: $lightBlue;
										stroke: $lightBlue;
										transition: all 0.1s ease-in-out;
									}
								}
							}
						}

						svg {
							&,
							path {
								fill: #ffffff;
								stroke: $lightBlue;
							}
						}

						&:hover {
							svg {
								&,
								path {
									fill: #ffffff;
									stroke: $button-hover-icon;
								}
							}
						}
					}

					&:hover {
						cursor: pointer;

						.solution-analysis-header-text-text {
							&.send-to-text {
								color: $button-hover-icon;
							}
						}

						svg {
							&.solution-analysis-header-text-icon {
								fill: $button-hover-icon;
							}

							&,
							path {
								fill: $button-hover-icon;

								&#combine {
									stroke: $button-hover-icon;
								}
							}
						}
					}

					.solution-analysis-header-text-text {
						@include flex();
					}
				}
			}

			.drop-down-list-menu {
				padding: 12px 0 !important;
				right: 0;

				&--item {
					margin: 0 !important;
					max-height: 40px;
					border: 1;

					> a,
					> button,
					> div {
						height: 40px;
						min-width: 190px;
						width: fit-content;
						display: block;
					}

					> a {
						line-height: 40px;
					}

					&:hover {
						cursor: pointer;
						background: rgba(32, 192, 230, 0.1);
					}

					&:not(:first-child) {
						border: 0;
					}

					div,
					button {
						color: $blackColor !important;
						margin: 0 !important;

						&:not(.disabled) {
							&:hover {
								color: $blackColor !important;
							}

							svg,
							path {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	&--benefits {
		z-index: 0;
		height: 26px;

		.benefit-icon-wrapper {
			height: 26px;
			width: auto;
			border: 1px solid $benefitIconColor;
			border-radius: 50px;

			> svg {
				width: auto !important;
				height: 26px;
			}
		}

		.text {
			border-color: $benefitIconColor;
			color: $benefitIconColor;
		}

		.icons--benefits--stroke {
			stroke: $benefitIconColor;
		}

		.text,
		.icons--benefits--text {
			fill: $benefitIconColor;
		}

		.icon-buy-to-fly {
			background: $benefitIconBackgroundColor;
			height: 26px;

			> svg {
				width: auto !important;
				height: 20px;
			}
		}

		.icons--benefits--fill {
			fill: $benefitIconBackgroundColor;
		}
	}
}
