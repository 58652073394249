@import '../../../assets/style/base/colorsTheme.scss';

.select-with-icons {
  &--formControl {
    &#{&} {
      margin: 2px;
    }
  }
  &--select {
    &--root {
      &#{&} {
        &#{&} {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          min-height: unset;
          z-index: 1;
        }
      }
    }
    &--icon {
      position: absolute;
      right: 0;
      margin-top: 1px;
    }
  }

  &--icon {
    &#{&} {
      min-width: unset;
    }
    &--svg {
      width: 20px;
      height: auto;
    }
  }

  &--item {
    &--text {
      &#{&} {
        font-size: 13px;
        margin-left: 5px;
      }
    }
  }
}
