
.title{
    display:flex
}


.form-header {
  align-items: center;
  justify-content: space-between;
  height: 74.5px;

  &--type-icon {
    width: 20px ;
    height: 20px;
    margin-right: 10px;
  }

}


