.material-selector--hide-picker {
  display: flex;
  margin-bottom: 5px;

  &--text {
    max-width: 9em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 1.2em;
    color: #848484;
    text-align: left;
  }

  &--button {
    font-size: 0.7rem;
  }
}
