@import '../../../../../style/globalStyles.scss';
@import '../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../assets/style/base/designTheme.scss';

.solution-filtering {
	&--wrapper {
		padding: 0 10px;
		display: grid;
		grid-template-rows: auto;
		gap: 20px;

		.advanced-filter__bordered {
			height: 100%;
			max-height: 100%;
		}

		.customize-solution-filter__threads {
			height: inherit;
			&--wrapper {
				height: 100%;
				max-height: calc(100% - 45px);
			}
		}
	}
	&--ga-preferences {
		&--title {
			@include font-builder(17px, 600);
			text-align: left;
		}
	}
}
