@import '../../../assets/style/base/colorsTheme.scss';

.drop-down-list-menu {
  position: absolute;
  background-color: white;
  margin-top: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 5px 10px;
  width: auto;

  *, & {
    visibility: hidden;
  }

  * {
    transition: 0.2s;
  }

  *, &, svg {
    color: $text-regular;
    fill: $text-regular;
    font-weight: 400;
  }

  &--item {
    min-height: 40px;
    padding: 10px 5px;
    text-align: center;

    .solution-analysis-header-text.enabled.with-border {
      border: none !important;
    }

    &:hover {
      *, &, svg {
       // color: var(--solution-color);
      //  fill: var(--solution-color);
      }

      .solution-analysis-header-text {
        &.enabled {
          &.with-border {
            border: none !important;
            box-shadow: none !important;
          }
        }
        &.disabled {
          pointer-events: none;
        }
      }
    }
  }

  &.show {
    &, * {
      visibility: visible;
    }
  }

  &--item:not(:first-child) {
    border-top: 0.5px solid gray;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
}
