.customize {
  .small-letter {
    font-size: 9px;
    bottom: -2px;
    position: relative;
  }

  h3 {
    .small-letter {
      font-size: 18px;
    }
  }

  &__table {
    margin-bottom: 20px !important;

    tr {
      position: relative;
    }

    td {
      overflow: visible;
      padding-bottom: 20px;
    }
  }

  &__input {
    &-field {
      min-width: 50px !important;
    }

    &-loading,
    &-error {
      position: absolute;
      color: #f44335;
      width: fit-content;
      right: 0;
      bottom: -10px;

      &.left {
        left: 40px;
        bottom: 0;
      }

      &.center {
        width: 100%;
        text-align: center;
      }
    }

    &-loading {
      color: #000000;
      opacity: 0.7;
    }
  }

  &__field {
    position: relative;

    &.with-tooltip {
      cursor: pointer;
    }

    .tooltip {
      display: none;
      position: absolute;
      min-width: auto !important;
      padding: 8px;
      top: -38px;
      width: auto !important;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  &__default-field {
    position: relative;
    cursor: default;

    span {
      opacity: 0.7;
    }
  }
}
