
.admin-version-status-field {
  .circle-green,
  .circle-orange,
  .circle-red {
    height: 25px;
      width: 25px;
      border-radius: 50%;
      display: inline-block;
  }
 
  .circle-green {
    background-color: green;
  }
  .circle-orange {
    background-color: orange;
  }
  .circle-red {
    background-color: red;
  }
 }
