@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

div.upload-form-field {
  width: 100%;
  margin: 1em 0;

  &.upload-form-field--with-explenation {
    margin-top: 0;
  }
}

.bom-file-upload {
  .hide-input {
    display: none;
  }

  &__text {
    font-size: 12px;
    margin: 0 20px 0 5px;
    width: 125px;
    white-space: nowrap;
    @include text-ellipsis();
  }

  // emulation simple input html
  &__button {
    appearance: auto;
    white-space: nowrap;
    margin-inline-end: 4px;
    font-size: inherit;
    align-items: flex-start;
    text-align: center;
    cursor: default;
    box-sizing: border-box;
    background-color: rgb(239, 239, 239);
    color: black;
    margin: 0;
    padding: 1px 6px;
    border: 1px rgb(118, 118, 118) solid;
    border-radius: 2px;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    display: inline-block;

    &:hover {
      cursor: pointer;
      background-color: rgb(233, 232, 232);
    }
  }
}


.upload-project--unit-type-title {
  color: #848484;
  white-space: pre-wrap;
  margin-top: 10px;
}

.upload-form-field-application {
  margin-bottom: 1em;
}

.tolerance-check {
  position: relative;

  &-box {
    .tolerance-class-menu {
      margin: 0 10px;
    }

    &-icon {
      padding: 0 !important;
      margin: 0 !important;
      position: absolute;
      right: -5px;
      width: 30px;
      height: 30px;
    }

    &__text {
      margin-left: -5px;
      white-space: nowrap;
    }
  }
}

.upload-form-tolerance-info-icon {
  fill: #848484;
}

div[role='presentation'].upload-form-tolerance-info-alert-root {
  z-index: 5501 !important; /*override other popups*/
}

.upload-form-tolerance-info-alert-footer {
  margin-top: -55px !important;
}

.upload-form-tolerance-info-alert-dots {
  padding-top: 0 !important;
}

.upload-form-tolerance-info-alert-dots .castor-MuiPaper-rounded {
  background: black;
}

.upload-form-tolerance-info-alert-title {
  background-color: transparent !important;
  position: absolute !important;
  top: -530px;
  left: 20px;
  text-align: left;
  text-transform: none !important;
  width: 80% !important;
}

.upload-form-tolerance-info-alert-title-label {
  color: black;
  font-size: 15px;
  line-height: 1.5;
  white-space: pre-line;
}

.upload-form-tolerance-info-alert-slide {
  overflow: hidden !important;
}

.upload-project-filters {
  padding-right: 0;
  padding-top: 30px;
  max-height: 500px;
  overflow: auto;
}

.upload-project-material-selector-field {
  max-width: 95px;
}

.upload-project-filters-fields-title-devider {
  margin: 15px 0;
}

.upload-project-filters::-webkit-scrollbar {
  width: 7px;
}

.upload-project-filters-fields-content {
  width: 95%;
}

.upload-project-filters::-webkit-scrollbar-thumb {
  background-color: rgba(168, 168, 168, 0.7);
  border-radius: 10px;
}

.upload-project-filters-fields-title {
  font-style: italic;
  color: #222222;
}

.upload-project-filters-alert.castor-alert {
  z-index: 5000 !important;
}

.upload-project-filters-inhouse-alert.castor-alert {
  z-index: 5500 !important;
  top: 60% !important;
}

.upload-project-filters-button.border-button {
  margin: 15px 15px 0 0;
}

.upload-form-learn-how-button {
  margin-left: 15px;
}

.upload-project-materials-title {
  color: #848484;
  margin-bottom: 15px;
  font-size: 16px;
}

label.upload-form-radioGroup {
  align-items: flex-start;
}

span.upload-form-radio {
  align-items: flex-start;
  padding: 5px;
}

.upload-form-radio-bom {
  padding-top: 7px;
}

.upload-form-uploaded--progress-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 2.2em;
  font-weight: 100;
  color: #555;
  user-select: none;
}

.upload-form-uploaded--files-indicator {
  position: absolute;
  bottom: 61px;
  width: 100%;
  text-align: center;
}

.upload-form-standard-cost {
  &-text {
    fill: $text-regular-opac;
    font-size: 12px;
    line-height: 1em;
    margin: 5px 0 0 3px;
  }

  &-flex {
    margin-left: 57px;
  }

  &-box {
    margin-top: 5px;
  }
}