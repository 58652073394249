@import '../../../style/globalStyles.scss';

.error-list {
  @include flex(null, space-evenly);
  position: absolute;
  width: 100%;
  bottom: 60px;
}

.content {
  height: 100%;
  > div {
    height: 100%;

    x3d {
      height: 100%;
    }
  }
}
