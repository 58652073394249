@import '../../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../../assets/style/base/designTheme.scss';
@import '../../../../../../../style/globalStyles.scss';

$tabContent: 330px;

.solution-tab {
	&-content {
		height: $tabContent;
		padding-left: 4px;
		overflow-x: hidden !important;

		&::-webkit-scrollbar {
			width: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: darkgrey;
			border-radius: 10px;
		}
	}

	&--cost-title {
		@include flex(center, center, row);
		text-transform: initial;
		font-size: $text-field-header-font-size;
		gap: 15px;

		.block-with-the-benefit {
			@include flex();
			justify-content: flex-start;
			margin-bottom: 0;

			@include breakpoint(1680px) {
				max-width: 100%;
			}

			.solution-tab--new-tag {
				top: -10px;
				margin-right: 30px;
			}

			.details-popup-target,
			.solution-tab--new-tag {
				position: sticky;
			}

			.new-feature-tag {
				width: 45px;
				font-size: 11px;
			}
		}
		&.lead-title {
			@include flex(center, center, row);
			width: 149px;
			gap: 0;
		}
	}

	&--disabled-tag {
		width: 149px;
		margin-top: 10px !important;
	}

	&--new-tag {
		position: absolute;
		right: 0;
		top: -18px;
		font-size: 13px;
	}

	&--material-table {
		grid-row-gap: 10px !important;
		column-gap: 0 !important;
		width: 99.9%;

		&--show-all {
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}

	&-cluster {
		width: 100%;

		&-part-tile {
			height: auto !important;

			&--card {
				&#{&} {
					margin: 0;
					font-size: 1em;
				}
				&--image-wrapper {
					&#{&} {
						height: 200px;
					}
				}

				&--content {
					&#{&} {
						padding: 0 20px;
					}
				}
			}

			&--element {
				padding: 0.3em;
			}
		}

		&-part-comp {
			height: 344px;
		}
	}

	&-buttons {
		margin-top: 0 !important;

		&-button {
			&#{&} {
				background-color: transparent;
				text-transform: none;
				box-shadow: unset;
				color: $tab-button-text-color;
				border-bottom: 1px solid $tab-button-border-color;
				border-radius: 0;
				overflow: unset;
			}

			&-label {
				&#{&} {
					font-size: $text-field-header-font-size;
					align-self: flex-start;
					position: unset;
				}
			}

			&:hover {
				&#{&} {
					color: $tab-button-active-text-color;
					background-color: transparent;
					box-shadow: unset;
				}
			}

			&-selected {
				position: relative;
				color: $tab-button-active-text-color !important;

				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					border-bottom: 2px solid $tab-button-active-text-color;
				}
			}
		}
	}

	&-benefit {
		display: flex;
		align-items: center;

		&-popper-message {
			&.auto-width {
				.details-popup-data {
					width: auto;
					max-width: 150px;
				}
			}

			.details-popup-data {
				width: auto;
				max-width: 200px;
			}
		}

		&-icon {
			height: 19px;
			width: 19px;
			z-index: 2;
			border: 1px solid $benefitIconColor;
			border-radius: 100%;

			.icons--benefits--fill {
				fill: $benefitIconBackgroundColor;
			}

			path,
			.icons--benefits--text--stroke {
				fill: $benefitIconColor;
				stroke: $benefitIconColor;
			}
		}
	}
}
.popper-message-wrapper {
	width: auto;
	z-index: 99999999;
}
.benefit-dollar-icon {
	left: 253px;
	position: absolute;
	bottom: 25px;
	height: 30px;
	z-index: 99999999;
}
.cost-summary {
	&--quantity {
		text-align: start;
		margin-top: 24px;
		// font-weight: bold;
		color: $text-regular-opac;
	}
	&--table {
		&--text-with-icon {
			width: $table-column-with-text-width;
			color: inherit;
			justify-content: space-between;

			&--icon {
				margin: 0;
				width: 20px;
				height: 20px;
				min-width: unset;
				min-height: unset;

				&--left {
					margin: 0 10px 0 0;
				}
			}
		}
	}
}

.solution-tab-buttons-button-label {
	color: $solutionTabButton;
}

.lead-time-message-wrapper {
	width: 370px;
    max-width: 500px;
    border-radius: 8px;
    background-color: #f5f5f5
}