
@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../assets/style/base/colorsTheme.scss';

.admin-feature-toggle {
    height: 100%;
    width: 100%;
    display: grid;
    justify-items: start;
    overflow: auto;
    grid-template-columns: 33.334% 33.334% 33.334%;	
    padding-top:20px;

    &-back-button {
      top: 20px;
      margin-left: 30px !important;
      right: 20px;
    }

    &-helper-text{
      padding-left: 12px;
    padding-top: 20px;
    }

    &-label {
        text-align: left;
      }

      &-new-configuration-text {
        min-width: 250px !important;
        padding-right: 20px;
        margin-left: 15px;
      }

    &-checkbox {
        margin-left: 15px !important;
      }

      &-title {
        font-size: 18px;
        color: $text-regular;
        text-align: left;
      }
      
      &--popper {
        background-color: $long-text-read-more-background !important;
        text-align: left;

        &--data {
          font-size: 14px !important;
          line-height: 1.44 !important;
          color: $text-regular-opac !important;
        }
      }
    }