@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/colorsTheme.scss';

.quick-cad-project-results {
	&--header {
		width: 100%;
		height: 188px;
		background: $quick-upload-feedback-background;
		position: relative;
		padding: 40px;
		color: $blackColor;
		&--img {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 1;
		}
		&--feedback-text {
			@include flex(flex-start, flex-start, column);
			gap: 12px;
			position: inherit;
			z-index: 2;
			h2 {
				@include font-builder(32px, 600);
				margin: 0;
			}
			p {
				@include font-builder(20px, 400);
				margin: 0;
			}
		}
	}
	&--card-result-wrapper {
		padding: 0 16px;
		.quick-cad-project-results--card-result {
			padding: 0 !important;
		}
	}
	&--card-result {
		> ul {
			margin: 0 !important;
			padding: 8px !important;
		}
		.parts-header--title {
			text-align: left;
		}
	}
}
