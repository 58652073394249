@import '../../../../../../../../../style/globalStyles.scss';
@import '../../../../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../../../../assets/style/base/designTheme.scss';

.financial-form {
  &-buttons {
    align-self: flex-end;
    position: fixed;
    bottom: -15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: fit-content;
    height: 66px;
    align-items: center;
  }
  &-yAxisLabel {
    transform: rotate(90deg);
    position: absolute;
    transform-origin: -21px 39px;
  }
  &-xAxisLabel {
    text-align: center;
  }

  &-chart {
    min-height: $cost-analysis-chart-height;
    position: relative;
    width: calc(100% - 5px); //to not override shadow

    &.blur {
      filter: blur(3px);
    }

    &-datapoint-hover {
      width: 200px;
      background-color: $button-primary;

      &-cost {
        font-weight: bold;
      }
    }

    &.with-border {
      border: 1px solid $tableButtonsBorder;
      background: $tableButtonsBorder;
      border-radius: 6px;
      box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);

      .graphic-loader {
        width: 100% !important; //override inline style
        height: 100% !important; //override inline style
      }
    }
  }

  &-quantity {
    align-self: flex-start;
    width: 90px;

    #plastic-form-quantity {
      text-align: center;
      color: $text-regular-opac;
    }
  }

  &-explenation {
    text-align: initial;
  }

  &--text-field {
    margin: 9px 0 0 !important;

    &-wrapper {
      margin: 0 0 5px;
    }

    &-label {
      font-size: 14px !important;
      margin: 0;
    }
  }
  &--popup-explanation-text {
    margin-top: 6px;
  }
  &-advanced-button {
    margin: 10px 0 0 !important;
    align-self: flex-start;
  }

  &-button {
    margin: 0 20px !important;
    text-transform: none !important;
    font-size: 14px !important;
    white-space: nowrap;

    &-submit {
      text-transform: initial !important;
    }
  }

  &-edit {
    @include flexible-row();
    justify-content: flex-start;
    margin: 0;
  }
  &-user-custom-settings-header {
    display: flex;
    margin-top: 14px;
    font-size: larger;
  }
  &-chain-benefits-wrapper {
    margin-top: 10px;
    &-header {
      display: flex;
      font-size: larger;
    }
  }
}

.cost-comparison-tab {
  &--table {
    opacity: 0;
    z-index: -1;
    position: absolute;
    width: 100%;
    @include flex(flex-end, center, column);
    transition: opacity 0.3s ease-in-out;

    &.show {
      opacity: 1;
      height: unset;
      position: relative;
      z-index: 1;
    }
    &--light_user{
      opacity: 0.15;
    }
    &--text-with-icon {
      width: $table-column-with-text-width;
      color: inherit;
      justify-content: space-between;

      &--icon {
        margin: 0;
        width: 20px;
        height: 20px;
        min-width: unset;
        min-height: unset;
      }

    }
  }

  &--chart {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &.hide {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }

    &.blur {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 30px;
        left: 0;
        background: white;
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }

  &--standard-cost {
    height: 200px;
    position: relative;

    &--label {
      margin: 25px 25px 0 0;
      font-size: 16.5px;

      &--success {
        color: $benefitsText;
      }

      &--body {
        font-size: 23px;
      }

      &--title {
        margin-bottom: 10px;
      }

      &--space {
        margin-top: 102px;
      }
    }
  }

  &--toggle--root {
    > span:first-child {
      height: unset;
    }
  }

  &--switches {
    @include flex(start, center, column);

    &--top {
      margin-bottom: 10px;
    }
  }

  &--information {
    @include flexible-row;
    align-items: flex-start;
    text-align: left;
    margin-top: 10px;

    &--cost-explenation {
      text-align: left;
      display: flex;
      align-items: center;
      font-weight: bold;
      line-height: 16px;

      &--icon {
        width: 15px;
        height: 15px;
        margin-right: 7px;
      }
    }
  }

  &--cost-explenation {
    text-align: left;
    display: flex;
    align-items: center;
    font-weight: bold;
    line-height: 16px;

    &--icon {
      width: 15px;
      height: 15px;
      margin-right: 7px;

      &.not-active {
        .icons--benefits--fill {
          fill: #848484;
        }

        .icons--benefits--text--stroke {
          stroke: #ffffff;
          stroke-width: 1px;
        }
      }
    }
  }

  .edit-parameters {
    height: 290px;
    overflow: auto;
  }
}

.check-boxs {
  margin-top: 15px;

  &--header {
    text-align: initial;
    // margin-right: 20px;
    margin-top: 16px;

    &.note {
      font-size: 12px;
    }
  }
  &--subset {
    margin-left: 19px;
  }
  &--wrapper {
    display: flex;
    align-items: center;
    // @include flex(center, space-between);
  }

  &--info {
    margin-right: 5px;
  }
  &--icon {
    &#{&} {
      width: 30px;
      height: 30px;
    }
  }
  &--label {
    &#{&} {
      margin-left: -3px;
    }

    &.subhead {
      .details-popup-target {
        font-size: 16px;
      }
    }
  }

  &-with-info {
    display: flex;
    align-items: center;
    margin-left: 14px;

    > span {
      width: 18px;
      height: 18px;
    }

    .info-in-checkbox {
      margin: 0 11px 0 14px;

      svg {
        width: 12px;
      }
    }
  }

  &-popup {
    width: 261px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $long-text-read-more-background;
  }
}

.info-box {
  &-wrapper {
    &#{&} {
      width: 285px;
    }
  }
  &-data {
    width: 285px;
  }
}

.lead-time-form {
  .flex-block {
    max-width: 255px;
    position: relative;

    .admin-form-field {
      & > div {
        font-size: 14px;
        border-radius: 2px;
        border: 1px solid #dedede;
        padding: 0 15px;

        &:before,
        &:after {
          display: none;
        }
      }
    }

    .details-popup-target {
      margin-top: 0;
      top: 25px;
      position: absolute;
    }

    label {
      white-space: nowrap;
    }
  }
}

.custom-tooltip-lead {
  padding: 5px;
  text-align: left;

  &.flex {
    @include flex(center, center, column);

    &.start {
      align-items: flex-start;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }

  .single-dot {
    display: flex;
    flex-direction: column;
  }

  .am,
  .default {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .cross {
    background-color: $chartCrossColor;
  }

  .am {
    background-color: $chartAMColor;
  }

  .default {
    background-color: $chartMethodColor;
  }
}

.apexcharts {
  &-legend {
    left: 80px !important; //override inline style
    justify-content: flex-end !important; //override inline style
    bottom: 5px !important;

    &-series,
    &-text {
      cursor: pointer;
    }
  }
}

.seriesName {
  &-mold,
  &-am {
    padding: 3px 5px;
    border-radius: 3px;
  }

  &-mold {
    background-color: $chartMethodColor;
  }

  &-am {
    background-color: $chartAMColor;
  }

  &-none {
    display: none;
  }
}

.lead-time-chart {
  position: relative;

  .disabled-block {
    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background-color: rgba(116, 116, 116, 0.8);
      border-radius: 4px;
      @include flex();
    }

    &__text {
      width: 80%;
      min-height: 76px;
      border: 3px solid #ffffff;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      padding: 0 5px;
      box-sizing: border-box;
      @include flex();
      cursor: default;
      z-index: 2;
    }
  }
}

.lead-time-results {
  flex-direction: column;
  align-items: self-start;

  .text-with {
    &-benefit {
      @include flex();
      margin-bottom: 0;
    }

    &-explanation {
      margin-left: 22px;
    }
  }
}
