

.date-range-picker-wrapper {
    display: flex;
    justify-content:space-between;
	margin: 10px 0 !important;
	margin: 8px 8px;
	div,
	input {
		width: 100%;
	}
}