.admin-clusters {
  &--image {
    width: 150px;
  }

  &--result {
    font-weight: bold;
  }

  &--action-button {
    &#{&} {
      margin: 1px 0;
      width: 7em !important;
      padding: 6px 0;
      text-transform: unset;
      justify-content: space-evenly;
    }
  }

  &--buttons {
    position: absolute;
    top: -40px;
    right: 30px;
    &--button {
      &#{&} {
        text-transform: unset;
      }
      &--publish {
        &#{&} {
          width: 15em;
        }
      }
    }
  }
}
