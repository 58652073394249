@import '../../../assets/style/base/colorsTheme.scss';

.checkbox {
  &--checked-icon {
    color: $button-primary !important;
  }

  &--disabled-icon {
    color: unset !important;
  }
}
