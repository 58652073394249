@import '../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../assets/style/base/designTheme.scss';
@import '../../../../../style/globalStyles.scss';

.customize-printer-props {
	&--content {
		margin: 12px;

		&--inputs {
			&--title {
				opacity: 0.6;
			}

			&--wrapper {
				@include flexible-row();
			}

			&--button {
				&#{&} {
					padding: 5px 20px;
					width: unset;
				}
			}

			&--fields {
				@include flexible-row();

				&--field {
					&#{&} {
						width: $customize-printer-price-width;
						margin: 0 0.2rem;
					}

					&--error-text {
						position: absolute;
						top: 30px;
					}

					&--long {
						&#{&} {
							width: $customize-printer-layer-thickness-width;
						}
					}

					&--sign {
						font-size: 1rem;
					}
				}
			}
		}

		&--select {
			width: $customize-printer-cost-name-width;
			margin-right: 0.5rem;
			&--long {
				width: $customize-printer-cost-company-width;
				margin-right: 25px;
			}
		}
		&--select-printer-cost {
			width: 228px;
		}

		&--table {
			&--icon {
				&#{&} {
					width: 17px;
					height: 17px;
				}
			}
		}
	}
}
.custom-input {
	position: relative;
}
