@import '../../../../../../src/assets/style/base/colorsTheme.scss';
@import '../../../../../../src/assets/style/base/designTheme.scss';

.configure-manufacturing-method {
  width: 300px;
  height: 50px;
  position: relative;
  cursor: pointer;

  .label {
    height: 37px;
    margin: 4px 0;
    padding: 0 15px;
    font-size: 16px;
    line-height: 2.8em;
    border-radius: 2px;
    align-items: center;
    font-weight: bold;
    color: $text-regular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 264px;
    display: block;

    svg {
      position: absolute;
      right: 15px;
      top: calc(50% - 12px);
    }
  }

  &--ddl {
    z-index: $app_z_index !important;
  }

  .material-selector-configure {
    color: $text-regular;
    font-weight: bold;
    font-size: 16px;
  }

  .check-boxs--icon {
    margin-left: -30px;

    svg,
    input {
      position: absolute;
      z-index: 0;
    }
  }
}

.dropdown-fixed-height-menu {
  width: 310px;

  .dropdown-fixed__menu {
    background: white;
    max-height: 200px;
    width: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }
}

.dropdown-config {
  &__menu {
    background-color: #ffffff;
  }

  &__title {
    display: flex;
    align-items: center;
    width: 100%;

    svg {
      margin-right: 18px;
    }
  }

  &__label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

div[role='presentation'] {
  //&:not(.dropdown) {
  //  z-index: $app_z_index !important;
  //}
  &.upload-form-tolerance-info-alert-root {
    z-index: 5501 !important;
    min-height: 700px;
  }
}
