@import '../../assets/style/base/colorsTheme.scss';
@import '../../assets/style/base/designTheme.scss';
@import '../../style/globalStyles.scss';

.circle-load {
  svg,
  img {
    width: 100%;
  }
}

.loader-with-text {
  &#{&} {
    border: 1px solid gray;
    background: #F1F1F1;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-width: 263px;
    padding: 10px 17px 30px 17px;
    margin: auto;
    @include flex(center, center, column);

    h4 {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #323232;
      margin: 0;
    }
  }
}
