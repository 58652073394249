@import '../../../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../../../assets/style/base/designTheme.scss';
@import '../../../../../../../../style/globalStyles.scss';

$smallTextSize: 10pt;
$mediumTextSize: 14pt;
$bigTextSize: 20pt;

.page-header {
  .top-border {
    width: 100%;
    height: 4px;
    background: $progress-bar-gradient;
  }

  .text-section {
    @include flex(center, space-between, row);
    margin: 10px 0;

    h1 {
      @include typography();
      margin: 0 0 7px 0;
      font-size: $bigTextSize - 2pt;
      color: $takeATourButtonColor;
      font-weight: bold;
      max-width: 800px;
      word-break: break-word;
    }

    &.solution {
      h1 {
        &:nth-of-type(2) {
          margin-top: 14pt;
        }
      }
    }

    p {
      font-size: $smallTextSize;
      color: $tooltip-title-font-color;
      margin: 0;
    }
  }

  img {
    max-height: 160px;
    width: auto;
    height: auto;
    max-width: 200px;
  }
}

.page-footer {
  @include flex(center, space-between, row);

  .small-text {
    font-size: $smallTextSize + 2pt;
    color: grey;
    margin-right: 15px;
  }

  &__powered {
    text-align: center;
  }

  img {
    height: 25px;
    margin-left: 10px;
  }

  p {
    font-size: $mediumTextSize - 2pt;
    color: $tooltip-title-font-color;
    margin: 0 0 5px 0;
    font-weight: bold;
  }
}

.export-pdf {
  margin: 0;
  width: 100%;

  .inline {
    display: inline-block;
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .solution-analysis-object-content {
    margin: 0 !important;
  }

  .solution-analysis-object {
    &:hover:not(.expand) {
      background: transparent;
    }

    * {
      box-shadow: none !important;
    }
  }

  td {
    display: block;

    > div {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }

  h2 {
    font-size: $bigTextSize;
    font-weight: bold;
    line-height: $bigTextSize;
  }

  .part-analysis-button-text-transform {
    display: none;
  }

  &__part-info {
    @include flex(flex-start, space-between, row);
    width: 100%;
    margin-bottom: 15px;
    height: 180pt;

    .orientation-button {
      display: none;
    }

    .part-image-container-div {
      position: relative;
    }

    .part-image-container--structural,
    .part-image-container--part-size {
      display: none;
    }


    .part-image-container-coordinate {
      position: absolute;
      width: 90px;
      top: 0;
      right: -130px;
    }

    .part-image-container {
      height: auto;
      max-height: 300px;
    }

    img {
      max-width: 250pt;
      max-height: 170pt;
      height: auto;
      width: auto;
    }

    .part-image,
    .part-info {
      h2 {
        margin-bottom: 10px;
      }

      p {
        span {
          font-weight: bold;
          margin-right: 5px;
        }
      }

      &__section {
        max-width: 500pt;

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  &__configuration {
    &-co2 {
      position: relative;

      div {
        &.text {
          font-size: $mediumTextSize;
        }
      }

      .co-tooltip {
        position: absolute;
        right: 0;
        top: 15px;
        z-index: 100;
      }
    }

    .select-drop-down--icon {
      display: none;
    }

    .expand-button--content {
      display: none;
    }

    .solution-analysis-header {
      background: transparent !important;
      padding: 0;

      & + div {
        & {
          height: 114px;
          padding: 0;
        }
      }
    }

    .solution-analysis-header-name {
      input {
        padding-left: 0;
      }
    }

    .solution-analysis-header-wrapper {
      .solution-analysis-header-chevron-rotate {
        display: none;
      }
    }

    .solution-analysis-object {
      margin-bottom: 0;
      height: 150px !important;
      border: 0;
      box-shadow: none;

      .details-popup-target {
        display: none !important;
      }

      &:hover {
        .expand-button {
          display: none;
        }
      }
    }

    .result-detail {
      .castor-detail-body {
        width: 130px;
        font-size: $mediumTextSize + 2pt;
        word-break: break-word;
        white-space: pre-wrap;
        justify-content: flex-start;
      }
    }

    .expand-button + div {
      position: relative;
    }

    .select-drop-down--select,
    .detail-body--cost {
      font-size: $mediumTextSize;
    }

    .castor-detail-body--text {
      font-size: $smallTextSize + 2pt;
    }

    .castor-detail-body.detail-body {
      font-size: $mediumTextSize;
    }

    .castor-detail {
      @include flex(flex-start, space-between, column);
      margin-left: 5px;
      margin-top: 5px;
      height: 110px;

      .detail-body--cost--secondary {
        white-space: break-spaces;
      }

      &.with-frame {
        border: none;
      }

      &.result-detail {
        .castor-detail-title {
          position: relative;
        }
      }
    }

    .title-wrapper {
      line-height: 20px;
    }

    .solution-analysis-header-buttons-wrapper {
      display: none;
    }

    .castor-detail-title {
      white-space: pre-wrap;
      font-size: $smallTextSize + 2pt;
      color: #848484;

      &:not(.detail-title) {
        width: 160px;
        height: 40px;
        margin: 0;
      }

      &.detail-title {
        top: -5px;
      }
    }

    .solution-analysis-object-content,
    .solution-analysis-header-chevron,
    .solution-analysis-object-content-details {
      display: none;
    }

    button {
      display: none;
    }
  }

  &__material {
    .data-table-text-field {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: auto;
      max-width: none;
    }
  }

  &__material,
  &__geometry {
    .data-table {
      margin: 0 !important;
      padding: 0 !important;
    }

    .data-table-results--grid {
      height: auto;
    }

    .data-table--break-row-line {
      height: 1pt;
      margin: 0;
    }

    .data-table-row-icon {
      height: 15pt;
      width: 15pt;
    }

    .data-table-results--grid.data-table-results--grid {
      grid-row-gap: 3px;
    }
  }

  &__geometry {
    .data-table-row-text {
      font-size: $smallTextSize + 1pt;
    }

    .data-table {
      > div {
        @include flex(center, space-between, row-reverse);
        position: relative;
      }
    }

    .data-table-long-text-field,
    .data-table-text-field {
      padding: 10pt 0;
    }

    .data-table-long-text-field {
      font-size: $smallTextSize;
      line-height: $mediumTextSize;
    }

    .icon-left {
      height: 30pt;
      width: 180pt !important;
      align-items: center !important;
      justify-content: flex-end !important;
    }

    .review-and-fix {
      display: block;
      margin: 0;
    }

    &.data-table-results--grid {
      height: auto;
      overflow: hidden;
    }
  }

  &__cost-analysis,
  &__lead-time,
  &__analysis {
    &.flex {
      @include flex(flex-start, space-between, row);
    }

    .check-mark {
      &.success {
        width: 30px;
        height: 30px;

        circle {
          display: none;
          fill: transparent;
        }
      }

      &.success {
        polygon {
          fill: green
        }
      }

      &.failed {
        font-size: 30px;
        color: grey;
      }
    }

    p {
      color: $tooltip-body-font-color;

      span {
        font-weight: bold;
      }
    }

    .small {
      width: 45%;
    }

    .big {
      width: 53%;
    }

    .analysis-table {

      .analysis-header {
        padding-left: 3px;
        padding-right: 3px;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      > div {
        @include flex(flex-start, space-between, row);
        margin: 0;
        padding: 10px 0;
        border-bottom: 1px solid grey;
      }
    }

    .cost-comparison-tab--table--wrapper {
      height: initial;
      display: inline-block;
      margin-bottom: 0;
    }

    .data-table-row-icon-part,
    .data-table--first-column--parent--chevron {
      display: none;
    }


    .data-table--first-column--parent {
      padding-left: 10px !important;
    }

    .solution-tab--new-table.solution-tab--material-table {
      grid-template-columns: 3fr 2fr 2fr !important;
    }

    .data-table-header,
    .data-table-row-text,
    .data-table-text-field:not(.cost-summary--table--text--title) {
      height: initial;
      padding: 2pt;
      font-size: $smallTextSize;
      margin: 0;
      display: inline-block;
    }

    .data-table {
      padding: 0;
      margin: 0;
    }

    .data-table-row-text {
      display: flex;
      max-width: none;
    }

    .cost-summary--table--text--full-detail {
      svg {
        height: 25px;
      }
    }

    .data-table-row-text {
      height: 25px;
      line-height: 25px;
    }

    .data-table--break-row-line {
      margin: 0;
      padding: 0;
    }

    .grid-table {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(4, auto);
      grid-column-gap: 0;
      grid-row-gap: 0;

      p {
        margin: 0;
      }

      .break-word p {
        word-break: break-word;
        width: 100px;
      }

      .flex {
        &.row p {
          flex-direction: row;
          align-items: center;
        }

        p {
          @include flex(flex-start, center, column);

          .result {
            @include flex(center, flex-start, row);
            line-height: 15px;
            height: 15px;
            margin-top: 5px;

            svg {
              width: 15px;
              min-width: 15px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  &__lead-time,
  &__analysis {
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100%;

    .cost-comparison-tab--information--cost-explenation {
      font-size: $smallTextSize;
      line-height: $smallTextSize;
    }

    .apexcharts-legend {
      padding: 0;
    }
  }

  &__lead-time {
    height: 260pt;

    &.last {
      height: 265pt;
    }
  }

  &__lead-time {
    .financial-form-chart {
      min-height: 100pt;
      display: inline-block;
      width: 100%;
      padding: 0;
      margin: 0;
    }

    &.with-standard-cost {
      height: initial;
    }
  }


  &__cost-analysis {
    .solution-tab--new-table {
      margin-bottom: 0;

      .data-table--first-row--inside.extra-space,
      .data-table--first-column--inside.extra-space {
        padding-left: 50px !important;
      }

      .cost-summary--table--text--full-detail {
        &:not(.data-table--first-column) {
          @include flex(center, space-between, row);

          .data-table-row-text {
            margin-right: 5px;
          }
        }
      }
    }
  }

  &__lead-time {
    .apexcharts-title-text {
      display: none;
    }

    .apexcharts-canvas {
      min-height: 180px !important;
    }

    .lead {
      rect + .apexcharts-text {
        transform: translateY(20px);
      }
    }

    .standard-cost-data {
      &__cost {
        @include flex(flex-start, flex-start, column);

        .castor-detail {
          align-self: initial;
        }
      }

      .cost-comparison-tab--standard-cost {
        height: initial;
      }

      .cost-comparison-tab--standard-cost--label {
        line-height: $mediumTextSize;
        font-size: $mediumTextSize;
        padding: 0;
        margin: 0;
      }

      .castor-detail-body--extra-details {
        display: none;
      }

      .cost-comparison-tab--standard-cost--label--body {
        &, div {
          margin: 0;
          padding: 0;
          line-height: $mediumTextSize + 2pt;
          font-size: $mediumTextSize + 2pt;
        }
      }
    }
  }

  .text-with-explanation,
  .text-with-benefit {
    margin: 0 !important;
    padding: 0;
    line-height: $smallTextSize + 3pt;
    font-size: $smallTextSize;
    height: $smallTextSize;
  }

  .text-with-explanation {
    padding-left: 22px;
  }

  .standard-cost-data__cost {
    width: 40%;
  }

  .standard-cost-data__benefit {
    width: 60%;
  }

  .cost-comparison-tab--standard-cost--label {
    margin: 0;
    min-height: 80pt;

    .castor-detail-title {
      white-space: normal;
    }
  }

  .cost-comparison-tab--standard-cost--label--title,
  .cost-comparison-tab--standard-cost--label--success {
    margin: 0;
    padding: 0;
    line-height: $mediumTextSize + 10pt;
    font-size: $mediumTextSize;
    min-height: $mediumTextSize + 10pt;
    display: inline-block;
  }

  .text-with-explanation,
  .text-with-benefit {
    margin: 0 !important;
    padding: 0;
    line-height: $smallTextSize + 3pt;
    font-size: $smallTextSize;
    height: $smallTextSize;
  }

  .text-with-explanation {
    padding-left: 22px;
  }

  .flex-analysis {
    @include flex()
  }
}

.solution-options {
  min-height: 400px;
  margin-bottom: 20px;

  &__header {
    font-size: $mediumTextSize + 2pt;
  }

  &__checkboxes {
    &-parent,
    &-child {
      @include flex(center, flex-start, row);
    }

    &-nested {
      margin-left: 30px;
    }
  }
}

.pdf-section {
  height: 460px;
  overflow: hidden;

  &__hidden {
    visibility: hidden;
  }
}

.pdf-export-alert {
  .castor-alert--body {
    max-height: 65vh;
  }
}

.space-pdf {
  width: 100%;
  height: 30pt;
}

.line-pdf {
  width: 100%;
  height: 1px;
  background: $progress-bar-gradient;

  &.top {
    height: 3px;
  }
}
