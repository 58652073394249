@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.stats-simple-card {
  @include flexible-column();
  align-items: flex-start;

  &--content {
    @include flexible-row();
    justify-content: unset;
    height: 50px;
    margin-bottom: 5px;

    &--main {
      font-size: 50px;
      color: $text-regular;
      margin-right: 15px;
    }
  }

  &--explenation {
    color: $text-regular-opac;
    font-size: 20px;
  }
}
