@import '../../../../style/globalStyles.scss';

.drawing-2d-cost {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > div:first-of-type {
    margin-bottom: -40px;
    align-self: flex-start !important;
  }
}

.drawing {
  &__input {
    &-field {
      width: 90px !important;
    }

    &-loading,
    &-error {
      position: absolute;
      color: #f44335;
      width: fit-content;
      right: 0;
      bottom: -10px;

      &.left {
        left: 40px;
        bottom: 0;
      }

      &.center {
        width: 100%;
        text-align: center;
      }
    }

    &-loading {
      color: #000000;
      opacity: 0.7;
    }
  }

  &__field {
    position: relative;

    &.with-tooltip {
      cursor: pointer;
    }

    .tooltip {
      display: none;
      position: absolute;
      min-width: auto !important;
      padding: 8px;
      top: -65px;
      width: auto !important;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
}
