@import '../../../../../style/globalStyles.scss';
@import '../../../../../assets/style/base/colorsTheme.scss';

.add-new-configuration {
  &--form {
    padding: 20px 24px;
    overflow: hidden;

    &.printer-material {
      .configuration {
        &--options {
          grid-template-columns: 1fr;
          max-width: 1378px;

          @include breakpoint(xl) {
            grid-template-columns: 2.2fr 2fr;

          }

          > div {
            width: 100%;

            &:first-of-type {
              max-width: 741px;
              min-width: 700px;
            }

            &:last-of-type {
              @include breakpoint(xl) {
                max-width: 617px;
              }
            }
          }

          &.is-2d-option {
            > div {
              width: 100%;

              &:first-of-type {
                min-width: 1350px;

                .select-printer {
                  justify-content: stretch !important;
                }
              }
            }
          }
        }
      }

      .printer-material-config--drop-down-headers {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: capitalize;
      }

      .material-selector-configure {
        max-width: 225px;
        min-width: 200px;
        width: 100%;
        padding: 0;
      }

      .select-printer {
        @include flex(center, stretch, row);
        flex-wrap: wrap;

        > div:not(:last-of-type) {
          margin-right: 13px;
        }

        .material-selector-configure {
          @include round-input();

          &.disabled {
            background: transparent;
          }

          div {
            border: 0;
          }
        }
      }
    }

    .configuration {
      max-width: 1492px;
      min-height: 493px;

      &--name {
        &,
        p {
          margin-bottom: 20px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #323232;
          max-width: 570px;

          & + div {
            .label {
              font-weight: 500;
              margin-bottom: 8px;
            }
          }
        }

        .label {
          font-weight: 500;
        }

        .field-with-label {
          max-width: 440px;
        }
      }

      &--options {
        display: grid;
        gap: 20px 20px;
        box-sizing: border-box;

        @include breakpoint(xl) {
          grid-template-columns: 2fr repeat(2, 1fr);

        }
      }
    }


    .solution-analysis-object-content-configure-top-header {
      @include flex(flex-start, flex-start);

      .transparent-button {
        height: 21px;
      }

      .details-popup-target, .title-wrapper {
        display: flex;
        flex-direction: column;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #848484;
          margin: 0;
        }
      }
    }

    .add-new-popper-message {
      background: #FFFFFF;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;

      .solution-feature-modal {
        label {
          margin-right: 0;

          & > span {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
          }
        }

        &.with-checkbox span {
          margin: 0 6px 0 0;
        }

        > div:not(:first-of-type):not(:last-of-type) {
          margin-top: 16px;
        }

        &.solution-dropdown {
          label {
            & > span {
              height: 14px;
              margin: 8px 0;
            }
          }

          label:first-of-type {
            & > span {
              margin-top: 0;
            }
          }

          label:last-of-type {
            & > span {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .grey-block {
      background: #F1F1F1;
      border-radius: 8px;
      padding: 20px;

      @include breakpoint(xl) {
        height: 300px;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #323232;
      }

      .solution-analysis-object-content-configure-filters {
        width: 100%;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 22px;
        }
      }
    }

    .basic {
      //  min-width: 510px;

      .material-selctors-div__buttons {
        .outlined {
          height: 38px !important;
        }
      }

      .field-with-label,
      .upload-project-material-selector-field,
      .select-box {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .quantity {
      margin-bottom: 20px;

      > div {
        @include flex(center, flex-start);
      }
    }

    .material {
      margin-bottom: 24px;

      .label {
        margin-bottom: 8px;
        font-weight: 500;
      }

      .field-with-label {
        padding: 0;
      }

      .material-selctors-div {
        flex-wrap: wrap;

        > div,
        .flex {
          margin-top: 10px;

          > div {
            &:first-of-type {
              min-width: 195px;
            }
          }
        }
      }

      .select-box {
        height: 38px;
      }
    }

    .post-processes {
      margin-bottom: 24px;

      > div > .label {
        font-weight: 500;
        min-width: 130px;
      }

      .transparent-button,
      > div {
        @include flex(center, flex-start);
      }
    }

    .filter,
    .property {
      min-width: 343px;

      .solution-analysis-object-content-configure-widget,
      .solution-analysis-object-content-configure-top-header,
      .solution-analysis-object-content-configure-widget--inputs {
        &,
        > div {
          width: 100% !important;
        }
      }

      .solution-analysis-object-content-configure-widget--inputs {
        padding: 0 10px 0 5px;
      }


      .solution-analysis-object-content-configure-widget {
        width: 100%;
      }

      .solution-analysis-object-content-configure-widget-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #323232;
      }

      .solution-analysis-object-content-configure-widget-feature {
        @include flex();
        white-space: nowrap;

        &.with-dropdown {
          width: 100%;
          justify-content: flex-end;
        }
      }
    }

    .tolerance {
      > .label {
        font-weight: 500;
        margin-bottom: 8px;
      }
    }

    .label {
      margin-right: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    .field-with-label {
      padding: 5px 12px;
    }

    .select-box {
      text-transform: capitalize;
      padding: 12px 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-left: 0;
      white-space: nowrap;

      &:last-of-type {
        margin-right: 0;
      }

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }

    .react-reveal {
      > div {
        margin-top: 0;
      }

      > div {
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;

        > div:not(.rc-slider) {
          width: 100px;
          margin: 0;

          p {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

          }
        }

        .solution_configure_prioritize__romeve_button {
          display: none;
        }
      }
    }

    .rc-slider-with-marks {
      width: 80%;
    }

    .rc-slider-mark {
      top: 8px
    }

    .rc-slider-mark-text {
      &:nth-of-type(2) {
        display: none;
      }
    }

    .rc-slider-rail {
      background-color: #FFFFFF;
    }

    .rc-slider-dot {
      background: #DEDEDE;
      border: 1px solid #FFFFFF;
      width: 8px;
      height: 8px;
      transition: all 0.3s ease-out;

      &:hover {
        background: #cecdcd;
      }

      &-active {
        background: $lightBlue;

        &:hover {
          background: darken($lightBlue,  10);
        }
      }
    }

    .rc-slider-handle {
      width: 12px;
      height: 16px;
      background: $lightBlue;
      border: 1px solid #FFFFFF;
      border-radius: 4px;

      &:focus {
        box-shadow: 0 0 0 2px #96dbfa;
        width: 12px;
        height: 16px;
      }
    }

    .solution-analysis-object-content-configure--transparent-button {
      text-transform: uppercase;
      @include flex();
    }
  }
}

.add-new-solution-alert {
  &.full-screen {
    &.castor-alert {
      &.sweet-alert {
        max-width: fit-content !important;
        width: 80vw !important;
        top: 4% !important;
        z-index: 5000 !important;

        @include breakpoint(xl) {
          top: 10% !important;
        }
      }
    }
  }

  &.loading {
    .add-new-configuration--form {
      pointer-events: none;
      filter: opacity(75%);
    }
  }

  * {
    @include styled-scroll();
  }

  .solution-analysis-object-content-configure-widget--footer {
    visibility: hidden;
  }

  .solution-analysis-object.expand {
    height: 400px;
    margin: 0;
    overflow: hidden;

    .material-configuration {
      .solution-analysis-object-content-configure--tolerance-button {
        white-space: initial;
      }
    }
  }

  .castor-alert--body {
    &.full-screen {
      max-height: 60vh;
      padding: 0;
      z-index: 2;

      @include breakpoint(xl) {
        height: auto;
      }
    }
  }

  .new-solution-loader {
    top: 50%;
    left: 50%;
    position: absolute;
    z-index: 11;
  }

  .modal-footer {
    button {
      text-transform: uppercase !important;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .new-configuration-loading {
    z-index: 10000000000 !important; //override all
    height: calc(100% - 65px) !important;
    top: 75px;
  }
}

.solution-edit-name {
  margin: 37px 35px 0;

  .solution-analysis-header-chevron {
    display: none;
  }

  input {
    font-size: 20px;
  }

  & + div {
    display: none;
  }
}

.add-circle {
  margin-right: 6px;
  transform: rotate(45deg);

  &.disabled {
    filter: grayscale(1);
  }
}

.upload-project-material-selector-root {
  max-width: 250px !important;
}