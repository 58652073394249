@import '../../../assets/style/base/colorsTheme.scss';

.transparent-button {
  color: $transparent-button-primary;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;

  path {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    color: darken($transparent-button-primary, 15);

    svg {
      path {
        fill: darken($transparent-button-primary, 15);
      }
    }
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.38);
    pointer-events: none;
    cursor: unset;
  }

  &--loader {
    display: inline-block !important;
    margin-top: 4px !important;
  }
}
