@import '../../../style/globalStyles.scss';

.stats-uploaded-parts {
  @include flexible-row;
  width: 90%;
  position: relative;
  
  &--indexes {
    @include flexible-column;
    align-items: flex-start;
    font-size: 18px;
    gap:30px;

    &--title {
      position: absolute;
      top: 13%;
      text-align: left;
      display: contents
    }
  }

  &--no-uploaded {
    @include flexible-column;
    align-items: flex-start;
    width: 90%;
    &--data-wrapper {
      @include flexible-row;
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
