.reset-password-form {
  width: 500px;
  height: 500px;
  left: 35%;
  position: relative;
  top: 150px;
}

.reset-password-form-headers-div {
  position: relative;
  left: 40px;
  margin: 10px;
}

.reset-password-form-headers-div-title {
  font-size: 40px;
}

.reset-password-form-inputs {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  left: 80px;
}