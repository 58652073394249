.loader-button {
  &--only-border {
    margin: unset !important;
    position: unset !important;
    background-color: transparent !important;
    color: #78be20 !important;
    padding: 6px 13px !important;
    box-shadow: unset !important;
    text-transform: capitalize !important;
    border-radius: 2px !important;
    border: 2px solid #78be20 !important;
  }
}

.button-with-loader-on {
    padding: 3px 15px !important;
}
