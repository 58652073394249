@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.printing-saving {
	position: relative;
	&--content {
		@include flexible-row;
		width: 90%;
		&--pie {
			@include flexible-row;
		}
		&--title {
			display: grid;
			grid-auto-flow: row;
			grid-row-gap: 10px;
		}
		&--data-saving {
			padding-left: 30px;
			padding-bottom: 50px;
			font-size: 18px;
			color: #848484;
			display: grid;
			grid-auto-flow: row;
			grid-row-gap: 40px;
		}
		&--divider {
			opacity: unset;
			margin-right: 20px;
			width: 98%;
		}
		&--table {
			display: grid;
			grid-template-columns: repeat(1, auto);
			grid-template-rows: repeat(1, 100px);
			border-radius: 3px;
			border: 1px solid $pieBenefitsShipments;
			gap: 1px;

			&--item {
				border: 1px;
				padding: 15px;

				&--icon {
					width: 30px;
					height: 30px;
					margin-bottom: 8px;
				}

				&--data {
					font-size: 25px;
				}
			}
		}
	}

	&--alert {
		&--icon {
			vertical-align: top;
			width: 18px !important;
			height: 18px !important;
      margin: 0 2px;
		}
	}
}
