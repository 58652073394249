.admin-data-table-add-item-button {
  position: absolute;
  display: flex;
  align-items: center;
  top: -3em;
  right: 1em;
}

.admin-data-table-search-field {
  order: 2;
  margin: 0 5em;
}

.admin-data-table-export-data{
  order: 3;
  margin-right: 50px;
}

.admin-data-table-search-field div {
  padding-bottom: 0;
}

.admin-data-table-search-field > div {
  display: flex;
  align-items: center;
}

.admin-data-table-search-bar-icon {
  height: unset !important;
  opacity: 0.6;
}

.admin-data-table-add-item-button .new-text {
  margin-left: 0.1em;
  font-size: 0.9em;
}

.admin-data-table-link {
  color: unset;
}

.admin-data-table-link.add-new {
  order: 3;
}

.admin-data-table-link .circle-icon {
  width: 2em;
  height: 2em;
}
.admin-data-table-link:hover {
  color: unset;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}
