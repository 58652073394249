@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.error-text {
  @include flex(center, center, column);
  height: 300px;

  &.modal {
    min-width: 500px;
  }

  &.full-page,
  &.sidebar {
    height: 100vh;
  }

  &.sidebar {
    width: 260px;
    position: absolute;
    opacity: var(--sidebar-opacity-color);
    background-color: var(--sidebar-color);
    color: var(--sidebar-text-color);
  }

  &[data-hidden='true'] {
    display: none;
  }
}