@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.analysis-settings {
	position: relative;
	width: 90%;
	height: auto;
	margin: 0 5%;
	padding: 16px 0;
	overflow-y: hidden;
	background-color: $object-content-background;
	border-radius: $object-content-border-radius;
	box-shadow: 0 2px 7px 0 hsla(0, 0%, 0%, 0.16);
	z-index: $app_z_index;

	&-header {
		width: 100%;
		padding: $object-header-padding;
		font-size: $object-header-font-size;
		text-align: start;
		font-weight: $object-header-font-weight;
	}
	&-content {
		width: 100%;
		min-width: 800px;
		max-height: 80vh;
		text-align: start;
		padding: $object-content-padding;
		overflow: hidden;

		.applyAllButtons {
			padding: 15px 45px 0 20px;
		}

		.customize-material,
		.customize-printer-props--content--inputs--wrapper,
		.in-house-printers-other-selector {
			justify-content: flex-start;
			column-gap: 20px;
			button {
				margin-left: auto !important;
			}
			div {
				max-width: unset;
			}
		}

		&-tab {
			&-content {
				max-height: calc(80vh - 250px); // calc of header/footer/empty space
				min-width: 100%;
				padding-left: 4px;
				overflow: auto;
				@include styled-scroll();

				.customize-material {
					min-width: 500px;
				}
			}

			&--cost-title {
				display: flex;
			}

			&--new-tag {
				position: absolute;
				right: 0;
				top: -18px;
				font-size: 13px;
			}

			&--material-table {
				grid-row-gap: 10px !important;
				column-gap: 0 !important;
				width: 99.9%;

				&--show-all {
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}

			&--semi {
				flex-direction: column;
				align-items: flex-start !important;

				&--toggle {
					&#{&} {
						margin-left: unset;
					}
				}
			}

			&-cluster {
				width: 100%;

				&-part-tile {
					height: 295px !important;

					&--card {
						&#{&} {
							margin: 0;
							font-size: 1em;
						}
						&:hover {
							&#{&} {
								box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.28);
							}
						}

						&--image-wrapper {
							&#{&} {
								min-height: unset;
							}
						}

						&--content {
							&#{&} {
								padding: 10px 20px 0;
							}
						}
					}

					&--element {
						padding: 0.3em;
					}
				}

				&-part-comp {
					height: 344px;
				}
			}

			&-buttons {
				margin-top: 0 !important;
				width: 30%;

				&-button {
					&#{&} {
						background-color: transparent;
						text-transform: none;
						box-shadow: unset;
						color: $tab-button-text-color;
						border-bottom: 1px solid $tab-button-border-color;
						border-radius: 0;
						overflow: unset;
					}

					&-label {
						&#{&} {
							font-size: $text-field-header-font-size;
							align-self: flex-start;
							position: unset;
						}
					}

					&:hover {
						&#{&} {
							color: $tab-button-active-text-color;
							background-color: transparent;
							box-shadow: unset;
						}
					}

					&-selected {
						position: relative;
						color: $tab-button-active-text-color !important;

						&:after {
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							border-bottom: 2px solid $tab-button-active-text-color;
						}
					}
				}
			}
			&-benefit {
				display: flex;
				align-items: center;
				&-popper-message {
					&#{&} {
						left: -210px !important;
						top: unset !important;
					}
				}

				&-dollar-icon {
					height: 19px;
					width: 19px;
					margin-top: -7px;
					z-index: 2;
				}
			}
		}
	}
	&-footer {
		padding: 16px;
		width: 90%;

		&-button {
			left: 5%;
		}
	}
}
