@import '../../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../../style/globalStyles.scss';

.solution-feature-modal {
  height: 100%;
  width: 100%;
  display: grid;
  justify-items: start;
  overflow: hidden;

  &.one-column {
    display: flex;
    flex-direction: column;
    overflow: inherit;
  }

  &.stretch {
    align-items: stretch;
    justify-content: center;
  }

  &.with-checkbox {
    span {
      font-size: 20px;
      margin-left: 20px;
    }
  }

  &-label {
    text-align: left;
  }

  &-checkbox {
    margin-left: 15px !important;
  }

  &-title {
    align-self: flex-start;
    font-weight: bold;
    font-size: 17px;
    text-align: left;
    margin-bottom: 15px;
    grid-column-start: 1;
    grid-column-end: 3;

    &.with-tooltip {
      display: flex;
    }
  }

  &-wrapper {
    padding: 41.5px 0 30px 58px;
    height: 467px;
  }

  &-root {
    color: $success;
  }

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 0.5em;
  }

  &-post-processes {
    &-info {
      width: 261px;

      &-data {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        color: $text-regular-opac;
      }

      &-wrapper {
        display: flex;
      }
    }
  }

  &-explanation-text {
    font-size: 15px;
    align-self: flex-start;
    text-align: left;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &-extra-explanation-text {
    font-size: 11px;
    align-self: flex-start;
    text-align: left;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &-checkbox-popup {
    display: unset !important;
  }

  .disabled {
    span {
      color: rgba(0, 0, 0, 0.38);
    }

    svg {
      fill: rgba(0, 0, 0, 0.38);
    }
  }

  &-block {
    @include styled-scroll(#F1F1F1, 5px);
    max-height: 456px;
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    //hack for a border
    border-top-left-radius: 8px 8px;
    border-bottom-left-radius: 8px 8px;
    border-top-right-radius: 27px 100px;
    border-bottom-right-radius: 27px 100px;

    &.fullScreen {
      max-height: none;
      height: auto;
    }
  }

  &-filter {
    padding: 0 5px 20px 10px;
    background: $background;
    margin: 0 15px 0 0;
    display: grid;
    grid-gap: 20px;
    align-items: stretch;
    position: relative;
    row-gap: 16px;

    @include breakpoint(1240px) {
      padding: 0 5px 20px 20px;
      grid-template-columns: repeat(2, minmax(400px, 1fr));
    }

    .filter-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: calc(100% - 50px);
      height: 40px;

      .upload-project-filters-fields-content {
        max-width: 100px;
        width: 100%;
      }

      [class*="-disabled-"] {
        *, & {
          background-color: transparent;
        }
      }

      label {
        width: 100%;
        margin-top: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        margin-right: 10px;

        .solution-feature-modal-label {
          margin-left: -5px;

          &.disabled {
            color: grey;
          }
        }

        .solution-feature-modal-checkbox,
        .checkbox--checked-icon {
          margin: 0 !important;

          svg {
            width: 16px;
            height: 16px;

            &.disabled {
              filter: grayscale(1);
            }
          }
        }
      }
    }
  }

  .switch-label-root {
    margin-left: 0 !important;
  }
}

.solution-warn {
  position: absolute;
  bottom: -10px;
  left: 30px;
  font-size: 12px;
}