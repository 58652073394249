@import '../../../assets/style/base/colorsTheme.scss';

.feature-section {
  justify-content: space-between;
  gap: 10px;

  &--subheader {
    margin: 12px 0;
  }

  &--opac-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $darkTextColor;
  }

  &--header {
    font-size: 20px;
    font-weight: bold;
    line-height: 23px;
    color: $darkTextColor;

    &__with-icon {
      display: flex;
      align-items: flex-start;
    }
  }

  &--content {
    max-width: 66%;
    width: 100%;
  }
}
