@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../style/globalStyles.scss';

.user-roi {
  position: relative;
  padding-bottom: 30px;

  &--printer-selection {
    @include flexible-row;
    justify-content: unset;
    margin: 20px 0;

    &--explanation {
      color: $text-regular-opac;
      font-size: 18px;
      margin-right: 20px;
    }
  }

  &--content {
    width: 80%;

    &--table {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-radius: 3px;
      border: 1px solid $roiTable;
      gap: 1px;

      &--item {
        @include flexible-row;
        padding: 15px;
        font-size: 18px;

        &--left {
          @include flexible-row;
        }

        &--right {
          border-left: 1px solid $roiTable;
        }

        &--icon {
          margin-right: 10px;
        }

        &--data {
          color: $text-regular-opac;
        }

        &--take-two {
          grid-column: span 2;
        }

        &--bottom {
          border-top: 1px solid $roiTable;
        }

        &--main-data {
          font-size: 22px;
        }

        &--explanation {
          font-size: 18px;
        }

        &--content {
          height: unset;
        }
      }
    }
  }
}
