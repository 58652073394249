@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.castor-detail {
  position: relative;
  @include flex(flex-start, flex-start, column);

  &-title {
    color: #000000;
    white-space: nowrap;
    min-height: 28px;
    font-size: 14px;
  }

  .part-detail-title {
    color: $tooltip-title-font-color;
    font-weight: $castor-detail-title-font-weight;
    font-size: $castor-detail-title-font-size;
  }

  &-body {
    color: $tooltip-body-font-color;
    @include flex(space-around);
    height: 100%;
    white-space: nowrap;


    &--extra-details {
      font-size: 14px;
      margin-left: 3px;
      color: $text-regular-opac;
    }

    &--text {
      &.icon {
        min-height: 45px;
      }
    }
  }
}
