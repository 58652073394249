@import "../../../assets/style/base/colorsTheme.scss";

.printer-material-config {
  &--drop-down-headers {
    font-size: 18px;
    font-weight: bold;
    color: $text-regular;
    margin: 10px 0;
  }

  &--select-menu {
    &#{&} {
      box-sizing: border-box;
      padding: 0 30px 0 15px;
    }
  }

  &--selectors {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
}
