@import '../../style/globalStyles.scss';

.side-bar-block {
  z-index: 1130 !important;
}

.main-panel-home {
  overflow-x: hidden !important;
  position: relative;
  height: 100%;
  z-index: $main-panel-z-index;

  @include breakpoint(md) {
    z-index: $main-panel-z-index-md-bp;
  }
}

.main-content {
  header {
    z-index: 15;
    position: relative;
  }

  @media screen and (max-width: 1280px) {
    min-width: 100%;
  }
}

.styled-scroll {
  overflow-x: auto;
  @include styled-scroll();
}

.dark-background {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  z-index: 4500;
}

.with-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 5px;
  }
}
