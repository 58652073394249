@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../style/globalStyles.scss';

.cost-analysis-parameters {
  position: relative;
  font-size: 14px;

  h3 {
    font-size: 20px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }

  &__button {
    width: 100%;
    @include flex(center, flex-end)
  }

  &__section {
    padding: 0 15px;
    width: 100%;

    &-image {
      margin: 0 15px 30px;
      @include flex(center, flex-end);
      background: rgba(237, 234, 234, 0.3);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      border: 1px solid #DEDEDE;
      height: auto;

      hr {
        width: calc(100% + 32px);
        left: -16px;
        position: relative;
        border-color: #DEDEDE;
      }

      .img {
        @include flex(center, center);
        width: 322px;
        background: #DAE1F0;
        height: 100%;

        img {
          height: 283px;
          padding: 14px;
        }
      }

      .text-block {
        width: calc(100% - 322px);
        padding: 16px;

        h3 {
          font-weight: 600;
          margin-top: 0;
          margin-left: 0;
        }

        p {
          @include font-builder(14px, 400, 16px);
          margin-left: 0;
        }

        .property {
          width: 100%;
          margin: 1px 0;

          span {
            font-weight: 400;
            font-size: 14px;
            padding-right: 8px;
            width: 20%;
            display: inline-block;

            + span {
              width: 80%;
              padding-left: 8px;
              padding-right: 0;
              position: relative;

              &:after {
                content: '';
                position: absolute;
                left: -8px;
                width: 1px;
                height: 10px;
                top: 6px;
                background: rgba(0, 0, 0, 0.87);
              }
            }
          }
        }
      }
    }


    &-bottom,
    &-top {
      font-weight: 500;
      font-size: 14px;
      @include flex(center, flex-end);

      .name {
        margin-left: 42px;
        width: 30%;
      }

      .cost {
        width: 20%;
      }

      .explanation {
        width: 50%;
      }
    }

    &-top {
      margin: 0 0 30px 0;
    }

    &-bottom {
      background: $whiteBackground;
      width: 100%;
      height: 50px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
      margin-top: 24px;
    }


    .sub-item:has(+.parent-item) {
      border-bottom: 1px solid lighten($regular-opac, 5);
      margin-bottom: 20px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .parent-item:has(+.parent-item),
    .parent-item:last-of-type {
      margin-bottom: 20px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .cost-analysis-item {
      position: relative;
      @include flex(center, center);
      font-weight: 500;
      padding: 13px 0;

      .name {
        width: 30%;
      }

      .cost {
        width: 20%;
      }

      .explanation {
        width: 50%;
      }

      &.parent-item {
        border: 1px solid lighten($regular-opac, 5);
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      &.error-item {
        svg {
          fill: lighten($warnColor, 30);
        }
      }

      &.sub-item {
        padding-left: 30px;
        font-weight: 400;
        border-left: 1px solid lighten($regular-opac, 5);
        border-right: 1px solid lighten($regular-opac, 5);

        .name {
          width: calc(30% - 30px);
        }

        .cost {
          width: 20%;
        }

        .explanation {
          width: 50%;
        }
      }
    }
  }

  .error {
    position: absolute;
    bottom: 20px;
    color: $warnColor;
  }
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: white;
  width: calc(100% + 70px);
  padding: 5px 35px;
  margin-left: -35px;
  box-shadow: 0 -5px 15px -8px rgba(0, 0, 0, 0.14);
}