@import '../../../assets/style/base/colorsTheme.scss';
@import '../../../assets/style/base/designTheme.scss';
@import '../../../style/globalStyles.scss';

.select-box {
  margin: 0 5px;
  padding: $select-box-padding;
  border: 1px solid $select-box-border;
  border-radius: 8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: 1px solid transparent;
  color: $select-box-text-color;
  position: relative;
  font-weight: 300;
  transition: all 0.3s ease-out;


  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.selected {
    background: $select-box-selected-background;
    border: 1px solid $select-box-selected-color;
    border-radius: 8px;
    color: $select-box-selected-color;
    outline: 1px solid $select-box-selected-color;

    &:not(.upload-files) {
      svg {
        display: $select-box-icon-display;
      }
    }

    svg {
      width: 12px;
      height: 12px;
      font-size: 12px;
      position: absolute;
      top: 4px;
      right: 6px;

      .icons--fill--success {
        fill: $select-box-selected-color;
      }
    }
  }
}
