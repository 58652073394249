.text-in-string {
  width: 30px;
  &--input {
    &#{&} {
      padding: 0;
      text-align: center;
    }

    &.input-error {
      color: #ff0000;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      //hide input number arrows
      display: none;
    }
  }
}
