@import '../../../../../style/globalStyles.scss';

.advanced {
	&--wrapper {
    @include flex(center, center, column);
		height: 100%;
	}
	&--description-block {
		&--wrapper {
			@include flex(center, center, column);
      width: 60%;
			height: inherit;
      gap: 28px;
		}
		&--header {
			@include font-builder(20px, 500);
		}
		&--description {
      @include flex(center, center, column);
			@include font-builder(20px, 400);
      gap: 28px;
		}
	}
}
