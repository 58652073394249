@import '../../style/globalStyles.scss';

.login-screen {
  z-index: 111;

  @media only screen and (max-width: 900px) {
    width: 100vw;
  }
}

.login-version-text {
  position: absolute;
  bottom: 10px;
  right: 2em;
  font-size: 0.8em;
  opacity: 0.6;
  color: white;
  z-index: 1;
}

.register-text {
  font-size: 1.3em;
}

.register-text-link {
  cursor: pointer;
  color: gray;
}

.req-email input {
  width: 60% !important;
  margin: auto;
  background-image: gray !important;
}

.login-powered-by {
  align-self: center;
  margin-top: 40px;
  width: 100%;

  .poweredBy--text {
    margin-top: 5px;
    margin-right: 7px;
  }
}

.register-powered-by {
  margin: auto;
  width: 155px;
}
