@import '../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../assets/style/base/designTheme.scss';
@import '../../../../../style/globalStyles.scss';

.cost-model {
	&--wrapper {
		width: 75%;
	}
	&--link-wrapper {
		@include flex(center, space-between, row);
		padding: 10px;
		gap: 50px;
		width: 100%;
	}
	&--link-text-block {
		@include flex(flex-start, flex-start, column);
		margin: 0;
		gap: 8px;
		h5 {
			@include font-builder(16px, 500);
			margin: inherit;
		}
		p {
			margin: inherit;
			text-align: left;
		}
	}
	&--devider {
		margin: 20px 10px;
	}
}
