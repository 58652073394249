@import '../../../../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../../../../assets/style/base/designTheme.scss';

.cost-summary-tab {
  &--header {
    padding: 10px 0 30px;

    &--top {
      font-weight: bold;
    }
  }

  &--unprintable {
    &--explanation {
      display: flex;
      margin-top: 3em;

      &--text {
        font-size: 1rem;
      }
    }
  }
}

.cost-summary {
  &--quantity {
    font-size: $text-field-label-font-size;
  }
}
