@import '../../../../../../../../../style/globalStyles.scss';

.mechanical-analysis {
  @include flexible-row;
  align-items: flex-start;
  text-align: left;

  &--wrapper {
    @include flexible-column;
    height: 100%;
  }

  &--main {
    width: 100%;

    &--explanation {
      &--icon {
        width: 15px;
        height: 15px;

        &--target {
          display: inline;
          vertical-align: sub;
        }

        &--manager {
          display: inline;
        }
      }
      &--orientation-warning {
        display: flex;
        flex-direction: row;
        margin-top: 14px;
        &--text {
          line-height: 24px;
        }
        &--icon {
          width: 25px;
          height: 25px;
          margin-right: 11px;
        }
      }
    }
  }

  &--actions {
    align-self: flex-end;
    position: fixed;
    bottom: 0;

    &--buttons {
      overflow: hidden;
      @include flex();

      &--new {
        &#{&} {
          margin-left: 20px;
        }
      }
    }
  }

  &--part-orientation {
    width: 40%;
    margin-left: 20px;

    &--edit {
      @include flexible-row;
      position: relative;

      &--button {
        position: absolute;
        right: 0;
      }
    }

    &--image {
      width: 100%;
      border-radius: 10px;
    }
  }
}
