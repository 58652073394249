@import '../../../../../style/globalStyles.scss';

.material-names {
	&--wrapper {
    @include flex(flex-start, center, column);
    gap: 10px;
    > div {
      width: 100%;
    }
		.custom-material-names--title {
			text-align: left;
      margin-top: 0;
		}
	}
}
