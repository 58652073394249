@import '../../../../../style/globalStyles.scss';

.cost-and-supply-chain {
	&--wrapper {
		gap: 30px;
		display: grid;
		grid-template-rows: auto;
		h3 {
			margin-top: 0 !important;
		}
	}
}
