@import '../../../assets/style/base/colorsTheme.scss';

.part-properties {
  overflow: auto;

  &-form {
    height: 70vh;
    max-width: unset;
  }

  &-back-button {
    left: 40%;
    bottom: 20px;
  }

  &-table {
    max-height: 60vh;
    grid-row-gap: 0 !important;
    min-width: 1100px;

    &-header {
      background: unset !important;
      text-align: left;
      align-self: start;
      height: 60px;

      &-description {
        height: 15px;
      }
    }

    &-button {
      min-width: 145px !important;
      height: 32px;
      margin: unset !important;
    }

    &-icon {
      cursor: pointer;
    }
  }

  &-button {
    display: flex;
    align-items: center;
    margin-inline-end: 10px;
    height: 32px;

    &--error-message {
      margin-top: 10px;
    }

    &--alert {
      width: 600px !important;

      &--body {
        &#{&} {
          max-height: 500px;
        }
      }
    }
  }

  &-buttons {
    justify-content: flex-end;
    gap: 20px;

    &-cal-button {
      width: 220px;
    }
  }
}

.data-table {
  &--break-row-line {
    margin: 10px 0;
  }

  &-text-field {
    max-width: 120%;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    white-space: nowrap !important;

    &.with-tooltip {
      &:hover {
        cursor: pointer;
      }
    }

    &.with-border {
      border-bottom: 1px solid;
      min-width: 100%;
    }
  }

  &-opacity-field {
    max-width: 100%;

    > .data-table-opacity-field {
      padding: 0 !important;
      gap: 5px
    }

    svg {
      &:hover {
        cursor: pointer;
        scale: 1.1;
      }
    }

    &.extra-field {
      max-width: calc(100% + 20px);
      gap: 0;
      position: relative;

      > .data-table-opacity-field {
        max-width: calc(100% - 30px);
      }

      > div {
        gap: 0;
      }

      > svg {
        cursor: pointer;
      }
    }

    .data-table-row-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.text_field__error {
  height: 0;
  font-size: 10px !important;
  line-height: 1em !important;
  width: auto;
}

.icon-red {
  path {
    fill: red;
  }

  min-width: 25px;
  min-height: 25px;
  width: 25px;
  height: 25px;
}
