@import '../../../../assets/style/base/colorsTheme.scss';

.custom-calculation-co2{
  &--title {
        color: #565656;
        margin-top: 20px;
        font-size: 16px;
        font-weight: normal;
        padding-top: inherit;
        margin-bottom: 7px;
        font-weight: 500;
        padding-left: 13px;
      }

  &--prime-title {
        color: #797878;
        margin-top: 20px;
        font-size: 16px;
        font-weight: normal;
        padding-top: inherit;
        margin-bottom: 7px;
        font-weight: 500;
        padding-left: 13px;
      }
  &--divider {
        opacity: unset;
        margin-top: 40px !important;
        margin-left: 15px !important;
        margin-bottom: 40px !important;

        width: 98%;
      }
  &--error{
        font-size: 0.9rem;
        align-self: center;
        color: red;
        padding-left: 13px;

      }
  &--explanation {
        color: #797878;
        font-size: 16px;
        padding-top:20px;
        margin-bottom: 7px;
        font-weight: 250px;
        padding-left: 13px;
      }
  &--container{
      margin-left:15px;
      margin-bottom:8px
  }
  &--table {
    &--icon {
      &#{&} {
        width: 17px;
        height: 17px;
      }
    }
  }
  &--material-picker{
    width: 30%;
    &--text {
      margin-right: 1rem;
      margin-left: 1rem;
      width: 8.5rem;
    }
    &--select {
      margin-right: 0.5rem;
      margin-left: -3.5rem;
      width: 18rem;
    }
  }

  &--printer-picker{
    width: 50%;
    &--text {
      margin-right: 1rem;
      margin-left: 1rem;
      width: 8.5rem;
    }
    &--select {
      margin-right: 0.5rem;
      margin-left: -3.5rem;
      width: 18rem;
    }
  }

  &--carbon-picker{
    width: 17%;
    margin-left: 1rem !important;
    margin-top: 0.8rem !important;
  }

  &--product-use-picker{
    width: 7%;
    margin-left: 1rem !important;
    margin-top: 0.8rem !important;
  }

  &--end-of-life-picker{
    width: 6%;
    margin-left: 1rem !important;
    margin-top: 0.8rem !important;
  }

  &--twh-picker{
    width: 25%;
  }

  &--icon-info{
    margin-left: 10px;
    margin-top: 17px;
    &-content{
      width: 265px;
       background-color: $tooltip-background-color;

    }
  }

  &--select{
    margin-right: 1rem;
    width: 150px;
  }
}


.printers-selector {
  display: flex;
  margin: 0.6em;
  justify-content: space-between;
  align-items: center;
  margin-left:2px;

  .select {
    margin-right: 1rem;
    width: 160px;
  }
}

.cycle-selector {
  display: flex !important;
  margin: 0.6em;
  justify-content: space-between;
  align-items: center;
  margin-left:1px;
  .select {
    margin-right: 0.6rem;
    width: 180px;
  }
}

