@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../style/globalStyles.scss';

.solution-orientation {
  &--explanation {
    @include breakpoint(lg) {
      padding-right: 50px;
      max-width: 30%;
    }
  }

  &--radio {
    padding: 9px !important;
  }

  &--alert {
    &--body {
      > div {
        @include flex(center, center, column);
        overflow: hidden;
        padding: 10px;

        @include breakpoint(lg) {
          @include flex(initial, initial, row);
          overflow: auto;
          padding: 0;
        }
      }
    }
  }

  &--title {
    &--1 {
      font-weight: bold;
    }

    &--2 {
      color: $text-regular-opac;
      margin: 10px 0;
    }
  }

  &--orientations {
    display: grid;
    // we need to create 3 equal columns of the same width excluding gaps
    grid-template-columns: repeat(3, calc(33.33% - (20px * 2 / 3)));
    gap: 20px;
    margin: auto;

    @include breakpoint(lg) {
      min-height: 680px;
      padding: 10px;
      overflow: hidden;
    }

    &--card {
      box-shadow: 0 0 10px 1px $cardShadow;
      margin: 0;
      border: 1px solid $cardShadow;
      font-size: 0.8rem;

      .grid-list-benefits {
        z-index: inherit;
      }

      &--image {
        width: unset;
        min-width: 100%;
        min-height: 100%;
        max-height: unset;
        object-fit: cover;

        &--wrapper {
          height: calc(5vw + 10vh);
          overflow: hidden;
        }
      }

      &--content {
        padding: 10px 10px 0 10px !important;

        &--title {
          &#{&} {
            max-width: 100%;
          }
        }

        &--icon {
          &#{&} {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  &--radio {
    &#{&} {
      left: 0;
      position: absolute;
    }
  }

  &--wrapper {
    &.small-item {
      max-height: 50%;
    }

    &#{&} {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      position: relative;
      align-items: flex-start;
      margin: 0;
    }
  }

  &--root {
    justify-content: flex-end;
  }

  &--label {
    $blockClass: &;
    position: relative;
    height: 184px;

    &--wrapper {
      height: 100%;
      max-width: 100%;

      &#{&} {
        // width: 90%;
      }
    }

    &--header {
      border-bottom: 1px solid $devider-color;
      justify-content: space-around;
      position: relative;
      height: 32px;
      align-items: center;
      color: $devider-color;
      font-size: 12px;

      &--price--number {
        font-size: 15px;
      }

      &--divider {
        bottom: 0;
        opacity: unset;
      }
    }

    &--image {
      width: 79%;
      margin: 18px auto;
      max-height: 110px;
    }

    &--tray-num {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $devider-color;
      padding: 2px 5px;
    }

    &--layers-num {
      position: absolute;
      bottom: 0;
      left: 0;
      color: $devider-color;
      padding: 2px 5px;
    }
  }

  &--data-table {
    display: grid;
    justify-items: start;
    grid-row-gap: 17px;
    column-gap: 16px;

    &--results {
      height: unset;

      &--grid {
        height: unset;

        grid-template-columns: 0.5fr 1fr !important;
      }
    }

    &--row {
      &--icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      &--text {
        text-overflow: unset;
        overflow: unset;
        white-space: unset;
        max-width: unset;
      }
    }
  }
}

.analysis-result-hover-box {
  width: 500px;
  background-color: $background;

  &--contact {
    width: auto !important;
    height: 100%;
    font-size: 11px;
  }
}
