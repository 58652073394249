@import '../../../../../style/globalStyles.scss';

.done-screen {
	&--wrapper {
		@include flex(center, center, column);
		height: 100%;
    gap: 40px;
	}
	&--description {
    font-size: 20px;
		h4 {
			font-weight: 500;
      margin-bottom: 20px;
		}
	}
  &--button {
		text-transform: uppercase !important;
	}
}
