@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../style/globalStyles.scss';

.solution-feature-supply-chain {
  &--tog {
    align-items: center;
    margin-inline-start: 10px;
  }

  &-radio-button{
    padding-left:20px;
    display: grid;
    padding-top: 4px;
    &--label {
      &#{&} {
        align-items: center;
      }
    }
  }

  &--info {
    width: 261px;
    &-data {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: $text-regular-opac;
    }
    &-wrapper {
      display: flex;
    }
  }

  .customize {
    &__input {
      &-field {
        left: -5px;
        position: absolute;
        top: 4px;
        width: 80px;
      }

      &-error {
        left: 15px;
        bottom: 0;

        &.small {
          bottom: -2px;
          left: inherit;
          width: 700px;
          text-align: right;
        }
      }
    }

    &__table {
      position: relative;

      .checkbox {
        left: -15px;
      }


      td {
        position: relative;
      }

      tr {
        .with-opacity {
          opacity: 0.7;
        }

        span {
          z-index: 1;
        }
      }

      &-edit {
        width: 100%;
        padding: 0 15px;
        @include flex(center, end, row);

        .cancel-button {
          margin-right: 10px;
        }
      }


      .with-tooltip {
        cursor: pointer;
        z-index: 2;

        .tooltip {
          display: none;
          position: absolute;
          min-width: auto !important;
          padding: 8px;
          top: -25px;
          width: 200px !important;
          z-index: 1;
        }

        &:hover {
          .tooltip {
            display: block;
          }
        }
      }
    }
  }
}
