@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../assets/style/base/designTheme.scss';

.part-analysis-tab {
  margin: 20px 0;
  min-width: 1280px;
  width: 100%;

  &.no-content {
    min-width: 100%;
  }

  .solution-analysis-header-buttons-wrapper {
    .solution-analysis-header-text-text {
      text-transform: uppercase;
    }
  }

  .drop-down-list-menu--item {
    .solution-analysis-header-text-text {
      text-transform: capitalize;
    }
  }

  &--content {
    z-index: 1;
    overflow: hidden;
  }

  .indicator {
    background: white;
    top: 38px;
    height: 5px;
    z-index: 1;
    transition: none;

    &:after,
    &:before {
      position: absolute;
      content: '';
      width: 2px;
      height: 4px;
      background: $lightBlue;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  &--bar {
    background-color: transparent !important;
    box-shadow: none !important;
    position: relative;
    height: 40px;
    min-height: 0;

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: $lightBlue;
      position: absolute;
      top: 40px;
    }
  }

  // prevent overflow hidden for 2 nested div
  header {
    z-index: 10;
    width: 100%;
  }

  &--header {
    button:not(.part-analysis-header-button) {
      background: $whiteColor;
      border-width: 2px 2px 0 2px;
      border-style: solid;
      border-radius: 8px 8px 0 0;
      margin: 0 0 0 8px;
      border-color: $whiteColor;
      padding: 12px 30px !important;
      min-width: 0;
      max-width: none;
      min-height: 0;
      height: 40px;

      &:hover {
        svg {
          &:not(.leading-icon) {
            visibility: visible;
          }
        }
      }

      svg {
        &:not(.leading-icon) {
          visibility: hidden;
        }
      }
    }

    .tab-wrapper {
      @include flex(center, center, row-reverse);

      .tab-label {
        @include flex(center, flex-start, row);
        gap: 5px;

        .text {
          line-height: initial;
        }
      }

      svg,
      path {
        fill: $lightBlue;
      }
    }

    button:not(.part-analysis-header-button) {
      svg {
        &:not(.leading-icon) {
          position: absolute;
          right: 10px;
        }
      }

      svg,
      path {
        &:not(.leading-icon) {
          margin: 0 0 0 10px !important;
          fill: grey;
        }
      }

      &:hover {
        span {
          color: $lightBlue;
        }

        svg,
        path {
          fill: $lightBlue;

          &:hover {
            transform: scale(1);
          }
        }
      }

      &[aria-selected='true'] {
        border-color: $lightBlue;
        position: relative;
        overflow: visible;

        &:after {
          content: '';
          width: 100%;
          height: 4px;
          background-color: white;
          position: absolute;
          top: 37px;
          left: 0;
          z-index: 1;
        }

        span {
          color: $lightBlue;

          span:first-letter {
            text-transform: uppercase;
          }
        }

        .leading-icon {
          svg,
          path {
            fill: $lightBlue;
          }
        }
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: initial;
      }
    }

    .tab-flex-container {
      overflow-y: hidden;
      overflow-x: auto;

      @include styled-scroll(transparent, 0px);
    }
  }

  &--content {
    background: white;
    padding: 20px 16px;
    height: auto;

    &.without-info {
      .solution-analysis-object-top-details {
        padding: 0;
        margin-bottom: 20px;
      }

      .solution-analysis-object-content,
      .solution-analysis-object {
        margin: 0;
      }

      .solution-analysis-object {
        height: 535px;
      }
    }

    .solution-analysis-object {
      border: none;
      border-radius: 8px;
      height: 580px;

      &.expand {
        .solution-analysis-object-content {
          &.show-pdf-viewer {
            z-index: 1100;
          }
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  .add-new-button {
    @include flex();
    position: relative;
    background: $whiteColor;
    border-width: 2px 2px 0 2px;
    border-style: solid;
    border-radius: 8px 8px 0 0;
    border-color: $whiteColor;
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin: 0 8px;

    &:hover {
      svg {
        transform: scale(1.1);
      }
    }

    svg {
      &.active {
        &,
        path {
          fill: $lightBlue;
        }
      }
    }

    &:hover {
      cursor: pointer;

      svg,
      path {
        fill: $lightBlue;
      }
    }
  }

  .dropdown-tab {
    &.drop-down-list-menu {
      padding: 20px 16px;
      border-radius: 8px;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
      z-index: 1;

      &:before {
        display: none;
      }

      .drop-down-list-menu--item {
        padding: 0;

        &:first-of-type {
          margin-bottom: 20px !important;
        }

        &:last-of-type {
          padding-top: 20px !important;
        }
      }

      .part-analysis-header-button {
        margin-top: 12px !important;
        padding: 10px 24px !important;
        text-transform: uppercase;
        color: $whiteColor;
        border-radius: 8px !important;
        width: 100%;
        box-shadow: none;

        &:hover {
          color: $button-hover-color;

          &,
          span {
            color: $button-hover-color;

            svg {
              &,
              path {
                fill: $button-hover-color !important;
              }
            }
          }
        }

        &_text {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
        }

        &,
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: $whiteColor;
          text-transform: uppercase;

          svg {
            margin-right: 8px;

            &,
            path {
              fill: $whiteColor !important;
            }
          }
        }
      }
    }
  }

  .drop-down-list-menu {
    &,
    * {
      transition: none !important;
    }
  }

  &--failed-processing {
    padding: 20px;

    svg {
      width: 15px;
      height: 15px;
      margin: 0 5px;
    }

    p {
      font-size: 16px;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}

.info-box-data {
  font-size: 14px;
}
