.customize-form {
  margin: 1em;
  display: flex;
  flex-direction: column;
}

.customize-form-submit {
  align-self: flex-end;
}

.customize-form-field {
  width: 100%;
  margin: 0.5em 0 !important;
}

.customize-form-dropdown-item {
  text-transform: capitalize;
}

.new-material-link {
  float: right;
  margin: 0 1em;
}

.form-multiple-fields {
  display: flex;
}

.form-field-in-multiple {
  width: 100%;
}

.form-field-in-multiple.first {
  margin-right: 1em;
  width: 70%;
}

.form-field-in-multiple.last {
  margin-right: 1em;
  width: 30%;
}

.surface-finish-field {
  text-transform: capitalize;
}
