@import '../../../style/globalStyles.scss';
@import '../../../assets/style/base/colorsTheme.scss';

$headerBackground: #f9f9f9;

.part-card {
	transition: background-color 0.3s ease-in-out;

	&:hover {
		.project-analysis--expand-button {
			opacity: 0;
		}

		.part-actions--remove {
			opacity: 1;
		}

		background: $cardBackground;
	}
}

.dark {
	.card--image-wrapper {
		border: 1px solid #000000;
	}
}

.project-analysis {
	&--expand-button {
		position: absolute;
		bottom: 8em;
		right: 2em;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}
}

.part-actions {
	@include flexible-row();
	position: absolute;
	z-index: 100;
	top: 0.8rem;
	right: 1.2rem;
	width: 53px;
	justify-content: flex-end;
	gap: 8px;

	&--remove {
		width: 16px;
		height: 16px;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		fill: $regular-opac;

		&:hover {
			fill: $blackColor;
			opacity: 1;
		}
	}

	&--save {
		width: 20px !important;
		height: auto !important;
		font-size: 0.7rem;
		color: $regular-opac;

		&:hover,
		&--starred {
			color: $text-regular-opac;
		}
	}
}

.defaultText {
	font-style: italic;
}

.card {
	width: 100%;
	height: 100%;
	color: $text-regular-opac;
	font-weight: 400;
	font-size: 1.1em;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background: #fff;
	border-radius: 6px;
	box-shadow: 0 0 1px 1px $cardShadow;
	transition: all 0.5s;

	.card--footer {
		min-height: 50px;
		padding: 12px 16px;
		border-top: 1px solid $lightGrey;
	}

	.section-header {
		@include flex(center, space-between);
		padding: 12px 16px;
		background: $headerBackground;
		border-bottom: 1px solid $lightGrey;
		height: 50px;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		width: 100%;

		&--text {
			width: calc(100% - 50px);
			@include flex(center, flex-start);

			div {
				white-space: nowrap;
				@include text-ellipsis();

				&.title {
					color: $darkTextColor;
					display: block;

					&:has(+ div) {
						max-width: 60%;
					}

					&.defaultText {
						font-style: italic;
					}
				}

				&:not(.title) {
					color: $darkGrey;
					border-left: 1px solid $darkGrey;
					padding-left: 10px;
					margin-left: 10px;
					padding-right: 2px;
				}
			}
		}

		.part-actions {
			position: relative;
			right: inherit;
			top: inherit;
		}
	}

	&:hover {
		box-shadow: 0 0 5px 1px $cardShadowHover;

		.part-actions--remove {
			opacity: 1;
		}
	}

	&--selected {
		&#{&} {
			border: 1px solid $button-primary;
		}
	}

	&--image {
		&-wrapper {
			position: relative;
			padding: 10px;
			height: 150px;
			background-color: $partBackground;
			@include flex(center, center);
			display: flex !important;

			&.drawing {
				background-color: white;

				&:has(.fallback-wrapper) {
					background-color: $partBackground;
				}
			}

			@include breakpoint(lg) {
				height: 200px;
			}

			@include breakpoint(1860px) {
				height: calc(5vh + 15vw);
			}
		}

		&-inner {
			height: auto;
			width: auto;
			max-width: 100%;
			max-height: 100%;
			transition: border 0.5s;
			border-radius: 6px 6px 0 0;

			&-coordinate {
				position: absolute;
				z-index: 100;
				right: 11px;
				top: 7px;
				width: 60px;
			}
		}
	}

	&--content {
		position: relative;
		padding: 0 20px;

		&:has(div) {
			padding: 15px 20px 30px;
		}

		&--empty {
			height: calc(1.5em + 7px);
		}
	}

	&--title {
		font-size: 1.2em;
		font-weight: 400;
		color: $text-regular;
		text-align: left;
		margin-top: 0;
		margin-bottom: 0;
		@include typography;
		max-width: 80%;
	}

	&--footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: auto;
		width: 100%;
		height: 30px;
		padding: 15px 20px 24px;
	}

	&--result-label-icon {
		height: 20px;
		min-width: 20px;
		margin-right: 6px;
		max-width: 20px;
	}

	&--result-label-text {
		margin-right: auto;
		@include typography;
		&--extra-reason {
			background-color: $lightGrey;
			margin-left: 5px;
			padding: 2px 6px;
			border-radius: 4px;
		}
	}
}

.benefit-icon-wrapper {
	margin: 0 3px;
	min-width: 28px;

	.castor-icon-small {
		width: 28px;
		height: 28px;
	}
}

.unprintable-popup {
	width: auto;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	background-color: $tooltip-background-color;
	&--data {
		width: auto;
		font-size: 16px;
		letter-spacing: normal;
		color: $tooltip-body-font-color;
		text-align: left;
	}
}
