.form-control {
  &.custom {
    order: 1;
    width: 240px;
    margin: -15px -20px 0 0;

    > div {
      & > div {
        padding-right: 0;
      }
    }
  }
}
