@import '../../../style/globalStyles.scss';
@import '../../../assets/style/base/colorsTheme.scss';

.parts-header {
  &--title {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: $darkTextColor;
    width: 150px;
    white-space: nowrap;

    &--advanced {
      color: $advancedText;
    }
  }

  &--remove-button {
    margin: 0 10px 0 !important;

    &--text {
      margin-left: 8px;
      font-size: 18px;
      font-weight: 400;
      height: 20px;
    }
  }

  &--search-bar {
    max-height: 32px;
  }
}

.parts-subheader {
  height: 55px !important;
  padding-bottom: 0 !important;

  &--hide {
    display: none;
  }
}

.cluster-section {
  &--subheader {
    &--icon {
      margin-right: 10px;
    }

    &--text {
      white-space: pre-wrap;
    }
  }

  &--button {
    &#{&} {
      text-transform: unset;
      margin: 0;
    }
  }
}

.search-again-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  svg {
    width: 20px;
    margin-right: 5px;
  }
}

.text {
  &-dark {
    color: #000000;
  }
}

.standard-cost {
  &--error-message {
    margin-top: 10px;
  }

  &--alert {
    width: 600px !important;

    &--body {
      &#{&} {
        max-height: 500px;
      }
    }
  }

  &--container {
    max-height: 210px;
    display: inline-block;
    overflow-y: auto;
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 7px;
      border-radius: 10px;
      background-color: hsla(218, 76, 15, 0.08);
    }

    &::-webkit-scrollbar-thumb {
      background-color: hsla(0, 0, 66, 0.7);
      border-radius: 10px;
    }
  }
}

.grid-list--tile {
  padding: 1em;
  height: auto !important;

  .grid-list-benefits {
    z-index: 101;
  }
}

.grid-list-benefits {
  display: flex;
  align-items: center;
  z-index: 101;
  min-width: fit-content;

  &--icon {
    width: 32px;
    height: 32px;
  }
}

.more-parts-button--container {
  @include flex;
  margin-bottom: 10px;
  height: unset !important;
}

.single-part-project {
  @include flex(flex-start, null);
  position: relative;
  padding-left: 30px;

  &--actions {
    @include flex(flex-start, null, column);
    margin-right: 20%;

    &--result {
      &--text {
        font-size: 20px;
        font-weight: bold;
      }

      > button {
        width: 220px;
      }
    }

    &--activities {
      @include flex(null, null, column);
      position: absolute;
      bottom: 8px;
      width: 220px;

      button {
        width: 100%;
      }
    }

    &--delete {
      &:hover {
        &#{&} {
          color: $text-regular-opac;
          box-shadow: unset;
        }
      }

      &:focus {
        &#{&} {
          color: $text-regular-opac;
        }
      }

      &#{&} {
        color: $text-regular-opac;
        fill: $text-regular-opac;
        padding: 6px 13px;
        height: 40px;
        box-shadow: unset;
        text-transform: capitalize;
        border-radius: 2px;
        border: 2px solid $text-regular-opac;
        margin: 10px 0 0 !important;
        order: 1;
      }

      &--icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  &--part {
    width: 400px;

    &--card {
      margin: 0;

      &--size-wrapper {
        @include breakpoint(lg) {
          display: inline;

          &.project-result {
            display: inline-block;
          }
        }
      }
    }
  }
}

#grid-list {
  z-index: 111;

  & > div {
    overflow: visible !important; //override inline
  }
}

#general-parts-statistics {
  width: calc(100% - 30px);
  margin: 0 15px;
  z-index: 0;
  overflow: visible;

  .general-part-statistics {
    width: 100%;
    overflow: auto;

    &,
    ul {
      width: inherit;
      min-height: 400px !important; //override inline

      @include breakpoint(1440px) {
        min-height: 500px !important; //override inline
      }

      @include breakpoint(1680px) {
        min-height: 600px !important; //override inline
      }
    }

    ul {
      margin: 0 !important; //override inline
      box-shadow: none !important; //override inline

      li {
        width: inherit;
      }
    }

    .parts-pagination {
      min-height: 24px !important;
      margin-top: 24px !important;
    }
  }
}

.all-inapplicable {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.loading-wrapper {
  z-index: 1000 !important;
  width: calc(100% - 20px) !important;
}

.quick-cad-project-results--card-result {
  .multiple-parts-loading {
    height: calc(100% - 145px) !important;
  }
}

.multiple-parts-loading {
  height: calc(100% - 70px) !important;
  bottom: 0;
  top: unset !important;
}

.main-panel-home {
  .project-analysis-block {
    position: relative;

    &__loader {
      left: -260px;
      width: 100vw !important;
    }
  }

  &.withoutMenu {
    .project-analysis-block {
      &__loader {
        left: 0;
      }
    }
  }
}

.general-part-statistics__block {
  width: 100%;
}