@import '../../../../assets/style/base/designTheme.scss';
@import '../../../../style/globalStyles.scss';

.custom-input {
  svg {
    margin-right: 20px;
  }

  &--action {
    margin-right: 5px;
  }
}

// make common styles for all cost-lead
.customize-cost-and-lead-settings {
  h3 {
    text-align: left;
    margin-top: 20px;
    margin-left: 12px;
    @include font-builder(20px, 500);

    + div {
      p {
        text-align: left;
        margin: 12px;
        @include font-builder(16px, 400);
      }
    }
  }

  .solution-feature-supply-chain {
    margin-top: 30px;
  }

  .form-header-wrapper {
    margin-top: 20px;
  }

  .customize__table {
    padding-top: 15px;
  }

  .materials-costs-table,
  .printer-costs-table {
    padding: 15px;
  }

  .material-costs-input-hint {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
  }

  table {
    th {
      padding-top: 10px;
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
