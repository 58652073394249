@import '../../../../assets/style/base/colorsTheme.scss';
@import '../../../../style/globalStyles.scss';

.costing-function-editor {
	&--header {
		padding: 0 24px;
		text-align: left;
	}
	&--body {
		padding: 24px;
		color: $darkTextColor;
	}
	&--button {
		border-radius: 8px !important;
		height: 30px;
		margin: 0 0 1px 0 !important;
		padding: 12px 20px !important;
		text-transform: uppercase !important;
		font-size: 14px !important;
		&.in-select-row {
			align-self: flex-end;
		}
		&.secondary {
			color: $button-primary;
			border: 1px solid $button-primary;
			background-color: $whiteColor;
			&:hover {
				background-color: $whiteColor;
				color: $button-primary;
			}
		}
	}
	&--icon {
		width: 18px !important;
		height: 18px !important;
		margin-right: 6px;
	}
	&--accordion {
		background: $whiteColor;
		box-shadow: 0 0 40px rgba(0, 0, 0, 0.1) !important;
		border-radius: 8px !important;
		margin-bottom: 20px !important;
		padding: 20px !important;
		&::before {
			display: none;
		}
	}
	&--step-heading {
		@include flex(center, space-between, row);
		margin: 0 !important;
		padding: 0 !important;
		.expand {
			position: relative;
			transform: rotate(270deg);

			&.open {
				transform: rotate(360deg);
			}
		}
		&--wrapper {
			margin: 0 !important;
			padding: 0 !important;
			min-height: 0 !important;
		}
		&--text {
			@include flex(flex-start, center, column);
			@include font-builder(16px, 400);
			text-align: left;
			&--main {
				font-weight: 500;
			}
		}
	}
	&--step-body {
		padding: 0 !important;
		margin-top: 20px;
		display: block !important;
		&--content {
			@include flex(center, flex-start, row);
			gap: 24px;
		}
	}
	&--select {
		padding: 7px 12px !important;
		width: 250px !important;
		&:focus {
			border-radius: 8px !important;
			width: 250px;
		}
		&-field {
			height: 30px;
			background-color: transparent;
			border: 1px solid $darkTextColor !important;
			border-radius: 8px;
			text-align: left;
			font-size: 12px;
			text-transform: capitalize;
			color: $darkTextColor;
			padding: 0 !important;
			margin: 0 !important;
			&:before,
			&:after {
				display: none;
			}
		}
		&-wrapper {
			@include flex(flex-start, center, column);
			label {
				font-weight: 500;
				color: $darkTextColor;
			}
		}
	}
	&--create-function {
		&--upper-blocks {
			display: grid;
			width: 100% !important;
			grid-template-rows: 1fr auto;
			row-gap: 24px;
		}
		&--label-wrapper {
			@include flex(center, flex-start, row);
			width: 100%;
		}
		&--label {
			@include font-builder(16px, 500, 1em);
			text-align: left;
			&.with-border {
				padding-right: 12px;
				border-right: 1px solid $lightGrey;
			}
		}
		&--transparent-button {
			color: $transparent-button-primary;
			font-size: 14px !important;
			transition: all 0.2s ease-in-out;
			text-transform: uppercase !important;
			border-radius: 0 !important;
			background-color: transparent;
			padding: 0 12px !important;
			margin: 0 !important;
			cursor: pointer;
			height: 20px;
			box-shadow: none !important;
			text-transform: uppercase;
			&:hover {
				color: $button-primary-light !important;
				box-shadow: 0 0 transparent !important;
				svg {
					path {
						fill: $button-primary-light;
					}
				}
			}
			span {
				line-height: 1em;
			}
			svg {
				height: 14px;
				path {
					fill: $transparent-button-primary;
				}
			}
		}
		&--operators-wrapper {
			@include flex(flex-start, center, column);
			width: 100%;
			margin-top: 12px;
			gap: 12px;
		}
		&--operators {
			@include flex(center, flex-start, row);
			width: 100%;
			gap: 12px;
			flex-wrap: wrap;
		}
		&--operator {
			@include font-builder(20px, 400);
			padding: 3.5px 9px;
			border-radius: 4px;
			border: 1px solid $button-border-color;
			color: $button-border-color;
			cursor: pointer;
			background-color: $whiteColor;
			transform: translate(0, 0);
			&.variable {
				font-size: 16px;
				border-radius: 8px;
				color: $darkTextColor;
				border-color: $darkTextColor;
				position: relative;
			}
			&.deletable {
				&:hover {
					.delete {
						display: flex;
					}
				}
			}
			&.dragging {
				.costing-function-editor--create-function--variable-count {
					opacity: 0;
				}
			}
		}
		&--variables {
			@include flex(center, flex-start, row);
			margin-top: 12px;
			gap: 12px 30px;
			flex-wrap: wrap;
			.info-box-wrapper {
				width: auto;
			}
		}
		&--drop-zone {
			@include flex(center, flex-start, row);
			width: 100%;
			border: 1px dashed $darkGrey;
			border-radius: 4px;
			min-height: 50px;
			padding: 12px 20px;
			min-height: 56px;
			&--function-name {
				font-weight: 500;
			}
			&--wrapper {
				@include flex(center, flex-start, row);
				margin-top: 40px;
				gap: 20px;
			}
		}
		&--variable-count {
			@include flex(center, center, row);
			@include font-builder(12px, 400, 18px);
			width: 18px;
			height: 18px;
			border-radius: 50%;
			background-color: $count-note-color;
			border: 1px solid transparent;
			position: absolute;
			top: -10px;
			right: -10px;
			color: $whiteColor;
			&.delete {
				display: none;
				width: 16px;
				height: 16px;
				background-color: inherit;
				border-color: inherit;
				color: inherit;
				svg {
					width: 12px;
					height: 12px;
				}
			}
			&.custom {
				top: -10px;
				left: -10px;
			}
		}
	}
	&--alert {
		padding: 24px;
		&--description {
			font-weight: 400;
		}
		&--body {
			@include flex(center, flex-start, row);
			margin: 24px 0;
			gap: 40px;
		}
		&--input-wrapper {
			@include flex(flex-start, center, column);
			.label {
				font-weight: 500;
			}
		}
		&--input {
			width: 250px !important;
			max-height: 30px;
			padding: 0 12px !important;
			&.number {
				width: 100px !important;
				input {
					padding: 7px 0 !important;
				}
			}
			&.error {
				border-color: $warnColor !important;
			}
			p {
				white-space: nowrap;
				text-transform: none;
				margin-left: -12px;
				color: $warnColor !important;
			}
		}
	}
	&--functions-list {
		&--wrapper {
			width: 100%;
		}
		&--headers,
		&--row {
			display: grid;
			grid-template-columns: repeat(2, minmax(100px, 200px)) minmax(200px, 1fr) minmax(
					100px,
					200px
				);
			justify-items: start;
			font-weight: 500;
		}
		&--row {
			font-weight: 400;
			border-radius: 8px;
			box-shadow: 0 0 10px $cardShadow;
			height: 60px;
			margin: 16px 0;
			align-items: center;
			&:hover {
				background-color: $hoverPartColor;
			}
		}
		&--cell {
			@include text-ellipsis();
			padding-left: 12px;
			text-align: left;
			white-space: nowrap;
			width: 100%;
			&.action-buttons {
				justify-self: flex-end;
				text-align: right;
				padding-right: 10px;
			}
		}
	}
	&--popover-root {
		z-index: 4000 !important;
	}
}
.select-toggle-all {
	&:focus {
		background: transparent !important;
	}
}
.select-toggle-custom {
	&.disabled {
		opacity: 0.5;
		background-color: $lightGrey;
	}
}
